import React, { FC, useEffect } from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import ToggleButton from '../../ToggleButton';
import { parseQs, updateUrl } from '../../../resources/utils';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../reducer/@types';
import { applyFilter, getFilteredProducts } from '../../../reducer';
import { SETTING_UPDATED } from '../../../reducer/actions';
import { Product } from '../../../models/product';
import { updateSettings } from "../../../reducer/redux-actions";
import { getProductsFromFilteredProducts } from '.';


interface CoveredFilterProps extends WithSheet<typeof STYLES> {

}

const CoveredFilter: FC<CoveredFilterProps> = (props) => {
    const { allProducts = [] } = useSelector<IAppState, Pick<IAppState, 'allProducts'>>(({ allProducts }) => ({ allProducts }));
    const { settings, products = [], originalFilterProducts } = useSelector<IAppState, Pick<IAppState, 'settings' | 'products' | 'originalFilterProducts'>>(({ settings, products, originalFilterProducts }) => ({ settings, products, originalFilterProducts }));
    const queryState = parseQs();
    const dispatch = useDispatch();

    useEffect(() => {

        if (queryState.settings.covered !== settings.covered) {
            dispatch(updateSettings({
                settings: { ...settings, covered: queryState.settings.covered },
                products: Product.settingFilterProduct({ ...settings, covered: queryState.settings.covered }, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
            }))
        }
    }, [queryState.settings.covered, settings.covered]);

    const handleToggle = () => {
        const _settings = { ...settings, covered: !queryState.settings.covered }
        dispatch(updateSettings({
            settings: _settings,
            products: Product.settingFilterProduct({ ...settings, covered: _settings.covered }, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
        }, { ..._settings, eventName: 'TOGGLE COVERED_BY_CHARDAN_FILTER' }))

        updateUrl({ ...queryState, settings: { ...settings, covered: !settings.covered } });
    }

    return (
        <ToggleButton active={settings.covered} onToggle={handleToggle} label={'COVERED BY CHARDAN'} />
    )
}

const STYLES = ({

})

export default injectSheet(STYLES)(CoveredFilter);