import React, { FC, useEffect, useCallback } from 'react';
import Signs from './Signs';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { IAppState } from '../../../reducer/@types';
import { Legends } from '../../../models/legends';
import { LEGENDS_RECEIVED } from '../../../reducer/actions';
import LegendBlock from './LegendBlock';

type IStateProps = Pick<IAppState, 'legends'>

const selectLegends = createSelector<IAppState, IAppState, IStateProps>(
    state => state,
    state => ({ legends: state.legends })
)

interface LegendProps {
    selectedLegendCategory?: string
}

const Legend: FC<LegendProps> = (props) => {

    const { legends } = useSelector(selectLegends);
    const { selectedLegendCategory } = props;

    const dispatch = useDispatch();

    const getData = useCallback(async () => {
        const data = await Legends.fetchLegends().catch(error => { throw error });
        dispatch({ type: LEGENDS_RECEIVED, data });
    }, [dispatch]);

    useEffect(() => {
        legends.length === 0 && getData();
    }, [legends, getData]);


    return (
        <div>
            <Signs />
            {
                legends.map(item => {
                    return <LegendBlock selectedCategory={selectedLegendCategory} key={item._id.airtableId} legend={item} />
                })
            }
        </div>
    )
}

export default Legend;