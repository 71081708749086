import React, { FC, useCallback, useEffect } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import { createSelector } from 'reselect';
import { IAppState } from '../../../reducer/@types';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../../../models/product';
import { CLEAR_SELECTED_THERAPEUTIC_SECTOR, THERAPEUTIC_SECTOR_DETAILS_RECEIVED } from '../../../reducer/actions';
import Divider from '../DetailComponents/Divider';
import ChipsList from '../DetailComponents/ChipsList';
import { IField } from '../../../models/landscape';
import { applyFilter } from '../../../reducer';
import { parseAggregatedArray } from '../../../resources/utils';
import CoverageBadge from '../DetailComponents/CoverageBadge';
import Contact from '../../../screens/Contact';
import ContactButton from '../../ContactButton';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    id: string
}

const selectSelectedTherapeuticSector = createSelector<IAppState, IAppState, Pick<IAppState, 'selectedTherapeuticSector'>>(
    state => state,
    ({ selectedTherapeuticSector }) => ({ selectedTherapeuticSector })
)

const TherapeuticSectorsDetails: FC<IProps> = ({ id, classes }) => {

    const dispatch = useDispatch();

    const { selectedTherapeuticSector } = useSelector(selectSelectedTherapeuticSector);

    const getData = useCallback(async () => {
        dispatch({ type: CLEAR_SELECTED_THERAPEUTIC_SECTOR });
        const data = await Product.fetchTherapeuticSectorDetails(id).catch(error => { throw error });
        dispatch({ type: THERAPEUTIC_SECTOR_DETAILS_RECEIVED, data });
    }, [dispatch, id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if ((selectedTherapeuticSector && selectedTherapeuticSector.airtableId !== id) || !selectedTherapeuticSector) getData(); }, [id, getData]);

    const companies = (selectedTherapeuticSector && parseAggregatedArray<IField>(selectedTherapeuticSector.companies || [])) || [];
    const partners = (selectedTherapeuticSector && parseAggregatedArray<IField>(selectedTherapeuticSector.partners || [])) || [];
    const investors = (selectedTherapeuticSector && parseAggregatedArray<IField>(selectedTherapeuticSector.investors || [])) || [];
    const landscapes = (selectedTherapeuticSector && parseAggregatedArray<IField>(selectedTherapeuticSector.sector || [])) || [];
    const analysts = (selectedTherapeuticSector && parseAggregatedArray<IField>(selectedTherapeuticSector.analysts || [])) || [];

    const handleLandscapeClick = (landscape: IField) => applyFilter('landscape', landscape.airtableId);
    const handleComapnyClick = (company: IField) => applyFilter('company', company.airtableId);
    const handlePartnerClick = (partner: IField) => applyFilter('partner', [partner.airtableId]);
    const handleInvestorClick = (investor: IField) => applyFilter('investor', [investor.airtableId]);

    return (
        <div>
            {
                selectedTherapeuticSector ?
                    (
                        <>
                            <p className={classes.title} >{selectedTherapeuticSector && selectedTherapeuticSector.name}</p>
                            <Divider ySpacing={10} />
                            {landscapes.length > 0 ? <ChipsList collapsedChipCount={5} onClick={handleLandscapeClick} items={landscapes} title={'Landscapes'} /> : null}
                            {(selectedTherapeuticSector.companies || []).length > 0 ? <ChipsList collapsedChipCount={5} items={companies} onClick={handleComapnyClick} title={'Companies'} /> : null}
                            {(selectedTherapeuticSector.partners || []).length > 0 ? <ChipsList collapsedChipCount={5} items={partners} onClick={handlePartnerClick} title={'Partners'} /> : null}
                            {(selectedTherapeuticSector.investors || []).length > 0 ? <ChipsList collapsedChipCount={5} items={investors} onClick={handleInvestorClick} title={'investors'} /> : null}
                            {analysts.length > 0 && <CoverageBadge analysts={analysts} />}
                            <ContactButton />
                        </>
                    )
                    : <p>Loading</p>
            }
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    title: {
        fontSize: 14,
        lineHeight: '20px'
    }
});

export default injectSheet(STYLES)(TherapeuticSectorsDetails);