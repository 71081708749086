import axios from 'axios';
import { ITherapeuticSector, TherapeuticSector, IField, ITherapeuticSectors } from './therapeuticSector';
import { Landscape, ILandscape, IStage } from './landscape';
import { IIndication } from './indication';
import { IAppState } from '../reducer/@types';


export const COLOR_CONFIG = {
    gene_therapy_gene_editing: '#8bc53f',
    microbiome: '#f6921e',
    rna: '#9e1f63',
    digital_therapeutics: '#25a9e0',
    cell_therapy: '#9c45db'
}

type IThumbImage = {
    url: string
    width: number
    height: number
}

export interface ILogo {
    thumbnails: Record<'full' | 'large' | 'small', IThumbImage>
}

export interface IProduct {
    name: string
    id: string
    partnered: boolean
    companies: Array<ICompany>
    therapeuticSectors: Array<ITherapeuticSectors>
    airtableId: string
    regulatoryStages: Array<IRegulatoryStages>
    indications: Array<IIndication>
    sector: ISector
    legendCategories: Array<ISector>
    partners: Array<IField>
    productComment?: string
    noLongerInDevelopment?: boolean;
}

interface ISector {
    airtableId: "gene_therapy_gene_editing" | "microbiome" | "rna" | "digital_therapeutics" | "cell_therapy"
    name: string
}
export interface ICompany extends IField {
    metadata: Array<any>
    airtableId: string
    analysts: IField[]
    name: string
    description?: string
    shortName: string
    logo: Array<ILogo>
    ticker?: string
    managementKeyPeople?: Array<{
        airtableId: string
        name: string
    }>,
    partners: Array<{
        _id: {
            metadata: Array<any>
            airtableId: string
            name: string
        }
    }>
    sectors: Array<{
        _id: ISector
        therapeuticSectors: Array<{
            therapeuticSector: {
                airtableId: string
                name: string
            }
        }>
    }>
    investors: Array<IField>
    chardanCoverage: boolean
    total: number;
    publicPrivate: 'Private' | 'Public'
}

interface IRegulatoryStages extends IField {
    metadata: Array<any>
}

export type TFilterType = 'company' | 'legendCategory' | 'therapeuticSector' | 'partner' | 'investor' | 'managementKeyPeople' | 'covered' | 'indication' | 'analyst' | 'landscape' | 'partneredView'

export interface IInitialData {
    therapeuticSectors: ITherapeuticSector[] | null
    landscapes: ILandscape[] | null,
    stages: IStage[] | null
}

export type ProductEntities = 'partners' | 'investors' | 'companies';

export type ProductEntity = 'partner' | 'investor' | 'company' | 'therapeuticSector' | 'indication' | 'analysts' | 'managementKeyPeople' | 'legend';

export type TSetting = 'coverage' | 'partnered' | 'non-partnered';

export class Product {
    static async fetchProducts(): Promise<IProduct[] | null> {
        try {
            const res = await axios.request({
                url: '/products'
            })
            return res.data;
        } catch (error) {
            return []
        }
    }

    static async fetchProductEntities(entity: ProductEntities, filter?: any) {
        const res = await axios.request({ url: `/products/${entity}`, params: { filter } }).catch(error => {
            console.log('Error', error.response.data);
            throw error.response.data;
        })
        return res.data;
    }


    static async fetchProductEntityDetails(entity: ProductEntity, id: string) {
        const res = await axios.request({ url: `/products/entity?type=${entity}&airtableId=${id}` }).catch(error => {
            console.log('Error', error.response.data);
            throw error.response.data;
        });
        return res.data;
    }

    static async fetchPartners(filter?: any) {
        return await this.fetchProductEntities('partners', filter).catch(error => { throw error });
    }

    static async fetchInvestors(filter?: any) {
        return await this.fetchProductEntities('investors', filter).catch(error => { throw error });
    }

    static async fetchCompanies(filter?: any) {
        return await this.fetchProductEntities('companies', filter).catch(error => { throw error });
    }

    static async fetchCompanyDetails(id: string) {
        return await this.fetchProductEntityDetails('company', id).catch(error => { throw error });;
    }

    static async fetchTherapeuticSectorDetails(id: string) {
        return await this.fetchProductEntityDetails('therapeuticSector', id).catch(error => { throw error });
    }

    static async fetchIndicationDetails(id: string) {
        return await this.fetchProductEntityDetails('indication', id).catch(error => { throw error });
    }

    static async fetchAnalystDetails(id: string) {
        return await this.fetchProductEntityDetails('analysts', id).catch(error => { throw error });
    }

    static async fetchManagementKeyPeopleDetails(id: string) {
        return await this.fetchProductEntityDetails('managementKeyPeople', id).catch(error => { throw error });
    }

    static async fetchLegendCategoryDetails(id: string) {
        return await this.fetchProductEntityDetails('legend', id).catch(error => { throw error });
    }

    static async fetchProductsBySectorIds(sectorIds: string[]): Promise<IProduct[] | []> {
        try {
            const res = await axios.request({
                url: '/products',
                params: {
                    filter: {
                        where: {
                            "sector.airtableId": {
                                inq: sectorIds
                            }
                        }
                    }
                }
            })
            return res.data;
        } catch (error) {
            return []
        }
    }

    static async fetchInitialData(): Promise<IInitialData> {
        const therapeuticSectors = await TherapeuticSector.fetchTherapeuticSectors();
        const landscapes = await Landscape.fetchLandscapes();
        const stages = await Landscape.fetchStages();
        return {
            therapeuticSectors,
            landscapes,
            stages
        }
    }


    static filterByProductProperty = (property: keyof Pick<IProduct, 'companies' | 'legendCategories' | 'therapeuticSectors' | 'partners' | 'indications' | 'sector'>, products: IProduct[], airtableId: string | string[]) => {
        let result: { [key: string]: boolean } = {};
        products.forEach(product => {

            if (!product[property]) return false;

            if (Array.isArray(product[property])) {
                if (property !== 'sector' && product[property].length < 1) return false;

                if (property === 'partners') {
                    product[property].findIndex(item => {
                        if ((airtableId as string[]).indexOf(item.airtableId) > -1) result[product.airtableId] = true
                        return false
                    })
                }
                else if (property !== 'sector' && product[property].findIndex((item: IField) => item.airtableId === airtableId) > -1)
                    result[product.airtableId] = true;
            }
            else {
                if (property === 'sector' && product[property].airtableId === airtableId) {
                    return result[product.airtableId] = true;
                }
            }

        });
        return result;
    }

    static filterByCompanyProperty = (property: keyof Pick<ICompany, 'investors' | 'managementKeyPeople' | 'analysts'>, products: IProduct[], airtableId: string | string[]) => {
        let result: { [key: string]: boolean } = {};
        products.forEach(product => {

            if (!product.companies || (product.companies || []).length === 0) return false;

            if (property === 'investors') {
                product.companies.forEach(c => {
                    (c[property] || []).findIndex(item => {
                        if ((airtableId as string[]).indexOf(item.airtableId) > -1) result[product.airtableId] = true;
                        return false
                    })
                })
            }

            product.companies.forEach(c => {
                if ((c[property] || []).findIndex(item => item.airtableId === airtableId) > -1)
                    result[product.airtableId] = true;
            })
        });
        return result;
    }


    static filterProducts(airtableId: string | string[], filterType: TFilterType, products: IProduct[]) {
        let filteredProducts: { [key: string]: any } = {};

        switch (filterType) {
            case 'company': return this.filterByProductProperty('companies', products, airtableId);

            case 'legendCategory': return this.filterByProductProperty('legendCategories', products, airtableId);

            case 'therapeuticSector': return this.filterByProductProperty('therapeuticSectors', products, airtableId);

            case 'partner': return this.filterByProductProperty('partners', products, airtableId);

            case 'indication': return this.filterByProductProperty('indications', products, airtableId);

            case 'managementKeyPeople': return this.filterByCompanyProperty('managementKeyPeople', products, airtableId);

            case 'investor': return this.filterByCompanyProperty('investors', products, airtableId);

            case 'landscape': return this.filterByProductProperty('sector', products, airtableId);

            case 'covered': {
                products.forEach(product => {
                    if (product.companies && product.companies.length > 0) {
                        product.companies.forEach(c => {
                            if (c.chardanCoverage) {
                                filteredProducts[product.airtableId] = true
                            }
                        })
                    }
                });
                break;
            }

            case 'analyst': return this.filterByCompanyProperty('analysts', products, airtableId);

        }
        return filteredProducts
    }

    static getCompanySettings(product: IProduct) {
        let settings = { public: false, private: false, covered: false };
        if (!product.companies || !product.companies.length)
            return settings;
        product.companies.forEach(company => {
            if (company.chardanCoverage)
                settings.covered = true;
            if (company.publicPrivate === 'Private')
                settings.private = true;
            if (company.publicPrivate === 'Public')
                settings.public = true;
        });
        return settings;
    }
    static settingFilterProduct(settings: IAppState['settings'], products: IProduct[] = []) {
        let filteredProducts: { [key: string]: any } = {};
        let filteredArray: IProduct[] = []
        products.forEach(product => {
            let filtered = true;
            if (settings.covered || settings.private || settings.public) {
                const companySettings = Product.getCompanySettings(product);

                if (settings.covered && !companySettings.covered) {
                    filtered = false;
                }
                if (settings.private && !settings.public && !companySettings.private)
                    filtered = false;
                if (settings.public && !settings.private && !companySettings.public)
                    filtered = false;
            }

            if (settings.partnered && !settings.nonPartnered && !product.partnered)
                filtered = false;

            if (settings.nonPartnered && !settings.partnered && product.partnered)
                filtered = false;

            if (settings.underDevelopment && !settings.notUnderDevelopment && product.noLongerInDevelopment)
                filtered = false;

            if (settings.notUnderDevelopment && !settings.underDevelopment && !product.noLongerInDevelopment)
                filtered = false;

            if (filtered)
                filteredProducts[product.airtableId] = true;
        })
        /* if (settings.covered) {
            products.forEach(product => {
                if (Array.isArray(product.companies) && product.companies.length > 0) {
                    product.companies.some(c => {
                        if (c.chardanCoverage) {
                            filteredArray.push(product);
                            return true;
                        }
                    })
                }
            });
        }

        if (settings.nonPartnered && !settings.partnered) {
            products.forEach(p => {
                if (!p.partnered) filteredArray.push(p);
            });
        }
        if (settings.partnered && !settings.nonPartnered) {
            products.forEach(p => {
                if (p.partnered) filteredArray.push(p)
            });
        }
        

        if (settings.private && !settings.public) {
            products.forEach(product => {
                if (Array.isArray(product.companies) && product.companies.length > 0) {
                    product.companies.some(c => {
                        if (c.publicPrivate === 'Private') {
                            filteredProducts[product.airtableId] = true;
                            return true;
                        }
                    })
                }
            });
        }

        if (settings.public && !settings.private) {
            products.forEach(product => {
                if (Array.isArray(product.companies) && product.companies.length > 0) {
                    product.companies.some(c => {
                        if (c.publicPrivate === 'Public') {
                            filteredProducts[product.airtableId] = true;
                            return true;
                        }
                    })
                }
            });
        }
        if (settings.public && settings.private) {
            products.forEach(p => {
                filteredProducts[p.airtableId] = true;
            })
        } */

        return filteredProducts;
    }

    static groupProductsBySectors(products: IProduct[]) {

        const grouped: Record<string, IProduct[]> = {};
        products.forEach(p => {
            const prev = grouped[p.sector.airtableId] || []
            grouped[p.sector.airtableId] = [...prev, p];
        })
        return grouped;
    }

    static getProductBarConfig(totalProducts: number, products: IProduct[], allProducts: IProduct[], sector: string, therapeuticSectors: ITherapeuticSector[], regulatoryStage: IRegulatoryStages, filteredProducts: Record<string, any> = {}): { color: string, percentage: number } {
        const color = COLOR_CONFIG[sector as keyof typeof COLOR_CONFIG];
        const config: { color: string, percentage: number } = {
            color,
            percentage: 0
        }


        const _thSector = allProducts[0] ? allProducts[0].therapeuticSectors[0] || undefined : undefined;
        if (!_thSector) return config

        const thSectorIndex = therapeuticSectors.findIndex(th => th._id.airtableId === _thSector.airtableId);
        const thSector = therapeuticSectors[thSectorIndex];

        if (!thSector) return config;
        const _index = thSector.regulatoryStages.findIndex(s => s._id.airtableId === regulatoryStage.airtableId);
        const _regulatoryStage = thSector.regulatoryStages[_index];

        if (!_regulatoryStage) return config;

        if (Object.keys(filteredProducts).length > 0)
            products = products.filter(p => !!filteredProducts[p.airtableId])

        const percentage = (products.length * 100) / totalProducts;



        // const percentage = (products.length * 100) / _regulatoryStage.total;

        return { ...config, percentage }

    }


}

