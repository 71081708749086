import Axios from 'axios';
import clsx from 'clsx';
import qs from 'qs';
import * as React from 'react';
import { useState } from 'react';
import injectSheet, { WithSheet } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router-dom';
import ReactSVG from 'react-svg';
import UniversalCookie from 'universal-cookie';
import { AppContext } from '../../App';
import LoginForm from '../../Components/AuthForm/Login';
import ResetPasswordForm from '../../Components/AuthForm/ResetPassword';
import Button from '../../Components/Buttons/Button';
import { buttonEvent } from '../../EventHandler/events';
import { getAnalyticsMetadata } from '../../middlewares/analytics';
import { IAppState } from '../../reducer/@types';
import { RESET_FILTERED_PRODUCTS, RESET_SETTINGS, SET_LOGIN } from '../../reducer/actions';
import GraphicSVG from '../../resources/images/Chardan-10.svg';
import LionImage from '../../resources/images/Lion.svg';
import AppTheme, { IAppTheme } from '../../resources/theme';
import { Device, initialState } from '../../resources/utils';
import { SVG_ID_to_airtabelId } from '../../service/mappings';
import Policy from '../Policy';
import About from './About';

export const cookie = new UniversalCookie();

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES>, RouteComponentProps { }


const FORGOT_PASS_URL = window.location.protocol + '//' + window.location.host + '/forgot-password'

// eslint-disable-next-line no-use-before-define
const Landing: React.SFC<IProps> = (props) => {
    const { me, acceptPolicy, logout } = React.useContext(AppContext);
    const { classes } = props;
    const [loginError, setloginError] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [formType, setFormType] = useState<'login' | 'forgotPass'>('login');
    const [privacyDialogOpen, setPrivacyDialogOpen] = React.useState(false);
    const policy_accepted = useSelector<IAppState, boolean>(state => state.policy_accepted || false);


    const state = useSelector<IAppState, Pick<IAppState, 'landscapes' | 'appInitialized' | 'selectedSectors' | 'isLoggedIn' | 'filteredProducts' | 'redirectPostLogin'>>(({ landscapes, appInitialized, selectedSectors, isLoggedIn, filteredProducts, redirectPostLogin }) => ({ landscapes, appInitialized, selectedSectors, isLoggedIn, filteredProducts, redirectPostLogin }));
    const dispatch = useDispatch();

    const queryState = { ...initialState, sectorIds: ['gene_therapy_gene_editing', 'rna', 'digital_therapeutics', 'microbiome', 'cell_therapy'] };

    const query = qs.stringify(queryState);

    const handleChangeText = (event: any) => { setPassword(event.target.value); }
    const handleChangeUserName = (event: any) => { setUserName(event.target.value); };

    React.useEffect(() => {
        if (state.appInitialized && !policy_accepted) {
            setPrivacyDialogOpen(true);
        }
    }, [state.appInitialized, policy_accepted])


    React.useEffect(() => {
        const hasAppliedFilters = !!Object.keys(state.filteredProducts || {}).length;
        if (hasAppliedFilters) {
            dispatch({
                type: RESET_FILTERED_PRODUCTS
            });
            dispatch({
                type: RESET_SETTINGS
            })
        }

    }, [state.filteredProducts])

    React.useEffect(() => {
        resetNotifications();
    }, [formType])


    const handleSubmitPass = async (event: any) => {
        event.preventDefault();
        resetNotifications();
        console.log('Submit credentials', userName, password);
        if (!userName || !password) {
            setloginError(`Username and password are required fields`);
            return;
        }
        setLoading(true);
        const res = await Axios.request({ url: '/Auth0Users/sign-in', data: { username: userName, password: password }, method: 'POST' }).catch(error => {
            console.log('Error', error);
            setLoading(false);
            setloginError(`Username and password do not match.`);
            throw error;
        });

        if (!res.data.access_token) {
            setLoading(false);
            setloginError(`Username and password do not match.`);
            return;
        }
        const accessTokenId = `Bearer ${res.data.access_token}`;
        Axios.defaults.headers.common['Authorization'] = accessTokenId;
        dispatch({
            type: SET_LOGIN,
            data: { id: accessTokenId },
            ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                eventName: 'USER LOGIN',
            })
        });
        await me();
        setLoading(false);
        cookie.set('id', accessTokenId, { path: '/' });
        if (state.redirectPostLogin)
            window.location.href = state.redirectPostLogin;
    }


    const resetNotifications = () => {
        setloginError('');
        setSuccessMsg('');
    }



    const handleResetPass = async (e: any) => {
        e.preventDefault();
        resetNotifications();
        if (!userName) {
            setloginError(`Please fill in your registered email`);
            return;
        }

        setLoading(true);
        try {
            const res = await Axios.request({
                url: '/Auth0Users/forget-password',
                method: 'PATCH',
                data: {
                    email: userName,
                    redirectUrl: FORGOT_PASS_URL
                }
            })
            setSuccessMsg('Reset link sent to ' + userName);
        } catch (error) {
            setloginError('Something went wrong. Please try again.')
        }
        setLoading(false);
    }

    const handleSVGClick = async (e: any) => {

        const element = e.target;
        if (!policy_accepted) return;
        // CHECK IF THE TOKEN HAS EXPIRED
        try {
            await me();
        } catch (error) {
            return;
        }


        console.log('Element', element);
        console.log('Target Key', element.id);
        if (!element)
            return;
        const elementId = element.id;
        if (!elementId)
            return;
        let queryState = { ...initialState }
        if (!SVG_ID_to_airtabelId[elementId as keyof typeof SVG_ID_to_airtabelId]) {
            return;
        }
        dispatch(buttonEvent({
            eventName: `SINGLE LANDSCAPE SELECTION - ${elementId}`,
            id: elementId
        }))
        queryState[SVG_ID_to_airtabelId[elementId as keyof typeof SVG_ID_to_airtabelId][0] as 'rowId' | 'sectorIds'] = SVG_ID_to_airtabelId[elementId as keyof typeof SVG_ID_to_airtabelId][1] as any;
        props.history.push('/view?' + qs.stringify(queryState));
    }




    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'userName') handleChangeUserName(e);
        if (e.target.name === 'password') handleChangeText(e);
    }



    const revokePolicy = () => {
        acceptPolicy(false)
    }

    const viewAll = async () => {
        if (!policy_accepted) return;
        // CHECK IF THE TOKEN HAS EXPIRED
        try {
            await me();
        } catch (error) {
            return;
        }

        dispatch(buttonEvent({
            eventName: 'VIEW ALL LANDSCAPES',
        }))

        props.history.push(`/view?${query}`)
    }



    if (!state.isLoggedIn) {
        return (
            <div className={classes.root} >
                <About />
                <div className={classes.form} style={{ flexDirection: 'column' }} >
                    {
                        formType === 'login' ?
                            <LoginForm
                                onChange={handleChange}
                                userName={userName}
                                password={password}
                                onSubmit={handleSubmitPass}
                                errorMsg={loginError}
                                successMsg={successMsg}
                                loading={loading}
                                handleResetPassword={() => setFormType('forgotPass')}
                            />
                            : null
                    }
                    {
                        formType === 'forgotPass' ?
                            <ResetPasswordForm
                                onChange={handleChange}
                                userName={userName}
                                onSubmit={handleResetPass}
                                errorMsg={loginError}
                                successMsg={successMsg}
                                loading={loading}
                                handleLogin={() => setFormType('login')}
                            /> : null
                    }
                </div>
            </div>
        )
    }




    return (
        <div className={classes.root}>
            <div className={classes.leftContent}>
                <div className="content">
                    <img src={LionImage} alt={'Chardan Logo'} />
                    <p className={classes.title} >Chardan BioScope<sup className={classes.superScript} >TM</sup></p>
                    <p className={classes.subTitle} >Interactive Landscape of <br /> Selected Healthcare Companies</p>
                    <Button onClick={viewAll} style={{ fontSize: '1vw', color: AppTheme.color.brandGold }} >View all landscapes <i className={'material-icons'} >keyboard_arrow_right</i></Button>

                </div>
            </div>
            <ReactSVG className={clsx(classes.rightContent, classes.svg)} src={GraphicSVG} onClick={handleSVGClick} />

            {
                !loading ?
                    <Policy open={privacyDialogOpen} onClose={() => setPrivacyDialogOpen(false)} /> : null
            }
        </div>
    )
}




const STYLES = (theme: IAppTheme) => ({
    root: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        // background: theme.color.background,
        background: 'white',
        justifyContent: 'space-around',
        '& a': {
            color: AppTheme.color.black
        }
    },
    leftContent: {
        // flex: 1,
        // paddingRight: '2vw',
        margin: Device.isTablet ? undefined : '0 auto',
        paddingLeft: Device.isTablet ? undefined : '50px',
        '& > .content': {
            float: 'right',
            '& > img': {
                width: 200
            }
        }
    },
    rightContent: {
        // flex: 1,
        minWidth: '50%',
        position: 'relative',
        height: '100%',
        '& svg': {
            height: '80%',
            width: '100%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(0,-50%)'
        }
    },
    description: {
        flex: 2,
        boxSizing: 'border-box',
        padding: '0px 40px',

        '& > div': {
            width: 700,
            margin: '0 auto',
            '& > img': {
                width: 200
            }
        }
    },
    descriptionContent: {
        margin: '20px 0',
        '& > p': {
            fontWeight: 400,
            fontSize: '.8vw',
            lineHeight: '1.5vw',
            marginBottom: 20,
            '& > .bullet': {
                display: 'inline-block',
                width: 5,
                height: 5,
                background: AppTheme.color.black,
                borderRadius: '50%',
                position: 'relative',
                top: '-.1vw'
            }
        }
    },
    bulletItem: {
        display: 'flex',
        alignItems: 'flex-start',
        // alignItems: 'center',
        margin: '12px 0px',
        '& p': {
            fontSize: 14,
            fontWeight: 400
        },
        '& i': {
            transform: 'translateY(-3px)',
            color: AppTheme.color.secondary,
            fontSize: 26,
            marginRight: 12
        }
    },
    descriptionHeader: {
        display: 'flex',
        justifyContent: 'space-bewteen',
        '& > div > h1': {
            fontFamily: AppTheme.font.family[0],
            fontSize: '2vw',
            fontWeight: 400
        },
        '& > div > h3': {
            fontFamily: AppTheme.font.family[0],
            fontWeight: 400,
            fontSize: '1.5vw'
        },
        '& > div > h1 > sup': {
            fontFamily: AppTheme.font.family[0],
            fontWeight: 400,
            fontSize: '1vw',
            color: AppTheme.color.black
        },
        '& .left-content': {
            flex: 1
        },
        '& .right-content': {
            '& > img': {
                width: 300
            }
        }
    },
    formCard: {
        padding: 40,
        background: 'white',
        boxSizing: 'border-box',
        maxWidth: 354,
        boxShadow: AppTheme.shadow[0],
        '& h4': {
            fontFamily: AppTheme.font.family[0],
            fontSize: 22,
            margin: '10px 0px',
            fontWeight: 400
        },
        '& hr': {
            borderColor: 'rgba(0,0,0,0.1)'
        },
        '& p, & a': {
            fontWeight: 400,
            fontSize: 12,
        },
        '& h5': {
            margin: '20px 0px 4px 0px'
        }
    },
    form: {
        flexDirection: 'column',
        flex: 1,
        marginRight: Device.isTablet ? 20 : 0,
        alignItems: 'center',
    },
    passwordInput: {
        height: 40,
        minWidth: 250,
        fontWeight: 400,
        border: '1px solid #ccc',
        padding: '0px 10px',
        outline: 'none',
        fontSize: 14,
        margin: '5px 0px',
        '&:focus': {
            borderColor: '#192354'
        }
    },
    passwordSubmitButtonContainer: {
        marginTop: 30,
        width: '100%',
        textAlign: 'center',
        '& > button': {
            height: 50,
            fontSize: 18,
            fontWeight: 400,
            fontFamily: AppTheme.font.family[0],
            width: '100%',
            background: '#192354',
            color: '#fff',
            outline: 'none',
            cursor: 'pointer'
        }
    },
    loginError: {
        color: 'red',
        fontSize: 10,
        fontWeight: 'normal',
        marginTop: 10,
        textAlign: 'center'
    },
    successMessage: {
        color: 'green',
        fontSize: 10,
        fontWeight: 'normal',
        marginTop: 10,
        textAlign: 'center'
    },
    svg: {
        // width: '50%'
    },
    title: {
        fontFamily: AppTheme.font.family[0],
        fontSize: '2vw',
        fontWeight: 400,
        margin: '20px 0px',
        position: 'relative'
    },
    superScript: {
        fontFamily: AppTheme.font.family[0],
        fontSize: '1vw',
        fontWeight: 400,
        color: theme.color.black,
        textTransform: 'uppercase'
    },
    subTitle: {
        fontFamily: AppTheme.font.family[0],
        fontSize: '1.5vw',
        fontWeight: 400
    }
})

export default injectSheet(STYLES)(withRouter(Landing));