import React, { FC, useCallback, useEffect } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../reducer/@types';
import { CLEAR_SELECTED_LEGEND_CATEGORY, LEGEND_CATEGORY_RECEIVED } from '../../../reducer/actions';
import { Product } from '../../../models/product';
import Divider from '../DetailComponents/Divider';
import ChipsList from '../DetailComponents/ChipsList';
import { parseAggregatedArray } from '../../../resources/utils';
import { applyFilter } from '../../../reducer';
import { IField } from '../../../models/therapeuticSector';
import CoverageBadge from '../DetailComponents/CoverageBadge';

// eslint-disable-next-line no-use-before-define
interface LegendDetailsProps extends WithSheet<typeof STYLES> {
    id: string
}

const selectSelectedLegendCategory = (state: IAppState): Pick<IAppState, 'selectedLegendCategory'> => ({
    selectedLegendCategory: state.selectedLegendCategory
})

const LegendDetails: FC<LegendDetailsProps> = ({ classes, id }) => {

    const { selectedLegendCategory } = useSelector(selectSelectedLegendCategory);

    const dispatch = useDispatch();

    const getData = useCallback(async () => {
        dispatch({ type: CLEAR_SELECTED_LEGEND_CATEGORY });
        const data = await Product.fetchLegendCategoryDetails(id).catch(error => { throw error });
        dispatch({ type: LEGEND_CATEGORY_RECEIVED, data });
    }, [dispatch, id]);

    useEffect(() => { if ((selectedLegendCategory && selectedLegendCategory.airtableId !== id) || !selectedLegendCategory) getData(); }, [id, getData, selectedLegendCategory]);

    const ts = (selectedLegendCategory ? parseAggregatedArray(selectedLegendCategory.therapeuticSectors || []) : []);
    const companies = (selectedLegendCategory ? parseAggregatedArray(selectedLegendCategory.companies || []) : []);
    const partners = (selectedLegendCategory ? parseAggregatedArray(selectedLegendCategory.partners || []) : []);
    const investors = (selectedLegendCategory ? parseAggregatedArray(selectedLegendCategory.investors || []) : []);
    const analysts = (selectedLegendCategory ? parseAggregatedArray(selectedLegendCategory.analysts || []) : []);

    const handleTSClick = (ts: IField) => applyFilter('therapeuticSector', ts.airtableId);
    const handleComapnyClick = (company: IField) => applyFilter('company', company.airtableId);
    const handlePartnerClick = (partner: IField) => applyFilter('partner', [partner.airtableId]);
    const handleInvestorClick = (investor: IField) => applyFilter('investor', [investor.airtableId]);

    return (
        <div>
            {
                selectedLegendCategory
                    ?
                    (
                        <>
                            <p className={classes.title}>{selectedLegendCategory.name || ''}</p>
                            <Divider ySpacing={9} />
                            {ts.length > 0 ? <ChipsList collapsedChipCount={5} title={'Therapeutic Sectors'} items={ts} onClick={handleTSClick} /> : null}
                            {companies.length > 0 ? <ChipsList collapsedChipCount={5} title={'Companies'} items={companies} onClick={handleComapnyClick} /> : null}
                            {partners.length > 0 ? <ChipsList collapsedChipCount={5} title={'Partners'} items={partners} onClick={handlePartnerClick} /> : null}
                            {investors.length > 0 ? <ChipsList collapsedChipCount={5} title={'Investors'} items={investors} onClick={handleInvestorClick} /> : null}
                            {analysts.length > 0 ? <CoverageBadge customTitle={'Chardan Researchers'} analysts={analysts} /> : null}
                        </>
                    )
                    : null
            }
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {

    },
    title: {
        fontSize: 14,
        lineHeight: '20px'
    }
});

export default injectSheet(STYLES)(LegendDetails);