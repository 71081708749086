import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';

interface IProps extends WithSheet<typeof STYLES> {
    duration?: number
}

const Delay: React.FC<IProps> = (props) => {
    const { classes, duration = 200 } = props
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, duration);
    }, [])

    return (
        <div className={show ? classes.show : classes.hidden}>
            {props.children}
        </div>
    )
}

const STYLES = (theme: any) => ({
    show: {
        display: 'inherit'
    },
    hidden: {
        display: 'none'
    }
})

export default injectSheet(STYLES)(Delay)