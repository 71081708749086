import axios from 'axios';


export interface TUser {
    name: string
    nickname: string
    email: string
    id: string
    picture: string
    email_verified: boolean
    identities: Array<any>
}

export class Auth {
    static getTicketInfo = (ticket: string) => axios.request<TUser>({
        url: `ForgetPasswordRequests/ticket/${ticket}`
    })

    static resetPassword = (ticket: string, password: string) => axios.request({
        url: `/Auth0Users/reset-password`,
        method: 'PATCH',
        data: {
            ticket,
            password
        }
    })
}