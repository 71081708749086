import React, { FC, useEffect } from "react";
import ToggleButton from "../../ToggleButton";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../../../reducer/@types";
import { parseQs, updateUrl } from "../../../resources/utils";
import { SETTING_UPDATED } from '../../../reducer/actions';
import { Product } from "../../../models/product";
import { updateSettings } from "../../../reducer/redux-actions";
import { getProductsFromFilteredProducts } from ".";


const UnderDevelopmentFilter: FC = (props) => {
    const { allProducts = [] } = useSelector<IAppState, Pick<IAppState, 'allProducts'>>(({ allProducts }) => ({ allProducts }));
    const { settings, products = [], originalFilterProducts } = useSelector<IAppState, Pick<IAppState, 'settings' | 'products' | 'originalFilterProducts'>>(({ settings, products, originalFilterProducts }) => ({ settings, products, originalFilterProducts }));
    const queryState = parseQs();
    const dispatch = useDispatch();
    const { underDevelopment, notUnderDevelopment } = settings;

    useEffect(() => {
        const { underDevelopment: QueryUnderDevelopment, notUnderDevelopment: QueryNotUnderDevelopment } = queryState.settings;
        if (QueryNotUnderDevelopment !== notUnderDevelopment || QueryUnderDevelopment !== underDevelopment) {
            dispatch(updateSettings({
                settings: queryState.settings,
                products: Product.settingFilterProduct(queryState.settings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
            }))
            // dispatch({
            //     type: SETTING_UPDATED,
            //     data: {
            //         settings: queryState.settings,
            //         products: Product.settingFilterProduct(queryState.settings, products)
            //     }
            // });
        }
    }, [queryState.settings, settings]);

    const handleToggleUnderDevelopment = () => {
        let notUnderDevelopmentFlag = underDevelopment ? true : notUnderDevelopment;
        const updatedSettings = {
            ...settings,
            notUnderDevelopment: notUnderDevelopmentFlag,
            underDevelopment: !underDevelopment
        }
        dispatch(updateSettings({
            settings: updatedSettings,
            products: Product.settingFilterProduct(updatedSettings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
        }, { ...updatedSettings, eventName: 'TOGGLE UNDER_DEVELOPMENT' }))
        // dispatch({
        //     type: SETTING_UPDATED,
        //     data: {
        //         settings: updatedSettings,
        //         products: Product.settingFilterProduct(updatedSettings, products)
        //     }
        // });
        updateUrl({ ...queryState, settings: updatedSettings });
    }

    const handleToggleNotUnderDevelopment = () => {
        let underDevelopmentFlag = notUnderDevelopment ? true : underDevelopment;
        const updatedSettings = {
            ...settings,
            notUnderDevelopment: !notUnderDevelopment,
            underDevelopment: underDevelopmentFlag
        }
        dispatch(updateSettings({
            settings: updatedSettings,
            products: Product.settingFilterProduct(updatedSettings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
        }, { ...updatedSettings, eventName: 'TOGGLE NOT_UNDER_DEVELOPMENT' }))
        // dispatch({
        //     type: SETTING_UPDATED,
        //     data: {
        //         settings: updatedSettings,
        //         products: Product.settingFilterProduct(updatedSettings, products)
        //     }
        // });
        updateUrl({ ...queryState, settings: updatedSettings });
    }

    return (
        <>
            <ToggleButton active={underDevelopment} onToggle={handleToggleUnderDevelopment} label={'PRODUCTS UNDER DEVELOPMENT'} />
            <ToggleButton active={notUnderDevelopment} onToggle={handleToggleNotUnderDevelopment} label={'PRODUCTS NO LONGER UNDER DEVELOPMENT'} />
        </>
    )
}

export default UnderDevelopmentFilter;