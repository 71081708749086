import { IProduct, Product } from "../models/product";


export interface IServiceProducts {
    [key: string]: IProduct[]
}

class _ProductService {
    products: IServiceProducts = {}
    allProducts: IProduct[] = [];
    isAllProductsFetched: boolean = false

    async getProducts(sectorIds: string[] | undefined): Promise<{
        filteredProducts: IProduct[],
        allProducts: IProduct[]
    }> {
        console.log("sectorIds", sectorIds);
        if (!this.isAllProductsFetched) {
            try {
                const _products = await Product.fetchProducts();
                if (_products)
                    this.allProducts = _products;

            } catch (error) {
                throw error
            }
        }
        if (sectorIds) {
            let products: IProduct[] = []
            let sectorIdsWithoutProducts: string[] = []

            sectorIds.forEach(sectorId => {
                if (this.products[sectorId])
                    products = [...products, ...this.products[sectorId]]
                else {
                    sectorIdsWithoutProducts.push(sectorId);
                }
            })
            if (sectorIdsWithoutProducts.length === 0)
                return { filteredProducts: products, allProducts: this.allProducts };


            const _data = await Product.fetchProductsBySectorIds(sectorIdsWithoutProducts);
            _data.forEach(product => this.products[product.sector.airtableId] ? this.products[product.sector.airtableId].push(product)
                : this.products[product.sector.airtableId] = [product]
            )
            return { filteredProducts: [...products, ..._data], allProducts: this.allProducts };

        }
        else
            return { filteredProducts: [], allProducts: this.allProducts };
    }
}


export const ProductService = new _ProductService();