import React, { FC, useCallback, useEffect } from 'react';
import AppTheme, { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { IAppState } from '../../../reducer/@types';
import { Product } from '../../../models/product';
import ChipToggle from '../../ChipToggle';
import { INVESTORS_RECIEVED } from '../../../reducer/actions';
import { applyFilter } from '../../../reducer';
import Divider from '../DetailComponents/Divider';
import Button from '../../Buttons/Button';
import { groupByFirstLetter } from '../../../resources/utils';
import clsx from 'clsx';
import AlphabetsButtonBar from '../../Buttons/AlphabetsButtonBar';
import SidebarChipItem from '../../SidebarChipItem';
import ContactButton from '../../ContactButton';
import Delay from '../../Delay';

// eslint-disable-next-line no-use-before-define
interface InvestorsProps extends WithSheet<typeof STYLES> {

}

const selectInvestors = createSelector<IAppState, IAppState, Pick<IAppState, 'investors' | 'filter' | 'selectedSectors'>>(
    state => state,
    ({ investors, filter, selectedSectors }) => ({ investors, filter, selectedSectors })
)

const Investors: FC<InvestorsProps> = ({ classes }) => {

    const { investors = [], filter, selectedSectors } = useSelector(selectInvestors);

    const dispatch = useDispatch();

    const getData = useCallback(async (filter?: any) => {
        const data = await Product.fetchInvestors(filter).catch(error => { throw error });
        dispatch({ type: INVESTORS_RECIEVED, data });
    }, [dispatch]);

    useEffect(() => {
        let filter = selectedSectors.map(item => ({ 'sector.airtableId': item }));
        getData({ where: { "$or": filter } });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSectors]);

    // const handleClick = (id: string) => filter.id === id ? applyFilter('', '') : applyFilter('investor', id);

    const handleClick = (id: string) => {
        let arr = filter.type === 'investor' ? filter.id as string[] || [] : [];
        let i = arr.indexOf(id);
        i === -1 ? arr.push(id) : arr.splice(i, 1);
        applyFilter('investor', arr);
    }

    const handleClear = () => applyFilter('', '');

    let groupedData = groupByFirstLetter({ data: ((investors || [])) });

    const handleAlphabetClick = (alphabet: string) => {
        let el = window.document.getElementById(`investor-alphabet-group-${alphabet}`);
        if (el) {
            el.scrollIntoView({ block: 'start' });
        }
    }

    return (
        <div className={classes.root} >
            <div className={classes.chipsContainer} >
                {
                    (filter.id || []).length > 0 && filter.type === 'investor' ?
                        <div className={classes.clearButton} >
                            <Button onClick={handleClear} >{`[x] Clear`}</Button>
                            <Divider size='full' />
                        </div> : null
                }
                {
                    (groupedData || []).map((grpItems, i) => {
                        return (
                            <div key={i} className={clsx(classes.chipItem)} id={`investor-alphabet-group-${grpItems.group.toUpperCase()}`} >
                                <p className={classes.stickyLabel}>{grpItems.group}</p>
                                {
                                    grpItems.items.map((investor, i) => {
                                        return (
                                            <div key={investor.airtableId} className={clsx(classes.chipItem)} >
                                                <ChipToggle
                                                    title={<SidebarChipItem name={investor.name} total={investor.total} type="C" />}
                                                    active={(filter.id as string[] || []).indexOf(investor.airtableId) !== -1}
                                                    id={investor.airtableId}
                                                    onToggle={handleClick}
                                                    inactiveColor={'#ecf1fd'}
                                                    inactiveTextColor={AppTheme.color.primaryLight}
                                                    activeColor={'#fff4d5'}
                                                    maxWidth={150}
                                                    activeTextColor={AppTheme.color.primaryLight}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                <Delay duration={600}>
                    <ContactButton />
                </Delay>
            </div>
            <AlphabetsButtonBar groups={groupedData.map(item => item.group)} handleAlphabetClick={handleAlphabetClick} />
        </div>
        // <div className={classes.root} >
        //     {
        //         (filter.id || []).length > 0 && filter.type === 'investor' ?
        //             <div className={classes.clearButton} >
        //                 <Button onClick={handleClear} >{`[x] Clear`}</Button>
        //                 <Divider size='full' />
        //             </div> : null
        //     }
        //     {
        //         (investors || []).map(item => {
        //             return (
        //                 <div key={item.airtableId} >
        //                     <ChipToggle
        //                         title={`${item.name} (${item.total})` || ''}
        //                         active={(filter.id as string[] || []).indexOf(item.airtableId) !== -1}
        //                         id={item.airtableId}
        //                         onToggle={handleClick}
        //                         inactiveColor={'#ecf1fd'}
        //                         inactiveTextColor={AppTheme.color.primaryLight}
        //                         activeColor={'#fff4d5'}
        //                         activeTextColor={AppTheme.color.primaryLight}
        //                     />
        //                 </div>
        //             )
        //         })
        //     }
        // </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        display: 'flex',
        overflowX: 'hidden',
    },
    chipsContainer: {
        flex: 1,
        height: 'calc(100vh - 150px)',
        overflowY: 'auto'
    },
    groupItem: {
        '& > p': {
            marginLeft: 12
        },
        marginBottom: 8
    },
    stickyLabel: {
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 1,
    },
    chipItem: { marginBottom: 8 },
    clearButton: {
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        '& button': {
            // transition: '400ms ease-in-out',
            background: 'transparent',
            color: theme.color.primaryLight,
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            '&:focus': {
                outline: 'none',
            },
            '&:active': {
                // transform: 'rotate(360deg)'
            }
        }
    }
});

export default injectSheet(STYLES)(Investors);