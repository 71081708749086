import React, { useEffect, useContext, useState } from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { Link } from 'react-router-dom';
import { IAppTheme, AppTheme } from '../../resources/theme';
import { useSpring, animated } from 'react-spring';
import Sectors from './Sectors';
import { parseQs, updateUrl, initialState, getQueryString, Device } from '../../resources/utils';
import logo from '../../resources/images/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../reducer/@types';
import { applyFilter } from '../../reducer';
import { TOGGLE_DRAWER } from '../../reducer/actions';
import Button from '../Buttons/Button';
import { AppContext } from '../../App';
import Filters from './Filters';
import Snackbar from '../Snackbar';
import Dialog from '../Modal/Dialog';
import Contact from '../../screens/Contact';
import clsx from 'clsx';
import ContactButton from '../ContactButton';
import ViewButtonGroup from './ViewButtonGroup';
import ToggleAggregation from './ToggleAggregation';
import { useClickEventListener } from '../../EventHandler/hook';
import { enableAnalyticsTracking } from '../../reducer/redux-actions';
import ChipToggle from '../ChipToggle';
import { OutsideClick } from '../OutsideClick';



// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> { }




const AppHeader: React.FC<IProps> = (props) => {

    const { classes } = props;

    const queryState = parseQs();

    // const [open, setOpen] = useState(false);





    // eslint-disable-next-line react-hooks/exhaustive-deps
    const Sector = React.useMemo(() => <Sectors />, [queryState.sectorIds]);
    const Filter = React.useMemo(() => <Filters />, [queryState.sectorIds]);

    return (
        <div className={classes.root}>
            <NavButtons classes={props.classes} />
            <Link to="/">
                <img src={logo} style={{ width: 50, margin: '0px 8px' }} alt='Chardan Logo' />
            </Link>
            <div className={classes.logoContainer}>
                Chardan BioScope
            </div>
            <div className={classes.divider} />
            {Sector}
            {/* <ToggleButton {...props} /> */}
            {Filter}
            <ViewButtonGroup />
            <ToggleAggregation />
            <div className={classes.spacer} />
            <Release {...props} />
        </div >
    )
}



const Release: React.FC<IProps> = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const tracking_enabled = useSelector<IAppState, boolean>(state => state.tracking_enabled)
    const copyLinkButtonRef = React.useRef<HTMLButtonElement>(null)
    useClickEventListener(copyLinkButtonRef, { eventName: 'COPY LINK' })

    const handleRleaseClick = () => {
        dispatch({ type: TOGGLE_DRAWER, data: true });
        let queryState = parseQs();
        updateUrl({ ...queryState, showReleaseNotes: '1' });
    }

    const { logout } = useContext(AppContext);
    const toggleTracking = () => {
        dispatch(enableAnalyticsTracking(!tracking_enabled))
    }
    const copyLink = () => {
        setShowSnackbar(true);
        const url = window.location.href;
        const el = document.createElement('textarea');
        el.value = url
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    let getSnackbarInfo;


    const getActions = () => {
        return (
            <>
                <div className={classes.releaseContainer}  >
                    <div className="button" onClick={logout}>LOG OUT</div>
                </div>
                {/* <button onClick={toggleTracking}>toggle tracking</button> */}
                <div className={classes.buttonGroup}>
                    <div className={classes.iconButtonAlt}>
                        <ContactButton variant="full" />
                    </div>

                    <div className={clsx(classes.iconButton, classes.pointer)}>
                        <button ref={copyLinkButtonRef} onClick={copyLink} >
                            <i className="material-icons">open_in_browser</i>
                        </button>
                    </div>

                </div>
            </>
        )
    }

    return (
        <>
            {Device.isTablet ? (
                <div>
                    <ChipToggle rootStyle={classes.chipToggleButton} onToggle={() => setExpanded(!expanded)} title={(
                        <i className="material-icons">{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
                    )} active={expanded} />
                </div>
            ) : null}

            {Device.isTablet ? expanded ? (
                <OutsideClick onClickOut={() => setExpanded(false)}>
                    <div className={classes.expandedContainer}>
                        {getActions()}
                    </div>
                </OutsideClick>
            ) : null : getActions()}

            <Snackbar open={showSnackbar} onClose={() => setShowSnackbar(false)} text={'Link Copied to share'} />
        </>
    )
}


const NavButtons: React.FC<Pick<IProps, 'classes'>> = React.memo((props) => {
    const { classes } = props;
    const refreshButtonRef = React.useRef<HTMLButtonElement>(null)
    const backButtonRef = React.useRef<HTMLButtonElement>(null)
    const forwardButtonRef = React.useRef<HTMLButtonElement>(null)
    useClickEventListener(refreshButtonRef, { eventName: 'PAGE REFRESH' })
    useClickEventListener(backButtonRef, { eventName: 'PAGE BACK' })
    useClickEventListener(forwardButtonRef, { eventName: 'PAGE FORWARD' })

    const goBack = () => window.history.back();

    const goForward = () => window.history.forward();

    const reset = () => {
        let el = document.getElementById('row-container');
        console.log('El', el);
        el && el.scrollTo({ top: 0, behavior: 'smooth' });
        updateUrl(initialState)
    };

    return (
        <div className={classes.navButtonContainer}>
            <button ref={backButtonRef} onClick={goBack}>
                <i className="material-icons">keyboard_arrow_left</i>
            </button>
            <button ref={forwardButtonRef} onClick={goForward}>
                <i className="material-icons">keyboard_arrow_right</i>
            </button>
            <button ref={refreshButtonRef} onClick={reset} >
                <i className="material-icons">refresh</i>
            </button>
        </div>
    )
})



// const ToggleButton: React.FC<IProps> = (props) => {
//     const { classes } = props;
//     const { filter } = useSelector<IAppState, Pick<IAppState, 'filter'>>(({ filter }) => ({ filter }));
//     const toggled = filter.type === 'covered';
//     const queryState = parseQs();
//     // const [toggled, setToggled] = React.useState(false);
//     const track_spring = useSpring({
//         background: toggled ? AppTheme.color.accent : AppTheme.color.primaryLight,
//     })
//     const thumb_spring = useSpring({
//         left: toggled ? 20 : 0
//     });

//     const handleToggle = () => applyFilter(toggled ? '' : 'covered', '');

//     useEffect(() => {
//         if (queryState.filter.type === 'covered' && filter.type !== 'covered') {
//             applyFilter('covered', '');
//         }
//     }, [queryState.filter, filter.type]);

//     return (
//         <div className={classes.toggleContainer} onClick={handleToggle} >
//             <animated.div className={classes.toggle} style={track_spring}>
//                 <animated.button className={classes.thumb} style={thumb_spring}></animated.button>
//             </animated.div>
//             <p className={classes.toggleText}>COVERED</p>
//         </div>
//     )
// }


const STYLES = (theme: IAppTheme) => ({
    root: {
        boxSizing: 'border-box',
        padding: '0px 14px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: theme.color.primary
    },
    chipToggleButton: {
        height: 27,
        padding: '3px 6px',
        width: 40,
        border: `1px solid ${AppTheme.color.lightBlue}`,
        '& .material-icons': {
            position: 'relative',
            top: -2
        }
    },
    expandedContainer: {
        zIndex: 1,
        right: Device.isTablet ? -5 : -45,
        background: 'white',
        boxSizing: 'border-box',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `2px 2px 9px 0px rgba(176,176,176, 0.5)`,
        top: 65,
        padding: 20,
        borderRadius: 8,
        '& > div': {
            margin: '4px 0px'
        },
        '&::before': {
            content: "' '",
            width: 27,
            height: Device.isTablet ? 20 : 27,

            zIndex: 1,
            position: 'absolute',
            top: -4,
            right: '16%',
            background: 'white',
            transform: 'rotate(45deg)'

        }
    },
    logoContainer: {
        color: 'white',
        fontWeight: 'normal',
        display: 'flex',
        justifyContent: 'space-around',
        marginRight: 11,
        fontFamily: 'Fjalla One',
        fontSize: 18
        // letterSpacing: 5
    },
    divider: {
        width: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        marginRight: 20,
        height: '60%'
    },
    spacer: {
        flex: '1 1 0'
    },
    navButtonContainer: {
        width: 80,
        height: 42,
        display: 'flex',
        background: theme.color.primaryDark,
        borderRadius: 9,
        cursor: 'pointer',
        '& button': {
            transition: '200ms linear',
            flex: 1,
            borderRadius: 9,
            background: 'transparent',
            padding: 0,
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                background: theme.color.primaryLight
            }
        }
    },
    toggleContainer: {
        background: theme.color.primaryDark,
        borderRadius: 9,
        padding: '8px 10px',
        lineHeight: 0,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    toggle: {
        height: 16,
        width: 34,
        position: 'relative',
        borderRadius: 15,
        marginRight: 8
    },
    thumb: {
        position: 'absolute',
        background: 'white',
        borderRadius: '50%',
        width: 15, height: 15,
        border: 'none',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none'
        }
    },
    toggleText: {
        fontSize: 8,
        color: theme.color.white
    },
    releaseContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // paddingRight: 8,
        '& .title': {
            fontSize: 6,
            color: 'white',
            textTransform: 'uppercase'
        },
        '& .button': {
            color: Device.isTablet ? 'black' : 'white',
            background: 'transparent',
            padding: 0,
            fontSize: 9,
            border: 'none',
            textTransform: 'uppercase',
            '&:focus': {
                outline: 'none'
            }
        },
        '& > div': {
            marginBottom: 4,
            transition: '200ms linear',
            '&:hover': {
                color: '#e84a4a',
            }
        },
        cursor: 'pointer'
    },
    title: {
        fontSize: 18,
        color: 'white',
        fontFamily: 'Fjalla One'
    },
    buttonGroup: {
        margin: '0px 20px',
        display: 'flex',
        alignItems: 'center'
    },
    pointer: {
        cursor: 'pointer'
    },
    iconButton: {
        height: 40,
        width: 40,
        borderRadius: 20,
        backgroundColor: '#5b6391',
        display: 'flex',
        // cursor: 'pointer',
        margin: '0px 6px',
        '& > button': {
            height: 40,
            width: 40,
            borderRadius: 20,
            background: 'transparent',
            padding: 0,
            color: AppTheme.color.primary,
            border: 'none',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                background: 'transparent'
            }
        }
    },
    iconButtonAlt: {
        height: 40,
        borderRadius: 20,
        backgroundColor: '#5b6391',
        display: 'flex',
        // cursor: 'pointer',
        margin: '0px 6px',
        '& > button': {
            height: 40,
            width: 100,
            padding: '0px 10px',
            borderRadius: 20,
            background: 'transparent',
            // color: 'white',
            border: 'none',
            cursor: 'pointer',
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                background: 'transparent'
            },
            '& > div': {
                margin: 0,
            },
            '& > div > p': {
                textDecoration: 'none',
                margin: 0
            }
        }
    }
})

export default injectSheet(STYLES)(AppHeader);