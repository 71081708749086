import Axios from "axios";

export class Legends {
    static async fetchLegends() {
        const res = await Axios.request({
            url: '/products/legends'
        }).catch(error => {
            console.log('Error Getting Legends', error.response);
            throw error.response;
        });
        return res.data
    }
}