import * as React from 'react'
import { useState } from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import Dialog from '../../Components/Modal/Dialog';
import { useContactService, EMAIL_KEY, REASON_KEY, MESSAGE_KEY } from './hook';
import FormSubmitted from './FormSubmitted';
import Form from './Form';
import { useClickEventListener } from '../../EventHandler/hook';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsMetadata } from '../../middlewares/analytics';
import { IAppState, IUser } from '../../reducer/@types';
import { closeContactDialog } from './redux-config';

interface IProps extends WithSheet<typeof STYLES> { }




const ContactDialog: React.FC<IProps> = (props) => {
    const { classes } = props;
    const appUser = useSelector<IAppState, IUser | undefined>(state => state.appUser)
    const dialogOpen = useSelector<IAppState, boolean>(state => state.contactDialogOpen)
    const { form, handleChange, loading, submit, reasonOptions, errors, resetForm, submitted } = useContactService(appUser);
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLButtonElement>(null);
    const isChardanUser = appUser && /.*chardan.com$/ig.test(appUser.email);


    useClickEventListener<HTMLButtonElement>(ref, {
        eventName: 'CONTACT FORM OPEN'
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.persist();
        e.preventDefault();
        dispatch({
            type: '',
            ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                eventName: 'CONTACT FORM SUBMIT'
            })
        })
        try {
            await submit();
            // closeDialog();
        } catch (error) {

        }
    }


    const closeDialog = () => {
        dispatch(closeContactDialog());
        dispatch({
            type: '-',
            ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                eventName: 'CONTACT FORM CLOSE'
            })
        })
        resetForm();
    }



    return (

        <Dialog
            classes={{ content: classes.formContainer }}
            open={dialogOpen}
            onClose={() => null}
        >
            {
                submitted ?
                    <FormSubmitted close={closeDialog} />
                    : !isChardanUser && appUser ? (
                        <Form
                            loading={loading}
                            user={appUser}
                            formData={form}
                            closeDialog={closeDialog}
                            errors={errors}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            reasonOptions={reasonOptions}
                        />
                    ) : (
                            <Form
                                user={appUser}
                                isChardanUser={true}
                                loading={loading}
                                formData={form}
                                closeDialog={closeDialog}
                                errors={errors}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                                reasonOptions={reasonOptions}
                            />
                        )
            }
        </Dialog>
    )
}




export const GREY = '#828282';
export const DARK_GREY = '#444'

const STYLES = (theme: any) => ({
    root: {
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    // dialogRoot: {
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //     right: 0,
    //     bottom: 0
    // },

    formContainer: {
        width: 320,
        padding: 30,
        paddingTop: 14,
        cursor: 'initial !important',
        boxSizing: 'borderBox',
        borderRadius: 8,
        boxShadow: `0px 3px 6px rgba(0,0,0,0.2)`,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff'
    },
})

export default injectSheet(STYLES)(ContactDialog)