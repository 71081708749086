import React, { FC } from 'react'
import { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    handleAlphabetClick: (alphabet: string) => void
    groups: string[]
}

const AlphabetsButtonBar: FC<IProps> = ({ classes, handleAlphabetClick, groups }) => {
    return (
        <div className={classes.alphabetButtonsContainer} >
            {
                '#abcdefghijklmnopqrstuvwxyz'.split('').map((item, i) => {
                    let isDisabled = groups.findIndex(groupName => groupName.toUpperCase() === item.toUpperCase()) === -1;
                    return <div key={i} className={clsx(classes.alphabetButton, { [classes.disabled]: isDisabled })} onClick={() => handleAlphabetClick(item.toUpperCase())} >{item.toUpperCase()}</div>
                })
            }
        </div >
    )
}

const STYLES = (theme: IAppTheme) => ({
    alphabetButtonsContainer: {
        height: 'calc(100vh - 150px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    alphabetButton: {
        fontSize: 10,
        color: theme.color.primary,
        transition: '200ms',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        width: 24,
        borderRadius: 12,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.color.primary,
            color: 'white',
        }
    },
    disabled: {
        color: 'darkGrey',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'darkGrey',
            color: 'white'
        }
    }
})

export default injectSheet(STYLES)(AlphabetsButtonBar)