import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import AppTheme from '../../resources/theme';
import LionImage from '../../resources/images/Lion.svg';
import TabletImage from '../../resources/images/Tablet.png';
import { Device } from '../../resources/utils';

interface IProps extends WithSheet<typeof STYLES> { }




const BULLET_POINTS = [
    {
        title: 'Explore',
        desc: 'Explore select companies, products, indications, investors, and partners across various modalities and phases of development'
    },
    {
        title: 'Target',
        desc: 'Target potential partners or investors based on gaps in product portfolios'
    },
    {
        title: 'Access',
        desc: 'Access company profiles detailing areas of focus, key management, investors and partners'
    },
    {
        title: 'Create',
        desc: 'Create and share heat maps'
    }
]


const About: React.FC<IProps> = (props) => {

    const { classes } = props
    return (
        <div className={classes.description}>
            <div>
                <img src={LionImage} />
                <div className={classes.descriptionHeader}>
                    <div className="left-content">
                        <h1>Chardan BioScope<sup>TM</sup></h1>
                        <h3>Interactive Landscape of<br /> Selected Healthcare Companies</h3>
                    </div>
                    <div className="right-content">
                        <img src={TabletImage} />
                    </div>
                </div>
                <div className={classes.descriptionContent}>
                    <p>
                        Featuring thousands of products from private and public companies across:
                        <br />
                        Cell-Based Immunotherapy <span className="bullet"></span> Gene Therapy & Gene Editing <span className="bullet"></span> Microbiome <span className="bullet"></span> RNA <span className="bullet"></span> Digital Therapeutics
                    </p>
                    {
                        BULLET_POINTS.map(p => (
                            <div className={classes.bulletItem} key={p.title}>
                                <i className="material-icons">check_circle_outline</i>
                                <p><b>{p.title}:</b> {p.desc}</p>

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

const STYLES = (theme: any) => ({
    description: {
        flex: 2,
        boxSizing: 'border-box',
        padding: '0px 40px',

        '& > div': {
            width: Device.isTablet ? 560 : 700,
            margin: '0 auto',
            '& > img': {
                width: 200
            }
        }
    },
    descriptionContent: {
        margin: '20px 0',
        '& > p': {
            fontWeight: 400,
            fontSize: '.8vw',
            lineHeight: '1.5vw',
            marginBottom: 20,
            '& > .bullet': {
                display: 'inline-block',
                width: 5,
                height: 5,
                background: AppTheme.color.black,
                borderRadius: '50%',
                position: 'relative',
                top: '-.1vw'
            }
        }
    },
    bulletItem: {
        display: 'flex',
        alignItems: 'flex-start',
        // alignItems: 'center',
        margin: '12px 0px',
        '& p': {
            fontSize: 14,
            fontWeight: 400
        },
        '& i': {
            transform: 'translateY(-3px)',
            color: AppTheme.color.secondary,
            fontSize: 26,
            marginRight: 12
        }
    },
    descriptionHeader: {
        display: 'flex',
        justifyContent: 'space-bewteen',
        '& > div > h1': {
            fontFamily: AppTheme.font.family[0],
            fontSize: '2vw',
            fontWeight: 400
        },
        '& > div > h3': {
            fontFamily: AppTheme.font.family[0],
            fontWeight: 400,
            fontSize: '1.5vw'
        },
        '& > div > h1 > sup': {
            fontFamily: AppTheme.font.family[0],
            fontWeight: 400,
            fontSize: '1vw',
            color: AppTheme.color.black
        },
        '& .left-content': {
            flex: 1
        },
        '& .right-content': {
            '& > img': {
                width: 300
            }
        }
    },
})

export default injectSheet(STYLES)(About)