import React, { FC, useEffect } from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';

export interface SnackbarProps extends WithSheet<typeof STYLES> {
    text: string;
    open: boolean;
    onClose: () => void;
    timeout?: number;
}

const Snackbar: FC<SnackbarProps> = ({ open, timeout = 3000, onClose, classes, text }) => {

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                onClose();
            }, timeout);
        }
    }, [open]);
    const displayClass = open ? classes.visible : classes.hidden
    return (
        <div className={clsx(classes.root, displayClass)} >
            <p className={classes.text} >{text}</p>
        </div>
    )
}

const STYLES = () => ({
    root: {
        height: 44,
        padding: '8px 16px',
        backgroundColor: '#90caf9',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        transition: '200ms'
    },
    text: {
        fontSize: 18,
        fontWeight: 'normal',
    },
    visible: {
        left: 20, bottom: 20,
    },
    hidden: {
        bottom: -100
    }
});


export default injectSheet(STYLES)(Snackbar);