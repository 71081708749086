import AppTheme from "../../resources/theme";

export const STYLES = (theme: any) => ({
    formCard: {
        padding: 40,
        background: 'white',
        boxSizing: 'border-box',
        maxWidth: 354,
        boxShadow: AppTheme.shadow[0],
        // boxShadow: `0px 2px 18px rgba(0,0,0,0.2)`,
        '& h4': {
            fontFamily: AppTheme.font.family[0],
            fontSize: 22,
            margin: '10px 0px',
            fontWeight: 400
        },
        '& hr': {
            borderColor: 'rgba(0,0,0,0.1)'
        },
        '& p, & a': {
            fontWeight: 400,
            fontSize: 12,
        },
        '& h5': {
            margin: '20px 0px 4px 0px'
        }
    },
    container: {
        display: 'flex'
    },
    passwordInput: {
        height: 40,
        minWidth: 250,
        fontWeight: 400,
        border: '1px solid #ccc',
        padding: '0px 10px',
        outline: 'none',
        fontSize: 14,
        margin: '5px 0px',
        '&:focus': {
            borderColor: '#192354'
        }
    },
    passwordSubmitButtonContainer: {
        marginTop: 30,
        width: '100%',
        textAlign: 'center',
        '& > button': {
            height: 50,
            fontSize: 18,
            fontWeight: 400,
            fontFamily: AppTheme.font.family[0],
            width: '100%',
            background: '#192354',
            color: '#fff',
            outline: 'none',
            cursor: 'pointer'
        }
    },

    input: {
        height: 40,
        minWidth: 250,
        width: '100%',
        boxSizing: 'border-box',
        fontWeight: 400,
        border: '1px solid #ccc',
        padding: '0px 10px',
        outline: 'none',
        fontSize: 14,
        margin: '5px 0px',
        '&:focus': {
            borderColor: '#192354'
        }
    },
    loginError: {
        color: 'red',
        fontSize: 10,
        fontWeight: 'normal',
        marginTop: 10,
        textAlign: 'center'
    },
    registerError: {
        color: AppTheme.color.error2,
        fontSize: 10,
        marginTop: 10,
    },
    successMessage: {
        color: 'green',
        fontSize: 10,
        fontWeight: 'normal',
        marginTop: 10,
        textAlign: 'center'
    },
    small: {
        fontSize: `${AppTheme.font.size.caption}px !important`,
        '& a': {
            fontSize: 'inherit'
        }
    },
    errorField: {
        border: `1px solid ${AppTheme.color.error2}`
    },
    checkError: {
        color: AppTheme.color.error2,
        fontSize: 20,
    },
    check: {
        color: AppTheme.color.success,
        fontSize: 20,
    },
    checkGrey: {
        color: '#bdbdbd',
        fontSize: 20,
    }
})