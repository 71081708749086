import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import ChipToggle from '../../ChipToggle';
import { withRouter, RouteComponentProps } from 'react-router';
import Covered from './CoveredFilter';
import ShowFilteredProducts from './ShowFilteredProducts';
import PartneredFilter from './PartneredFilter';
import PublicPrivateFilter from './PublicPrivateFilter';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../reducer/@types';
import { OutsideClick } from '../../OutsideClick';
import AppTheme from '../../../resources/theme';
import UnderDevelopmentFilter from './UnderDevelopmentFilter';
import CommercialFilter from './CommercialFilter';
import { IProduct } from '../../../models/product';
import Typography from '../../Typography';
import Button from '../../Buttons/Button';


// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES>, RouteComponentProps {
}

const FILTERS = [
    {
        key: 'a', label: 'commercial and PRE-commercial PHASES'
    },
    {
        key: 'b', label: 'PRODUCTS under development'
    },
    {
        key: 'c', label: 'PRODUCTS no longer under development'
    },
    {
        key: 'd', label: 'PARTNERED'
    },
    {
        key: 'e', label: 'NON-PARTNERED'
    },
    {
        key: 'f', label: 'COVERED BY CHARDAN'
    },
    {
        key: 'g', label: 'SHOW ONLY FILTERED PRODUCTS'
    },

]

// Give the products from the filteredProductsIds or return all the products
export const getProductsFromFilteredProducts = (allProducts: IProduct[], originalFilterProducts: { [key: string]: any }) => Object.keys(originalFilterProducts).length > 0 ? allProducts.filter((p => originalFilterProducts[p.airtableId])) : allProducts;



const Filters: React.FC<IProps> = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const { settings } = useSelector<IAppState, Pick<IAppState, 'settings'>>(({ settings }) => ({ settings }));
    const { classes } = props;
    let count = 0;
    Object.keys(settings).forEach((s) => {
        if (settings[s as keyof typeof settings]) count++;
    })
    return (
        <OutsideClick onClickOut={() => { setExpanded(false) }}>
            <div className={classes.root}>
                <p>{`Filters (${count})`}</p>

                <div>
                    <ChipToggle rootStyle={classes.chipToggleButton} onToggle={() => setExpanded(!expanded)} title={(
                        <i className="material-icons">{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
                    )} active={expanded} />
                </div>
                {
                    expanded ?
                        <div className={classes.expandedContainer}>
                            <p>Products</p>
                            <UnderDevelopmentFilter />
                            <PartneredFilter />
                            <Covered />
                            <ShowFilteredProducts />
                            <PublicPrivateFilter />
                            <CommercialFilter />
                            <div className={classes.closeButtonContainer}>
                                <Button fontSize={10} onClick={() => setExpanded(false)}>Close</Button>
                            </div>
                        </div>
                        : null

                }
            </div>
        </OutsideClick>
    )
}


const STYLES = (theme: any) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: 20,
        '& > div': {
            margin: '0px 4px'
        },
        '& > p': {
            fontSize: 13,
            fontWeight: 500,
            color: 'white',
            minWidth: 64
        }
    },
    chipToggleButton: {
        height: 27,
        padding: '3px 6px',
        width: 40,
        position: 'relative',
        top: 2,
        border: `1px solid ${AppTheme.color.lightBlue}`,
        '& .material-icons': {
            position: 'relative',
            top: -2
        }
    },
    expandedContainer: {
        width: 320,
        left: '-32%',
        zIndex: 1,
        background: 'white',
        boxSizing: 'border-box',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `2px 2px 9px 0px rgba(176,176,176, 0.5)`,
        top: 65,
        padding: 20,
        borderRadius: 8,
        '& > div': {
            margin: '4px 0px'
        },
        '&::before': {
            content: "' '",
            width: 27,
            height: 27,

            zIndex: 1,
            position: 'absolute',
            top: -4,
            right: '45%',
            background: 'white',
            transform: 'rotate(45deg)'

        }
    },
    filterItem: {
        display: 'flex',
        alignItems: 'center',
    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        textDecoration: 'underline',
    }
})

export default injectSheet(STYLES)(withRouter(Filters));