import React, { SFC } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import { IField } from '../../../models/therapeuticSector';
import { applyFilter } from '../../../reducer';
import ChipsList from './ChipsList';

// eslint-disable-next-line no-use-before-define
interface CoverageBadgeProps extends WithSheet<typeof STYLES> {
    analysts: IField[]
    customTitle?: string
}

const CoverageBadge: SFC<CoverageBadgeProps> = ({ classes, analysts, customTitle }) => {

    const handleClick = (analyst: IField) => applyFilter('analyst', analyst.airtableId);

    return (
        <div className={classes.root} >
            <p className={classes.title} >{customTitle || 'CHARDAN COVERAGE'}</p>
            <ChipsList items={analysts} onClick={handleClick} />
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        backgroundColor: '#fff4d5',
        borderRadius: 8,
        padding: '9px 12px',
        marginTop: 20
    },
    title: {
        fontSize: 10,
        color: '#c19b6a',
        marginBottom: 10,
        textTransform: 'uppercase'
    }
})

export default injectSheet(STYLES)(CoverageBadge);