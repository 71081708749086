import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';
import AppTheme from '../../../resources/theme';
import { Link } from 'react-router-dom';

interface IProps extends WithSheet<typeof STYLES> { }

const RegistrationSuccess: React.FC<IProps> = (props) => {

    const { classes } = props
    return (
        <div>
            <h4>Account Creation Complete</h4>
            <div className="flex-row align-center">
                <div>
                    <i className={clsx("material-icons", classes.check)} style={{ marginRight: 8, fontSize: 42 }}>check</i>
                </div>
                <div>
                    <div>
                        <p style={{ color: AppTheme.color.brandGold }}><b>Congratulations,</b></p>
                    </div>
                    <p>Your account has been created and you can now log in to the Chardan BioScope.</p>
                </div>
            </div>
            <br />
            <Link to="/" className={classes.loginButton}>GO TO LOG IN PAGE</Link>
        </div>
    )
}

const STYLES = (theme: any) => ({
    check: {
        color: AppTheme.color.success,
        fontSize: 20,
    },
    loginButton: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        fontSize: '18px !important',
        fontWeight: 400,
        fontFamily: AppTheme.font.family[0],
        width: '200px',
        background: '#192354',
        color: '#fff',
        outline: 'none',
        cursor: 'pointer'
    },
})

export default injectSheet(STYLES)(RegistrationSuccess)