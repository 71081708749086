import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';

export interface PolicyTextProps extends WithSheet<typeof STYLES> { }  {

}

const PolicyText: React.FC<PolicyTextProps> = (props) => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <h5>BioScope
            Privacy Policy
            </h5>
            <p >Maintaining
            your trust and confidence is a high priority. Chardan Capital Markets
            (“Chardan”, “we”, “us”, or “our”) recognizes that
            protecting the privacy and security of our customers is an important
            responsibility. That’s why we want you to understand how we collect
            personal information about you through the BioScope platform at
            [bioscope.chardan.com] (the “Platform), including how we use, share
            and disclose such information.
            </p>
            <br />

            <p >Please
            read this Privacy Policy carefully because when you submit
            information to or through the Platform, you are consenting to the
            collection, processing and retention of your information as described
            in this Privacy Policy.
            </p>
            <br />

            <p >If
            you do not want your information collected, used, or disclosed as
            stated in the Privacy Policy, please do not use the Platform.
            </p ><br />
            <br />






            <h5>Information
We Collect.</h5>
            <p ><u>When
You Register With Us</u></p>
            <p >We
            collect personal identifiers from you when you register to use the
            Platform or create an account with us, including name, email address,
            company or employer information, and your Platform username and
            password.
</p>
            <p ><br />
            </p>
            <p ><u>Information
Collected Automatically </u>
            </p>
            <p >Whenever
            you use the Platform, we, and/or our Service Providers, may use a
            variety of technologies that automatically or passively collect
            information about how you access or use the Platform (“Usage
            Information”).  However, if you are not logged into your Account,
            this information does not identify you as an individual user.  We do
            not treat Usage Information as personal information unless we combine
            your Usage Information with other data that, once linked, can be used
            to identify you – for example, if you are logged into your Account.
            Usage Information may include information collected in the following
            ways:
                        </p>
            <br />
            <p >Log
            Information.  When you use the Platform, information may be logged
            about your use of the Platform, including your browser type,
            operating system, and IP address (“Log Information”).  This
            statistical usage data provides us with information about how you use
            the Platform, such as how many people visit a specific page of the
            Platform, how long they stay on that page, and what actions they take
            on the platform.  We may use Log Information to assess the
            performance of the Platform or other online services, distinguish
            your device from others, prevent fraud, provide technical support
            and/or respond to your needs.  We may also use Log Information to
            personalize the Platform and its services.
                            </p>
            <br />
            <p >Device
            Identifiers.  A Device Identifier is a number that is automatically
            assigned to your Device when you access a website or its servers
            (“Device Identifier”).  Our system identifies your Device by its
            Device Identifier and we may automatically collect your IP address or
            other unique identifier for the Device you use to access the
            Platform, including the hardware
            model and mobile network information.
            We may use a Device Identifier to, among other things, administer
            the Platform, help diagnose problems with our servers, analyze
            trends, track Users’ movements on the Platform or help identify and
            gather broad demographic information for aggregate use.  Where we
            associate your Device Identifier with other information, we will
            treat that combined information as personal information under this
            Privacy Policy.
            </p>
            <br />

            <p ><u>As
                Described at the Point of Collection</u>
            </p>
            <p >We
            may collect information from you from time to time as described at
            the point of collection.
            </p>

            <br />
            <br />



            <h5>How
                We Use Information.</h5>
            <p >In
            addition to the uses described elsewhere in this Privacy Policy, we
            may use your information for the
            following purposes, such as to:
                </p>
            <ul>
                <li><p >Respond to your questions and requests or provide customer service;</p></li>
                <li><p >Deliver
                the access or features on the Platform, process transactions or send
                        you related information;</p></li>
                <li><p >Facilitate
                            your payments or work with third parties to process your payments; </p></li>
                <li><p >Verify your identity;</p></li>
                <li><p >Tailor
                content, personalize and improve the Platform by providing you with
                            specific content or recommendations;</p></li>
                <li><p >Communicate with you about the Platform or our other products and services;</p></li>
                <li><p >Resolve disputes, investigate and help curb fraud or other illegal behavior,
                                            comply with the law, or enforce our agreements and policies; and </p></li>
                <li><p >In other ways that we disclose to your at the time of collection.</p></li>
            </ul>

            <br />



            <h5>How
We Disclose Your Information.</h5>

            <p >We
            disclose information regarding you or your account under the
            following circumstances:
                    </p>
            <ul>
                <li><p >In
                order to comply with the law, to protect ourselves, the Platform,
                        our other users or others;</p></li>
                <li><p >To
                            your financial advisor and his or her manager;</p></li>
                <li><p >To establish or maintain an account with an unaffiliated third party,
                such as a clearing broker providing services to you and/or us, or to
                                service your account as authorized;</p></li>
                <li><p >To other financial institutions with whom we have joint marketing
                                    agreements;</p></li>
                <li><p >To government entities or other third parties in response to subpoenas
                                        or other legal process as required by law;</p></li>
                <li><p >To our affiliated companies as permitted by law;</p></li>
                <li><p >In the event of an acquisition, or a merger, sale, or transfer of part
                or all of our business or assets or in connection with any related
                                                due diligence;</p></li>
                <li><p >To
                companies that perform services on our behalf or assist in managing
                the Platform on our behalf, including service providers or companies
                        that provide marketing services; and</p></li>
                <li><p >In other ways that we disclose to you at the time of collection.</p></li>
            </ul>


            <br />



            <h5>Your
            Choices.</h5>
            <p >You
            have certain rights regarding the personal information we maintain
            about you. We also offer you certain choices about what personal
            information we collect from you, how we use that information, and how
            we communicate with you.
                </p>
            <ul>
                <li><p >You
                can choose not to provide personal information to us by refraining
                    from using the Platform.</p></li>
                <li><p >If
                you have an Account, you can update and correct inaccuracies in your
                personal information by signing into your Account and updating your
                        information.</p></li>
                <li><p >You
                can at any time tell us not to send you marketing communications by
                e-mail by clicking on the unsubscribe link within the marketing
                e-mails you receive from us. For additional details, please see
                            below.	</p></li>
            </ul>



            <br />


            <h5>Do Not Track</h5>
            <p >Please
            note that the Platform is not configured to recognize Do Not Track
            headers or
            signals&nbsp;from&nbsp;some&nbsp;or&nbsp;all&nbsp;browsers.&nbsp;
                    </p>
            <br />

            <h5>This
                        Platform is Offered in the United States</h5>
            <p >The
            Platform is intended for use in the United States so if you are
            located outside of the United States, it will be necessary to
            transfer your information internationally. In particular, your
            information will be transferred to and processed in the United States
            where our servers are located. The data protection and other laws of
            some countries, such as the United States, may not be as
            comprehensive as those in your country.
            </p>


            <br />



            <h5>Our
Security Policy</h5>
            <p >We
            use certain reasonable security measures as well as physical and
            electronic procedures to help protect your personal information.
            These safeguards vary based on the sensitivity of the information
            that we collect and store.  However, no data transmission or storage
            of information can be guaranteed to be 100% secure.  Please note that
            we cannot ensure or warrant the security of any information you
            transmit to us. You provide us with your information at your own
            risk.
</p>


            <br />
            <h5>Children</h5>
            <p>You
            must be at least 16 years of age to use the Platform.  The Platform
            is intended for a general audience. Accordingly, we do not knowingly
            collect personal information from anyone under 16 years of age.  If
            we learn that a minor under the age of 16 has provided us with any
            personal information, we will use that information only to respond
            directly to that child (or his or her parent or legal guardian) to
            inform the child that he or she cannot use the Platform.  We will
            then dispose of such personal information in accordance with this
            Privacy Policy.  If you believe we may have any information from or
            about a minor under the age of 16, please contact us at
                    [info@chardan.com].</p>
            <br />


            <h5>Other Sites</h5>
            <p >The
            Platform may contain links to other sites that we do not own or
            operate. This includes links from service providers, advertisers,
            sponsors and partners that may use our logo(s) as part of a
            co-branding agreement. We do not control, recommend or endorse these
            sites and are not responsible for these sites or their content,
            products, services, privacy policies or practices.  These other
            websites may send their own cookies to your Device, they may
            independently collect data or solicit personal information and may or
            may not have their own published privacy policies.
                            </p>



            <br />


            <h5>Closed or Inactive Accounts.</h5>
            <p >If
            you decide to close your account(s) or become an inactive customer,
            we will adhere to the privacy policies and practices as described in
            this notice.
                                </p>


            <br />
            <h5>Changes
            to this Privacy Policy.</h5>
            <p >We
            may update or modify our Privacy Policy from time to time and will
            let you know by updating the “Last Updated” date, listed above
            and as may be required by law.  Any changes we may make to our
            Privacy Policy will be posted on this page. Your continued use of the
            Platform after any modification to this Privacy Policy will
            constitute your acceptance of any such modifications. If you do not
            agree or consent to any updates or modifications, do not continue to
                                    use the Platform.</p>

            <br />
            <p >If
            you have any questions concerning this Privacy Policy, please write
            us at:</p>
            <br />

            <p >Chardan,
            LLC</p>
            <p >17 State Street</p>
            <p >Suite 2100</p>
            <p >New York, NY 10004</p>
            <p >Phone: 646-465-9000</p>
            <p >Facsimile: 646-465-9039</p>
            <p >E-mail: [privacy email]</p>


        </div>
    )
}


const STYLES = (theme: any) => ({
    root: {
        '& p': {
            fontSize: '11px',
            fontWeight: 500,
            marginBottom: 4
        }
    },

})
export default injectSheet(STYLES)(PolicyText);