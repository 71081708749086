import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { STYLES } from './style';

interface IProps extends WithSheet<typeof STYLES> {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
    loading?: boolean
    errorMsg?: string
    successMsg?: string
    userName: string
    password: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleResetPassword?: () => void
}

const LoginForm: React.FC<IProps> = (props) => {
    const { classes, errorMsg = '', successMsg = '', loading = false, userName = '', password = '' } = props

    return (
        <form className={classes.formCard} onSubmit={props.onSubmit}>
            <h4>Log in</h4>
            <input className={classes.passwordInput} type="text" name="userName" value={userName} placeholder="Enter email" onChange={props.onChange} />
            <input className={classes.passwordInput} type="password" name="password" value={password} placeholder="Enter Password" onChange={props.onChange} />
            <div className={classes.passwordSubmitButtonContainer}>
                <button type="submit">{loading ? 'Loading...' : 'LOG IN'}</button>
            </div>
            <p style={{ opacity: (errorMsg ? 1 : 0) }} className={classes.loginError}>{errorMsg}</p>
            <p style={{ opacity: (successMsg ? 1 : 0) }} className={classes.successMessage}>{successMsg}</p>
            <br />
            <p>
                Forgot login? <span><a onClick={props.handleResetPassword} href="#">Reset password</a></span>
            </p>
            <br />
            <hr />
            <h5>Request access</h5>
            <p>
                Don’t have an account?
                <br />
                Please email <a href="mailto:info@chardan.com">info@chardan.com</a> to request additional information or to schedule a demo.
            </p>
        </form>
    )
}



export default injectSheet(STYLES)(LoginForm)