import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import AppTheme from '../../resources/theme';
import { AppContext } from '../../App';
import clsx from 'clsx';
import PolicyText from './PolicyText';

interface IProps extends WithSheet<typeof STYLES> {
    // handleChange: (e: React.ChangeEvent<any>) => void
    // formData: Partial<TContactForm>
    // errors: Partial<TContactForm>
    // handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
    // loading?: boolean
    // reasonOptions: string[]
    closeDialog: () => void
    disableActions?: boolean
    strict?: boolean
}

const ERROR_TEXT = 'Something went wrong'

const Form: React.FC<IProps> = (props) => {

    const { classes, closeDialog, disableActions = false, strict = false } = props;
    const { acceptPolicy } = React.useContext(AppContext)
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleCloseDialog = async () => {
        if (!disableActions)
            await acceptPolicy(false);
        closeDialog();
    }

    const handleAccept = async () => {
        setLoading(true);
        setError('');
        try {
            await acceptPolicy();
            setLoading(false)
            closeDialog();
        } catch (error) {
            setError(ERROR_TEXT);
            setLoading(false)
        }
    }


    return (
        <div >
            <div className={clsx(classes.title)}>
                <p>Privacy Policy</p>
                <button type="button" onClick={handleCloseDialog}>
                    <i className="material-icons">close</i>
                </button>
            </div>
            <div className={classes.container}>
                <PolicyText />
            </div>

            {strict ? null : (
                disableActions ? (
                    <div className={clsx(classes.actionContainer, classes.actionContainerAlt)}>
                        <button onClick={handleCloseDialog}>
                            close
                        </button>
                        <button onClick={handleCloseDialog}>
                            BACK TO MESSAGE
                        </button>
                    </div>
                ) : (
                        <div className={classes.actionContainer}>
                            <button onClick={handleCloseDialog}>
                                Cancel
                            </button>
                            <button onClick={handleAccept}>
                                {loading ? 'SUBMITTING...' : 'Accept'}
                            </button>

                        </div>
                    )
            )}
            {
                error ?
                    <div className={classes.error}>
                        <i className="material-icons">warning</i>
                        <p>{error}</p>
                    </div> : null
            }
        </div>
    )
}


const STYLES = (theme: any) => ({
    container: {
        maxHeight: '40vh',
        overflow: 'scroll',
        margin: '20px 0px',

    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        paddingTop: 16,
        '& > p': {
            fontSize: 18,
            fontFamily: AppTheme.font.family[0]
        },
        '& > button': {
            padding: 2,
            cursor: 'pointer',
            border: 'none',
            position: 'absolute',
            right: -72,
            top: -24,
            lineHeight: 0.9,
            borderRadius: '100%',
            background: AppTheme.color.primaryLight,
            '& > i': {
                color: 'white',
                fontSize: 20
            }
        }
    },
    titleAlt: {
        '& > button': {
            right: -70,
            top: -24,
        }
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > button': {
            cursor: 'pointer',
            // color: DARK_GREY,
            fontWeight: 500,
            height: 40,
            '&:focus': {
                outline: 'none'
            }
        },
        '& > button:last-child': {
            backgroundColor: AppTheme.color.primaryLight,
            color: 'white',
            fontFamily: AppTheme.font.family[0],
            textTransform: 'uppercase',
            padding: '6px 30px'
        },
        '& > button:first-child': {
            border: 'none',
            marginRight: 20,
            textDecoration: 'underline'
        }
    },
    actionContainerAlt: {

        flexDirection: 'row-reverse',
        '& > button:last-child': {
            marginRight: 60,
        }

    },
    inputItem: {
        fontSize: 12,
        color: 'grey',
        margin: '20px 0px',
        display: 'flex',
        flexDirection: 'column',
        '& label': {
            fontSize: 10,
            marginBottom: 4,
            fontWeight: 500,
        },
        '& input, & textarea, & select': {
            // color: DARK_GREY,
            fontWeight: 500,
            padding: 2,

        },
        '& input, & select': {
            height: 30,
        },
        '& select': {
            background: 'white',
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            '-webkit-border-radius': 0,  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
            '-moz-border-radius': 0,  /* Firefox 1-3.6 */
            borderRadius: 0
        }
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        '& i': {
            color: AppTheme.color.error,
            fontSize: 18
        },
        '& p': {
            fontWeight: 500,
            marginLeft: 4,
            color: AppTheme.color.error
        }
    }
})

export default injectSheet(STYLES)(Form)