import React from "react";
import clsx from 'clsx';
import injectSheet, { WithSheet } from "react-jss";
import { IAppTheme } from "../../resources/theme";
import TableCell from "./TableCell";
import { IProduct } from "../../models/product";

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    id: string
    stages: IProduct[][] | undefined
    title: string
    onClick?: () => void
    loading?: boolean
    subcategory?: boolean
}

const TableRow: React.FC<IProps> = (props) => {
    const { classes, stages, title } = props;


    return (
        <div className={classes.tr}>
            <div className={clsx(classes.td, 'lscp-column', 'lscp-row-heading', 'lscp-row-heading-title')}
                onClick={props.onClick}
            >
                <p className="title">{title}</p>
            </div>
            {
                stages ? stages.map((products, i) => (
                    <TableCell products={products} colIndex={i} key={i} />
                )) :
                    <div style={{ flex: 10, paddingTop: 45 }} className={clsx(classes.td, 'lscp-column')} >
                        <p>Loading</p>
                    </div >

            }

        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        flex: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    th: {
        fontWeight: 500,
        display: 'flex',
        flex: 1,
    },
    tr: {
        padding: '8px 0px',
        fontWeight: 500,
        display: 'flex',
        flex: 1,
    },
    td: {
        background: theme.color.background,
        borderBottom: `2px solid rgba(0,0,0,0.05)`,
        margin: '0px 5px',
        textAlign: 'center',
        flex: 1,
        '& .sample-class': {
            display: 'inline-block',
            margin: '4px 10px'
        },
        '& > span > span': {
            margin: 8
        },
        '&.lscp-row-heading': {
            cursor: 'pointer',
            flex: 1
        },

    },
    subcategory: {
        width: 80,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderLeft: '2px solid grey'

    }
})


export default injectSheet(STYLES)(TableRow); 