
import { IAppState, IAction, IUser } from './@types';
// import { Reducer } from 'react';
import {
    SET_THERAPEUTIC_SECTORS, SET_ALL_PRODUCTS,
    SET_LANDSCAPES,
    FILTER_PRODUCTS_BY_LANDSCAPES,
    FILTER_PRODUCTS_BY_THERAPEUTIC_SECTOR,
    SET_STAGES,
    SET_INITIAL_STATE,
    SET_SELECTED_SECTORS,
    SET_LOADING,
    EXPAND_TABLE_ROW,
    APPLY_FILTER,
    RESET_FILTERED_PRODUCTS,
    EXPAND_COLUMN,
    LEGENDS_RECEIVED,
    COMPANIES_RECEIVED,
    SELECT_PRODUCT,
    COMPANY_RECEIVED,
    CLEAR_SELECTED_COMPANY,
    THERAPEUTIC_SECTOR_DETAILS_RECEIVED,
    CLEAR_SELECTED_THERAPEUTIC_SECTOR,
    PARTNERS_RECEIVED,
    SET_LOGIN,
    INVESTORS_RECIEVED,
    SELECT_SECTOR_BAR,
    INDICATION_DETAILS_RECEIVED,
    CLEAR_SELECTED_INDICATION,
    PERSON_DETAILS_RECEIVED,
    CLEAR_SELECTED_PERSON_DETAILS,
    CLEAR_SELECTED_LEGEND_CATEGORY,
    LEGEND_CATEGORY_RECEIVED,
    TOGGLE_DRAWER,
    SET_LOGOUT,
    SHOW_ONLY_FILTERED_PRODUCTS,
    PARTNER_VIEW_UPDATED,
    SETTING_UPDATED,
    RESET_SETTINGS,
    SET_POST_LOGIN_REDIRECT,
    TOGGLE_PRODUCT_BAR_AGGREGATION,
    USER_DETAIL_FETCHED,
    USER_POLICY_ACCEPTED,
    ANALYTICS_TRACKING_ENABLED
} from './actions';
import { Landscape, IField } from '../models/landscape';
import { TherapeuticSector, ITherapeuticSector } from '../models/therapeuticSector';
import { Reducer } from 'redux';
import { Indication } from '../models/indication';
import { Product, TFilterType, ICompany, IProduct } from '../models/product';
import { parseQs, updateUrl } from '../resources/utils';
import { rxStore } from '..';
import { IQueryState } from '../resources/@types';
import { Legend } from '../Components/Sidebar/Legend/@types';
import { applyProductsFilter } from './redux-actions';
import { getAnalyticsMetadata } from '../middlewares/analytics';
import { CONTACT_FORM_CLOSE, CONTACT_FORM_OPEN } from '../screens/Contact/redux-config';


export const initialState: IAppState = {
    policy_accepted: false,
    tracking_enabled: false,
    therapeuticSectors: [],
    contactDialogOpen: false,
    currentHighestRegulatoryTotal: 0,
    aggregationType: 'current',
    highestRegulatoryTotal: 0,
    allProducts: [],
    products: [],
    landscapes: [],
    stages: [],
    appInitialized: false,
    selectedSectors: [],
    loadingProducts: true,
    selectedRow: undefined,
    indications: undefined,
    filteredProducts: {},
    originalFilterProducts: {},
    filter: {},
    expandedColumn: '',
    legends: [],
    companies: [],
    isLoggedIn: false,
    showDrawer: false,
    partnerView: {
        nonPartnered: true,
        partnered: true
    },
    settings: {
        notUnderDevelopment: true,
        underDevelopment: true,
        covered: false,
        nonPartnered: true,
        showOnlyFiltered: false,
        partnered: true,
        public: true,
        private: true,
        showCommercialColumns: true
    }
}



export const applyFilter = (filterType: TFilterType | '', id: string | string[], selectedProduct: string = '') => {
    console.log('Params', filterType, id, selectedProduct);
    const state = rxStore.getState();
    if (!state.products || !state.products.length)
        return;
    const queryState = parseQs();
    if ((!filterType || ((state.filter.id === id) && (filterType === state.filter.type) && (selectedProduct === (state.selectedProduct || {} as IProduct).airtableId)))) {
        let newQueryState: IQueryState = {
            ...queryState, filter: {}, selectedProduct: ''
        }
        // if (selectedProduct) newQueryState['selectedProduct'] = selectedProduct;
        rxStore.dispatch({
            type: RESET_FILTERED_PRODUCTS
        });

        // See comment in after the closing brace of function
        JSON.stringify(queryState) !== JSON.stringify(newQueryState) && updateUrl(newQueryState);
        return;
    }

    const _data = {
        id,
        type: filterType
    }
    rxStore.dispatch({
        type: APPLY_FILTER,
        data: _data,
        ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
            eventName: 'PRODUCTS FILTERED',
            data: _data
        })
    })

    /* rxStore.dispatch({
        type: SETTING_UPDATED,
        data: {
            settings: state.settings,
            products: Product.settingFilterProduct(state.settings, getFilteredProducts())
        }
    }) */



    let newQueryState: IQueryState = {
        ...queryState, filter: {
            id: id,
            type: filterType
        },
        settings: { ...initialState.settings, showOnlyFiltered: state.settings.showOnlyFiltered },
        selectedProduct
    }
    // if (selectedProduct) newQueryState['selectedProduct'] = selectedProduct;
    // See comment in after the closing brace of function
    JSON.stringify(queryState) !== JSON.stringify(newQueryState) && updateUrl(newQueryState);
}
// That comparios is done, so that same url don't get pushed in the history, or else back button will have no effect for that

export const getProductsFromAppliedFilter = (products: IProduct[] | undefined, filteredProducts: Record<string, boolean>) => {
    if (!products || !filteredProducts)
        return [];
    let filteredProductsArray: IProduct[] = [];
    Object.keys(filteredProducts).forEach(airtableId => {
        const product = products.find(item => (item.airtableId === airtableId));
        if (product)
            filteredProductsArray.push(product);
    });
    if (!filteredProductsArray.length)
        return products;
    return filteredProductsArray;
}

export const getFilteredProducts = () => {
    const { products, originalFilterProducts: filteredProducts } = rxStore.getState();
    return getProductsFromAppliedFilter(products, filteredProducts);
}

export const expandColumn = (id: string) => {
    const state = rxStore.getState();
    if (!state.products || !state.products.length)
        return;

    const queryState = parseQs();
    let _id = '';
    if (state.expandedColumn !== id)
        _id = id;
    const newQueryState: IQueryState = {
        ...queryState,
        columnId: _id
    }
    rxStore.dispatch({
        type: EXPAND_COLUMN,
        data: _id,
        ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
            eventName: _id ? `LAYOUT COLUMN EXPANDED - ${_id}` : 'LAYOUT COLUMN RESET',
            stageId: _id ? _id : undefined
        })
    })
    updateUrl(newQueryState);

}


export const reducer: Reducer<IAppState, IAction> = (state = initialState, action) => {
    switch (action.type) {
        case CONTACT_FORM_CLOSE: {
            return { ...state, contactDialogOpen: false }
        }
        case CONTACT_FORM_OPEN: {
            return { ...state, contactDialogOpen: true }
        }
        case ANALYTICS_TRACKING_ENABLED: {
            // return { ...state, tracking_enabled: action.data }
            return { ...state, tracking_enabled: false }
        }
        case USER_POLICY_ACCEPTED: {
            return { ...state, policy_accepted: action.data }
        }
        case USER_DETAIL_FETCHED: {
            const user = action.data as IUser;
            const policy_accepted = true;//user && user.user_metadata && user.user_metadata.policy_accepted
            return { ...state, appUser: action.data, policy_accepted }
        }
        case TOGGLE_PRODUCT_BAR_AGGREGATION: {
            return { ...state, aggregationType: action.data }
        }
        case SET_LOGOUT:
            return { ...state, isLoggedIn: false, policy_accepted: false, token: undefined, userId: undefined, appInitialized: false, selectedSectors: [] };
        case SET_LOGIN:
            return { ...state, isLoggedIn: true, token: action.data.id, userId: action.data.userId };
        case EXPAND_COLUMN: return { ...state, expandedColumn: action.data };

        case APPLY_FILTER: {
            const { id, type } = action.data;
            const filteredProducts = Product.filterProducts(id, type, state.products || []);
            const currentHighestRegulatoryTotal = getHighestTotalFromCurrent(state.therapeuticSectors || [], filteredProducts)
            return { ...state, currentHighestRegulatoryTotal, filteredProducts, originalFilterProducts: filteredProducts, filter: { id, type }, settings: { ...initialState.settings, showOnlyFiltered: state.settings.showOnlyFiltered }, }
        }
        case RESET_FILTERED_PRODUCTS: {
            const currentHighestRegulatoryTotal = getHighestTotalFromCurrent(state.therapeuticSectors || [], {})
            return { ...state, filteredProducts: {}, filter: {}, originalFilterProducts: {}, currentHighestRegulatoryTotal };
        }

        case EXPAND_TABLE_ROW: {
            const sector = state.therapeuticSectors ? state.therapeuticSectors.find(sector => sector._id.airtableId === action.data) : undefined;
            const indications = Indication.setProducts(sector);
            return { ...state, selectedRow: sector, indications }
        }
        case SET_LOADING: return { ...state, loadingProducts: action.data }

        case SET_SELECTED_SECTORS: return {
            ...state,
            selectedSectors: action.data
        }

        case SET_INITIAL_STATE: {
            let { therapeuticSectors = [] }: { therapeuticSectors: ITherapeuticSector[] } = action.data;
            therapeuticSectors = TherapeuticSector.cleaupTherapeuticSectors(therapeuticSectors);

            therapeuticSectors.sort((a, b) => {
                if (a._id.name < b._id.name) return -1;
                else if (a._id.name > b._id.name) return 1;
                return 0
            })

            return {
                ...state,
                therapeuticSectors,
                landscapes: action.data.landscapes,
                stages: action.data.stages,
                appInitialized: true
            }
        }
        case FILTER_PRODUCTS_BY_LANDSCAPES: {
            const products = Landscape.filterProductsById(action.data.ids, action.data.products)
            return { ...state, products };
        }

        case FILTER_PRODUCTS_BY_THERAPEUTIC_SECTOR: {
            // const thSector = (state.therapeuticSectors || []).find(ts => ts._id.airtableId === action.data.id);

            return { ...state };
        }

        case SET_LANDSCAPES: return { ...state, landscapes: action.data };

        case SET_STAGES: return { ...state, stages: action.data };

        case SET_THERAPEUTIC_SECTORS: {
            return { ...state, therapeuticSectors: action.data }
        }
        case SET_ALL_PRODUCTS: {
            const { filteredProducts, allProducts } = action.data
            let therapeuticSectors = TherapeuticSector.setProducts(state.therapeuticSectors, filteredProducts, state.stages);
            const highestTotal = getHighestTotal(therapeuticSectors);
            const currentHighestRegulatoryTotal = getHighestTotalFromCurrent(state.therapeuticSectors || [], filteredProducts);
            // debugger
            // let updatedFilteredProducs = Product.settingFilterProduct(state.settings, filteredProducts);
            return { ...state, currentHighestRegulatoryTotal, products: filteredProducts, allProducts, therapeuticSectors, loadingProducts: false, highestRegulatoryTotal: highestTotal };
        }

        case LEGENDS_RECEIVED: {
            const { data = [] }: { data?: Legend[] } = action;
            data.sort((a, b) => {
                if (a._id.name < b._id.name) return -1;
                else if (a._id.name > b._id.name) return 1;
                return 0
            });
            return { ...state, legends: data };
        }

        case COMPANIES_RECEIVED: return { ...state, companies: (action.data as Array<ICompany> || []).sort((a, b) => a.name.localeCompare(b.name)) }

        case PARTNERS_RECEIVED: return { ...state, partners: (action.data as Array<IField> || []).sort((a, b) => a.name.localeCompare(b.name)) }

        case INVESTORS_RECIEVED: return { ...state, investors: (action.data as Array<IField> || []).sort((a, b) => a.name.localeCompare(b.name)) }

        case SELECT_PRODUCT: return { ...state, selectedProduct: action.data }
        case SELECT_SECTOR_BAR: return { ...state, selectedSectorBar: { ...action.data } }

        case COMPANY_RECEIVED: return { ...state, selectedCompany: action.data }

        case CLEAR_SELECTED_COMPANY: return { ...state, selectedCompany: undefined }

        case CLEAR_SELECTED_THERAPEUTIC_SECTOR: return { ...state, selectedTherapeuticSector: undefined }

        case THERAPEUTIC_SECTOR_DETAILS_RECEIVED: return { ...state, selectedTherapeuticSector: action.data }

        case CLEAR_SELECTED_INDICATION: return { ...state, selectedIndication: undefined }
        case INDICATION_DETAILS_RECEIVED: return { ...state, selectedIndication: action.data }

        case PERSON_DETAILS_RECEIVED: return { ...state, selectedPerson: action.data }
        case CLEAR_SELECTED_PERSON_DETAILS: return { ...state, selectedPerson: undefined }

        case CLEAR_SELECTED_LEGEND_CATEGORY: return { ...state, selectedLegendCategory: undefined }
        case LEGEND_CATEGORY_RECEIVED: return { ...state, selectedLegendCategory: action.data }

        case TOGGLE_DRAWER: return { ...state, showDrawer: action.data === undefined ? !state.showDrawer : action.data }


        // case PARTNER_VIEW_UPDATED: return { ...state, partnerView: action.data }

        case SETTING_UPDATED: {
            const hasFilteredProducts = !!Object.keys(action.data.products || {}).length;
            const hasAppliedFilters = !!Object.keys(state.originalFilterProducts || {}).length;
            const filteredProducts = (hasFilteredProducts) ? action.data.products : ((hasAppliedFilters) ? { empty: true } : {});
            // const currentHighestRegulatoryTotal = getHighestTotalFromCurrent(state.therapeuticSectors || [], filteredProducts)
            // return { ...state, settings: action.data.settings, filteredProducts, currentHighestRegulatoryTotal }
            return { ...state, settings: action.data.settings, filteredProducts };
        }
        case RESET_SETTINGS: {
            return { ...state, settings: { ...initialState.settings } };
        }
        case SET_POST_LOGIN_REDIRECT: return { ...state, redirectPostLogin: action.data.url };

        default: return state;

    }
}


const getHighestTotal = (therapeuticSectors: ITherapeuticSector[]) => {
    let highestTotal = 0;
    therapeuticSectors.forEach(th => {
        th.regulatoryStages.forEach(rs => {
            if (rs.total > highestTotal) {
                highestTotal = rs.total
            };
        })
    })
    return highestTotal
}

const getHighestTotalFromCurrent = (thSectors: ITherapeuticSector[], filteredProducts: Record<string, boolean>) => {
    let maxCount = 0;
    thSectors.forEach(thSector => {
        (thSector.stages || []).forEach(stage => {
            const _products = Object.keys(filteredProducts).length > 0 ? getProductsFromAppliedFilter(stage, filteredProducts) : stage;
            const groupedProductsBySector = Product.groupProductsBySectors(_products);
            const sectors = Object.keys(groupedProductsBySector);
            sectors.forEach(sector => {
                if (groupedProductsBySector[sector].length > maxCount)
                    maxCount = groupedProductsBySector[sector].length
            })

        })
    })
    return maxCount;
}