import React, { FC } from 'react'
import { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';

export interface DialogProps extends WithSheet<typeof STYLES> {
    open: boolean;
    onClose: () => void;
}

const Dialog: FC<DialogProps> = ({ classes, open, onClose, children }) => {

    return (
        <div className={clsx({ [classes.root]: true, [classes.hide]: !open })} onClick={() => onClose()} >
            <div className={classes.content} onClick={e => e.stopPropagation()} >
                {children}
            </div>
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        display: 'block',
        position: 'fixed',
        zIndex: 10,
        top: 0, left: 0,
        right: 0, bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
    rootOverride: {

    },
    content: {
        padding: 20,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff'
    },
    hide: {
        display: 'none'
    }
});

export default injectSheet(STYLES)(Dialog);