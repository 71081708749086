import { Dispatch } from "redux"

export const CONTACT_FORM_OPEN = 'CONTACT_FORM_CLOSE'
export const CONTACT_FORM_CLOSE = 'CONTACT_FORM_OPEN'

export const openContactDialog = () => (dispatch: Dispatch) => {
    dispatch({
        type: CONTACT_FORM_OPEN
    })
}

export const closeContactDialog = () => (dispatch: Dispatch) => {
    dispatch({
        type: CONTACT_FORM_CLOSE
    })
}