import { useState } from 'react';
import Axios from 'axios';
import { validateEmail } from '../../resources/utils';
import { IUser } from '../../reducer/@types';


const REASON_FOR_CONTACTING_OPTIONS = [
    'Speak to investment banking',
    'Speak to research',
    'Inquire about licensing',
    'Inquire about partnership',
    'Suggest an asset addition or change',
    'Upgrade BioScope license',
    'Report an error'
]


const EMAIL_ERROR = 'Please provide a properly formatted email address'
const REASON_ERROR = 'Please select a reason for contacting Chardan'

export const EMAIL_KEY = 'email';
export const REASON_KEY = 'subject';
export const MESSAGE_KEY = 'body'

export type TContactForm = {
    [EMAIL_KEY]: string
    [REASON_KEY]: string
    [MESSAGE_KEY]: string
}

const url = '/EmailContacts/send';


export const useContactService = (appUser?: IUser) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [form, setForm] = useState<Partial<TContactForm>>({ [EMAIL_KEY]: '', [REASON_KEY]: '' });
    const [errors, setErrors] = useState<Partial<TContactForm>>({})
    const isChardanUser = appUser && /.*chardan.com$/ig.test(appUser.email);

    const submit = async () => {
        setSubmitted(false);
        if (!validate()) throw null;
        if (!isChardanUser && !appUser) throw null

        setLoading(true);
        try {
            await Axios.request({
                url,
                method: 'POST',
                params: {
                    emailContact: { ...form, [EMAIL_KEY]: isChardanUser ? form[EMAIL_KEY] : appUser ? appUser.email : '' },
                }
            })
            setSubmitted(true);
            clearErrors();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }


    const validate = () => {
        let isFormValid = true;
        // validate email
        const isEmailValid = isChardanUser ? validateEmail(form[EMAIL_KEY] || '') : true;
        isFormValid = isFormValid && isEmailValid;
        // validate reason
        isFormValid = isFormValid && !!form.subject;

        setErrors({
            ...errors,
            [EMAIL_KEY]: isEmailValid ? '' : EMAIL_ERROR,
            [REASON_KEY]: !!form[REASON_KEY] ? '' : REASON_ERROR
        })
        return isFormValid
    }

    const resetForm = () => {
        setForm({ [EMAIL_KEY]: '', [REASON_KEY]: '' });
        setSubmitted(false);
        setLoading(false)
        clearErrors();
    }

    const clearErrors = () =>
        setErrors({ [EMAIL_KEY]: '', [REASON_KEY]: '' });



    return {
        form,
        loading,
        submit,
        errors,
        submitted,
        resetForm,
        reasonOptions: REASON_FOR_CONTACTING_OPTIONS,
        handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => setForm({ ...form, [e.target.name]: e.target.value })
    }
}