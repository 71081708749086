import React, { FC, useEffect, useContext } from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import ToggleButton from '../../ToggleButton';
import { parseQs, updateUrl } from '../../../resources/utils';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../reducer/@types';
import { SHOW_ONLY_FILTERED_PRODUCTS, SETTING_UPDATED } from '../../../reducer/actions';
import { getFilteredProducts } from '../../../reducer';
import { updateSettings } from "../../../reducer/redux-actions";
import { LayoutContext } from '../../../LayoutContext';


interface ShowFilteredProductsProps extends WithSheet<typeof STYLES> {

}

const ShowFilteredProducts: FC<ShowFilteredProductsProps> = (props) => {
    const { viewType } = useContext(LayoutContext)
    const { allProducts = [] } = useSelector<IAppState, Pick<IAppState, 'allProducts'>>(({ allProducts }) => ({ allProducts }));
    const { settings, filteredProducts } = useSelector<IAppState, Pick<IAppState, 'settings' | 'filteredProducts'>>(({ settings, filteredProducts }) => ({ settings, filteredProducts }));
    // const { showOnlyFilteredProducts } = useSelector<IAppState, Pick<IAppState, 'showOnlyFilteredProducts'>>(({ showOnlyFilteredProducts }) => ({ showOnlyFilteredProducts }));
    const queryState = parseQs();
    const dispatch = useDispatch();

    useEffect(() => {
        if (queryState.settings.showOnlyFiltered !== settings.showOnlyFiltered) {
            dispatch(updateSettings({
                settings: { ...settings, showOnlyFiltered: queryState.settings.showOnlyFiltered },
                products: viewType === 'pill' ? filteredProducts : filteredProducts
            }))
        }
    }, [queryState.showOnlyFiltered, settings.showOnlyFiltered]);

    const handleToggle = () => {
        const _settings = { ...settings, showOnlyFiltered: !queryState.settings.showOnlyFiltered };
        dispatch(updateSettings({
            settings: _settings,
            products: viewType === 'pill' ? filteredProducts : filteredProducts
        }, { ..._settings, eventName: 'TOGGLE ONLY_FILTERED' }))
        updateUrl({ ...queryState, settings: { ...settings, showOnlyFiltered: !settings.showOnlyFiltered } });
    };

    return (
        <ToggleButton active={settings.showOnlyFiltered} onToggle={handleToggle} label={'SHOW ONLY FILTERED PRODUCTS'} />
    )
}

const STYLES = ({

})

export default injectSheet(STYLES)(ShowFilteredProducts);