import { Dispatch } from "redux";
import { getAnalyticsMetadata } from "../middlewares/analytics";

export const BUTTON_EVENT = 'ANALYTICS_BUTTON_EVENT';
export const LOCATION_CHANGED = 'ANALYTICS_LOCATION_CHANGED';


export const buttonEvent = (data?: any) => (dispatch: Dispatch) => {
    dispatch({
        type: '',
        ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', data)
    })
}

export const locationChangeEvent = (data?: any) => (dispatch: Dispatch) =>
    dispatch({
        type: '',
        ...getAnalyticsMetadata('ANALYTICS_LOCATION_CHANGED', data)
    })
