import React, { FC, useEffect, useState } from 'react';
import { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import Modus from '../../resources/images/modus-logo.svg';
import clsx from 'clsx';

// eslint-disable-next-line no-use-before-define
const ModusBrandCard: FC<WithSheet<typeof STYLES>> = ({ classes }) => {

    const [isScrolling, setIsScrolling] = useState(false);

    let timeout: NodeJS.Timeout

    const ScrollListener = (e: Event) => {
        clearTimeout(timeout);
        setIsScrolling(true);
        timeout = setTimeout(() => {
            setIsScrolling(false);
        }, 400)
    }

    useEffect(() => {
        let el = window.document.getElementById('row-container');
        if (el) {
            el.addEventListener('scroll', ScrollListener);
        }
        return (() => {
            el && el.removeEventListener('scroll', ScrollListener);
        });
    })

    return (
        <div className={clsx(classes.root, { [classes.fade]: isScrolling })} >
            <p>Powered By</p>
            <div className={classes.logo} >
                <img src={Modus} alt={'logo'} />
                <p>modus-made.com</p>
            </div>
        </div>
    )
};

const STYLES = (theme: IAppTheme) => ({
    root: {
        padding: 5,
        backgroundColor: 'white',
        borderRadius: 4,
        transition: '300ms',
        opacity: 1,
        border: '1px Solid #C0C0CA',
        '& > p': {
            fontSize: 8,
            color: '#C0C0CA',
            marginBottom: 5
        }
    },
    fade: {
        opacity: 0.2
    },
    logo: {
        display: 'flex',
        '& p': {
            flex: 1,
            fontSize: 10,
            lineHeight: '20px',
            color: '#7A7974'
        },
        '& img': {
            width: 20,
            height: 20,
            marginRight: 6
        }
    }
});

export default injectSheet(STYLES)(ModusBrandCard);