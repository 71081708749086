export interface IAppTheme {
    color: {
        primary: string
        accent: string
        primaryLight: string
        primaryDark: string
        secondary: string
        background: string,
        white: string
        darkGrey: string
        backgroundDark: string
        backgroundMedium: string
        black: string,
        brandGold: string,
        lightBlue: string,
        error: string,
        error2: string,
        success: string
    }
    font: {
        family: string[],
        size: {
            caption: number
            body1: number
            body2: number
            title: number
            subtitle: number
        }
    },
    shadow: string[]
    // font: {
    //     weight: {
    //         bold: number
    //         medium: number
    //         regular: number
    //     }
    //     color: {
    //         primary: string
    //         secondary?: string
    //         light: string
    //     }
    // }
}


export const AppTheme: IAppTheme = {
    color: {
        primary: '#192354',
        primaryDark: '#0c1649',
        primaryLight: '#1e2b6f',
        secondary: '#ffa634',
        accent: '#c19b6a',
        background: '#f5f5f5',
        backgroundDark: '#d4d4d4',
        backgroundMedium: '#f1f1f1',
        darkGrey: '#8d704e',
        white: '#ffffff',
        black: '#000',
        brandGold: '#c19b6a',
        lightBlue: '#5b6391',
        error: '#9a2626',
        error2: '#FF0000',
        success: '#00bf00',
    },
    font: {
        family: [
            'Fjalla One',
            'FjallaOne-Regular'
        ],
        size: {
            caption: 10,
            body1: 16,
            title: 18,
            subtitle: 12,
            body2: 14,
        }
    },
    shadow: [
        '0px 2px 18px rgba(0,0,0,0.2)'
    ]


}


export default AppTheme;