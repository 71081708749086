import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { TContactForm, EMAIL_KEY, REASON_KEY, MESSAGE_KEY } from './hook';
import { DARK_GREY, GREY } from '.';
import AppTheme from '../../resources/theme';
import { useSelector } from 'react-redux';
import { IAppState, IUser } from '../../reducer/@types';
import Policy from '../Policy';
import { AppContext } from '../../App';

interface IProps extends WithSheet<typeof STYLES> {
    handleChange: (e: React.ChangeEvent<any>) => void
    formData: Partial<TContactForm>
    errors: Partial<TContactForm>
    user?: IUser
    isChardanUser?: boolean
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
    loading?: boolean
    reasonOptions: string[]
    closeDialog: () => void
}

const Form: React.FC<IProps> = (props) => {

    const { classes, user, isChardanUser = false, loading = false, handleChange, formData: form, errors, handleSubmit, closeDialog, reasonOptions } = props;
    const { logout } = React.useContext(AppContext);
    const [policyDialogOpen, setPolicyDialogOpen] = React.useState(false);

    const getErrorLabel = (errorText?: string) => {
        return (
            <label className={classes.error}>
                <i className="material-icons">warning</i>
                <p>{errorText}</p>
            </label>
        )
    }


    React.useEffect(() => {
        console.log("MOUNTED")
        setPolicyDialogOpen(false)
    }, [])

    const openPolicy = () => {
        setPolicyDialogOpen(true)
    }



    return (
        <>
            <form onSubmit={handleSubmit} >
                <div className={classes.title}>
                    <p>Contact Chardan</p>
                    <button type="button" onClick={closeDialog}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                {!isChardanUser && user ? (
                    <div className={classes.inputItem}>
                        <label>
                            <span>You are logged in as {user.email}. </span>
                            <button className={classes.linkButton} onClick={logout}>Log out</button> to change.
                        </label>
                    </div>
                ) : null}
                {isChardanUser ? (
                    <div className={classes.inputItem}>
                        {
                            errors[EMAIL_KEY] ? getErrorLabel(errors[EMAIL_KEY]) :
                                <label>Your email address</label>
                        }
                        <input
                            placeholder="Enter your email address"
                            name={EMAIL_KEY}
                            value={form[EMAIL_KEY] || ''}
                            onChange={handleChange}
                        />
                    </div>
                ) : null}

                <div className={classes.inputItem}>
                    {
                        errors[REASON_KEY] ? getErrorLabel(errors[REASON_KEY]) :
                            <label>Reason for contacting</label>
                    }
                    <select name={REASON_KEY} value={form[REASON_KEY] || ''} onChange={handleChange}>
                        <option defaultChecked value={''}>Select a reason</option>
                        {
                            reasonOptions.map((reason, i) => (
                                <option key={i} value={reason}>{reason}</option>
                            ))
                        }
                    </select>
                </div>
                <div className={classes.inputItem}>
                    <label>Your Message (optional)</label>
                    <textarea
                        rows={6}
                        placeholder={MESSAGE_PLACEHOLDER_TEXT}
                        name={MESSAGE_KEY} value={form[MESSAGE_KEY] || ''}
                        onChange={handleChange}
                    />
                </div>

                <div className={classes.actionContainer}>
                    <button type="submit">
                        {loading ? 'SUBMITTING...' : 'Send Message'}
                    </button>
                    <button type="button" onClick={closeDialog}>
                        Cancel
                </button>

                </div>
                <div className={classes.inputItem}>
                    <label>We respect your privacy. By continuing you agree to our <button type="button" className={classes.linkButton} onClick={openPolicy}>Privacy Policy</button></label>
                </div>
            </form>
            <Policy
                disableActions={true}
                open={policyDialogOpen}
                onClose={() => setPolicyDialogOpen(false)}
            />
        </>
    )
}

const MESSAGE_PLACEHOLDER_TEXT = `Please tell us a little about yourself and what you are looking for.

Thanks!`


const STYLES = (theme: any) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        paddingTop: 16,
        '& > p': {
            fontSize: 18,
            fontFamily: AppTheme.font.family[0]
        },
        '& > button': {
            padding: 2,
            cursor: 'pointer',
            border: 'none',
            position: 'absolute',
            right: -42,
            top: -24,
            lineHeight: 0.9,
            borderRadius: '100%',
            background: AppTheme.color.primaryLight,
            '& > i': {
                color: 'white',
                fontSize: 20
            }
        }
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            cursor: 'pointer',
            color: DARK_GREY,
            fontWeight: 500,
            height: 40,
            '&:focus': {
                outline: 'none'
            }
        },
        '& > button:first-child': {
            backgroundColor: AppTheme.color.primaryLight,
            color: 'white',
            fontFamily: AppTheme.font.family[0],
            textTransform: 'uppercase',
            padding: '6px 30px'
        },
        '& > button:last-child': {
            border: 'none',
            marginLeft: 40,
            textDecoration: 'underline'
        }
    },
    linkButton: {
        textDecoration: 'underline',
        border: 'none',
        padding: 0,
        margin: 0,
        cursor: 'pointer',
        color: 'inherit'
    },
    inputItem: {

        fontSize: 12,
        color: 'grey',
        margin: '20px 0px',
        display: 'flex',
        flexDirection: 'column',
        '& > label > span,  & > label > button': {
            fontWeight: 500,
        },
        '& label': {
            fontSize: 10,
            marginBottom: 4,
            fontWeight: 500,
        },
        '& input, & textarea, & select': {
            color: DARK_GREY,
            fontWeight: 500,
            padding: 2,
            border: '1px solid',
            borderColor: '#bcbcbc',

        },
        '& input, & select': {
            height: 30,
        },
        '& select': {
            background: 'white',
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            '-webkit-border-radius': 0,  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
            '-moz-border-radius': 0,  /* Firefox 1-3.6 */
            borderRadius: 0
        }
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        '& i': {
            color: AppTheme.color.error,
            fontSize: 18
        },
        '& p': {
            fontWeight: 500,
            marginLeft: 4,
            color: AppTheme.color.error
        }
    }
})

export default injectSheet(STYLES)(Form)