import { expandColumn } from ".";

export const SET_THERAPEUTIC_SECTORS = 'SET_THERAPEUTIC_SECTORS';
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS';
export const SET_LANDSCAPES = 'SET_LANDSCAPES';
export const SET_STAGES = 'SET_STAGES';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOGOUT = 'SET_LOGOUT'

// FILTER PRODUCTS
export const FILTER_PRODUCTS_BY_LANDSCAPES = 'FILTER_PRODUCTS_BY_LANDSCAPES';
export const FILTER_PRODUCTS_BY_THERAPEUTIC_SECTOR = 'FILTER_PRODUCTS_BY_THERAPEUTIC_SECTOR';

export const APPLY_FILTER = 'APPLY_FILTER'
export const RESET_FILTERED_PRODUCTS = 'RESET_FILTERED_PRODUCTS'

// HANDLE LANDSCAPE SELECTION FROM QUERY
export const SET_SELECTED_SECTORS = 'SET_SELECTED_SECTORS';


// SET INDICATIONS
export const EXPAND_TABLE_ROW = 'EXPAND_TABLE_ROW';
// EXPAND COLUMN
export const EXPAND_COLUMN = 'EXPAND_COLUMN'


// LOADING
export const SET_LOADING = 'SET_LOADING';

export const LEGENDS_RECEIVED = 'LEGENDS_RECEIVED';
export const COMPANIES_RECEIVED = 'COMPANIES_RECEIVED';
export const PARTNERS_RECEIVED = 'PARTNERS_RECEIVED';
export const INVESTORS_RECIEVED = 'INVESTORS_RECIEVED';

export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const SELECT_SECTOR_BAR = 'SELECT_SECTOR_BAR';

export const CLEAR_SELECTED_COMPANY = 'CLEAR_SELECTED_COMPANY';
export const COMPANY_RECEIVED = 'COMPANY_RECEIVED';

export const CLEAR_SELECTED_THERAPEUTIC_SECTOR = 'CLEAR_SELECTED_THERAPEUTIC_SECTOR';
export const THERAPEUTIC_SECTOR_DETAILS_RECEIVED = 'THERAPEUTIC_SECTOR_DETAILS_RECEIVED';

export const CLEAR_SELECTED_INDICATION = 'CLEAR_SELECTED_INDICATION';
export const INDICATION_DETAILS_RECEIVED = 'INDICATION_DETAILS_RECEIVED';

export const CLEAR_SELECTED_PERSON_DETAILS = 'CLEAR_SELECTED_PERSON_DETAILS';
export const PERSON_DETAILS_RECEIVED = 'PERSON_DETAILS_RECEIVED';

export const CLEAR_SELECTED_LEGEND_CATEGORY = 'CLEAR_SELECTED_LEGEND_CATEGORY';
export const LEGEND_CATEGORY_RECEIVED = 'LEGEND_CATEGORY_RECEIVED';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export const SHOW_ONLY_FILTERED_PRODUCTS = 'SHOW_ONLY_FILTERED_PRODUCTS';
export const PARTNER_VIEW_UPDATED = 'PARTNER_VIEW_UPDATED';

export const SETTING_UPDATED = 'SETTING_UPDATED';
export const RESET_SETTINGS = 'RESET_SETTINGS';
export const SET_POST_LOGIN_REDIRECT = 'SET_POST_LOGIN_REDIRECT';


export const TOGGLE_PRODUCT_BAR_AGGREGATION = 'TOGGLE_PRODUCT_BAR_AGGREGATION';

export const USER_DETAIL_FETCHED = 'USER_DETAIL_FETCHED'
export const USER_POLICY_ACCEPTED = 'USER_POLICY_ACCEPTED'
export const ANALYTICS_TRACKING_ENABLED = 'ANALYTICS_TRACKING_ENABLED'