import * as React from 'react';
import { ThemeProvider } from 'react-jss';
import config from './resources/Config';
import THEME from './resources/theme';
import RootNavigation from './RootNavigation';
import { Product } from './models/product';
import { SET_INITIAL_STATE, SET_LOADING, SET_LOGIN, SET_LOGOUT, SET_POST_LOGIN_REDIRECT } from './reducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState, IUser } from './reducer/@types';
import { cookie } from './screens/Landing';
import Axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import LayoutContextProvider from './LayoutContext';
import { setUserDetail, userPolicyAccepted } from './reducer/redux-actions';
import { UserService } from './service/userService';
import { useLocationChangeListener } from './EventHandler/hook';
import { getAnalyticsMetadata } from './middlewares/analytics';
import { HISTORY } from './screens/Home';




//Setting default url and headers
Axios.defaults.baseURL = config.get('API_URL');
Axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Authorization'] = 'fcoiuHZ5qkXDilNx5Wgi8zfnuHXRSfPm7guvqUnVol57CbBQGN20P3yUl4ZUU1fC';

interface IProps extends RouteComponentProps { }

export const AppContext = React.createContext<{
    logout: () => void
    me: () => Promise<void>
    acceptPolicy: (accept?: boolean) => Promise<void>
}>({
    logout: () => { },
    me: async () => { },
    acceptPolicy: async (accept: boolean = true) => { }
});

const App: React.FC<IProps> = (props) => {

    // useLocationChangeListener();
    const dispatch = useDispatch();
    const { token } = useSelector<IAppState, Pick<IAppState, 'isLoggedIn' | 'userId' | 'token'>>(({ isLoggedIn, userId, token }) => ({ isLoggedIn, userId, token }));
    const appUser = useSelector<IAppState, IUser | undefined>(state => state.appUser)
    const [loading, setLoading] = React.useState(true);

    const logout = (shouldReload: boolean = true) => {
        setLoading(true);
        cookie.remove('userId', { path: '/' });
        cookie.remove('id', { path: '/' });
        Axios.defaults.headers.common['Authorization'] = '';
        dispatch({
            type: SET_LOGOUT,
            ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                eventName: 'LOG OUT',
                user: appUser
            })
        });
        dispatch({
            type: '-',
            ...getAnalyticsMetadata('ANALYTICS_RESET_USER')
        })

        setLoading(false);
        if (/\/forgot-password/.test(props.location.pathname))
            return;
        if (shouldReload) {
            props.history.push('/');
        }


    }

    const acceptPolicy = async (accept: boolean = true) => {
        if (!appUser) return;
        try {
            if (accept)
                await UserService.acceptPolicy(appUser)
            dispatch(userPolicyAccepted(accept));
        } catch (error) {
            throw error;
        }
    }

    const AppInit = async () => {
        setLoading(true);
        const userId = cookie.get('userId');
        const token = cookie.get('id');
        if (!token) {
            logout(false);
            // props.history.push('/')
            setLoading(false)
            return;
        }
        if (/^\/view/i.test(window.location.pathname)) {
            dispatch({
                type: SET_POST_LOGIN_REDIRECT,
                data: {
                    url: window.location.href
                }
            })
        }


        Axios.defaults.headers.common['Authorization'] = token;
        await me();
        setLoading(false);
        dispatch({
            type: SET_LOGIN,
            data: { id: token },
            ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                eventName: 'USER LOGIN',
            })
        });
    }

    const me = async () => {
        try {
            const res = await Axios.request({ url: `/Auth0Users/me` })
            dispatch(setUserDetail(res.data))

        } catch (error) {
            logout();
            throw error;
        }
    }

    const loadData = () => {
        if (Boolean(token)) {
            dispatch({
                type: SET_LOADING,
                data: true
            })

            Product.fetchInitialData().then(
                data => {
                    dispatch({
                        type: SET_INITIAL_STATE, data: {
                            therapeuticSectors: data.therapeuticSectors,
                            stages: data.stages,
                            landscapes: data.landscapes
                        }
                    });
                    dispatch({
                        type: SET_LOADING,
                        data: false
                    })
                },
                err => dispatch({
                    type: SET_LOADING,
                    data: false
                })
            )
        }
    }

    React.useEffect(() => {
        loadData();
    }, [dispatch, token]);

    React.useEffect(() => {
        AppInit();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <LayoutContextProvider>
            <AppContext.Provider value={{ logout, me, acceptPolicy }}>
                <ThemeProvider theme={THEME}>
                    {
                        loading
                            ?
                            <p>Loading...</p>
                            :
                            <RootNavigation />
                    }
                </ThemeProvider>
            </AppContext.Provider>
        </LayoutContextProvider>
    );
}

export default withRouter(App);
