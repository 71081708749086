export const LANDSCAPE_TITLE_MAPPING: Record<string, { desktop: string, tablet: string }> = {
    cell_therapy: {
        desktop: 'Cell Therapy',
        tablet: 'CT'
    },
    gene_therapy_gene_editing: {
        desktop: 'Gene Therapy & Gene Editing',
        tablet: 'GT/E'
    },
    rna: {
        desktop: 'RNA',
        tablet: 'RNA'
    },
    microbiome: {
        desktop: 'Microbiome',
        tablet: 'MIC'
    },
    digital_therapeutics: {
        desktop: 'Digital Therapeutics',
        tablet: 'DT'
    }
}