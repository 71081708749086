import React, { useRef, useEffect, FC } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick(ref: any, onClickOut: () => void) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target)) {
            onClickOut();
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

interface IProps {
    children: React.ReactNode
    onClickOut: () => void
}


/**
 * Component that alerts if you click outside of it
 */
export const OutsideClick: FC<IProps> = (props) => {
    const wrapperRef = useRef(null);
    console.log('Wrapper ref', wrapperRef);
    useOutsideClick(wrapperRef, props.onClickOut);

    return <div ref={wrapperRef}>{props.children}</div>;
}
