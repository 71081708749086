import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { IProduct, COLOR_CONFIG } from '../../models/product';
import clsx from 'clsx';
import { findShapeByLegendCategoryId } from '../../service/mappings';
import ProductPopperCard, { ProductPopperCardProps } from '../Cards/ProductPopperCard';
import { useClickEventListener } from '../../EventHandler/hook';
import { Device } from '../../resources/utils';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    product: IProduct
    compact?: boolean
    active?: boolean
    onClick: (product: IProduct) => void
    showDetails?: boolean
    onCloseDetails?: () => void
    ref?: any
}

interface IShapeProps {
    isActive?: boolean
    color?: string
}
export const Square: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div
            style={{ height: 10, width: 10, ...(!isActive ? { border: `3px Solid ${color}`, height: 4, width: 4 } : { backgroundColor: color }) }}
        />
    )
})

export const SquareRotated: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div
            style={{ height: 10, width: 10, transform: 'rotate(45deg)', ...(!isActive ? { border: `3px Solid ${color}`, height: 4, width: 4 } : { backgroundColor: color }) }}
        />
    )
})


export const Circle: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div
            style={{ height: 10, width: 10, borderRadius: 5, ...(!isActive ? { border: `3px Solid ${color}`, height: 4, width: 4 } : { backgroundColor: color }) }}
        />
    )
});

export const DefaultShape: React.FC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div style={{ width: 10 }} >
            <div
                style={{ height: 10, width: 3, ...(isActive ? { backgroundColor: color } : { border: `2px Solid ${color}`, height: 6 }) }}
            />
        </div>
    )
})

export const Plus: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" >
            {
                isActive
                    ?
                    <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" fill={color} />
                    :
                    <path d="M13 2v9h9v2h-9v9h-2v-9h-9v-2h9v-9h2zm2-2h-6v9h-9v6h9v9h6v-9h9v-6h-9v-9z" fill={color} />
            }
        </svg>
        // <div style={{ height: 12, width: 12, display: 'flex', justifyContent: 'space-between', position: 'relative', top: -2, backgroundColor: isActive ? color : 'white' }} >
        //     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
        //         <div style={{ height: 4, width: 4, backgroundColor: 'white', ...(isActive ? {} : { borderRight: `1px Solid ${color}`, borderBottom: `1px Solid ${color}` }) }} />
        //         <div style={{ height: 3, width: 4, backgroundColor: 'transparent', ...(isActive ? {} : { borderLeft: `1px Solid ${color}` }) }} />
        //         <div style={{ height: 4, width: 4, backgroundColor: 'white', ...(isActive ? {} : { borderTop: `1px Solid ${color}`, borderRight: `1px Solid ${color}` }) }} />
        //     </div>
        //     <div style={{ height: 12, width: 4, boxSizing: 'border-box', ...(isActive ? {} : { borderTop: `1px Solid ${color}`, borderBottom: `1px Solid ${color}` }) }} >

        //     </div>
        //     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
        //         <div style={{ height: 4, width: 4, backgroundColor: 'white', ...(isActive ? {} : { borderLeft: `1px Solid ${color}`, borderBottom: `1px Solid ${color}` }) }} />
        //         <div style={{ height: 3, width: 4, backgroundColor: 'transparent', ...(isActive ? {} : { borderRight: `1px Solid ${color}` }) }} />
        //         <div style={{ height: 4, width: 4, backgroundColor: 'white', ...(isActive ? {} : { borderLeft: `1px Solid ${color}`, borderTop: `1px Solid ${color}` }) }} />
        //     </div>
        // </div>
    )
})

export const Triangle: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div style={{
            borderBottom: `12px Solid ${color}`,
            borderLeft: `6px Solid transparent`,
            borderRight: `6px Solid transparent`,
            height: 0, width: 0,
            position: 'relative',
            top: -2
        }} >
            <div style={{
                borderBottom: `4px Solid ${!isActive ? 'white' : color}`,
                borderLeft: `2px Solid transparent`,
                borderRight: `2px Solid transparent`,
                height: 0, width: 0,
                position: 'absolute',
                top: '5px',
                left: '-2px'
            }} />
        </div>
    )
})


const ProductPill: React.FC<IProps> = React.memo((props) => {

    const [position, setPosition] = React.useState<ProductPopperCardProps['variant']>();
    const [positionClass, setPositionClass] = React.useState('');

    const { classes, product, compact = true, showDetails = false, active = false } = props;
    let Comp = React.useMemo(() => {
        return findShapeByLegendCategoryId((Array.isArray(product.legendCategories) && product.legendCategories.length > 0) ? product.legendCategories[0].airtableId : 'default');
    }, [product.legendCategories]);

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClick = () => {
        props.onClick(product);
        if (pill.current) {
            const position: any = pill.current.getBoundingClientRect();
            console.log('Pill', window.innerHeight - position.y);
        }
    };

    const thumbnail = (product.companies || []).length > 0 && (product.companies[0].logo || []).length > 0 && product.companies[0].logo[0].thumbnails;

    const pill = React.useRef<HTMLDivElement>(null);
    useClickEventListener(pill, {
        eventName: `SELECT PRODUCT PILL - ${product.name}`,
        data: {
            name: product.name,
            airtableId: product.airtableId,
        }
    })


    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (props.showDetails && pill.current) {
            let p: ClientRect & { x: number, y: number } = pill.current.getBoundingClientRect() as ClientRect & { x: number, y: number };
            // Position from right
            let el = window.document.getElementById('table-container')
            const r = el ? el.offsetWidth - p.x : 0

            // Position from bottom

            const b = el ? el.offsetHeight - p.y : 0;

            let size = 230

            if (b < size && r < size) {
                setPositionClass('detailPopperTopLeft');
                setPosition('topLeft');
            }
            else if (b < size && r >= size) {
                setPositionClass('detailPopperTopRight');
                setPosition('topRight');
            }
            else if (b >= size && r < size) {
                setPositionClass('detailPopperBottomLeft');
                setPosition('bottomLeft');
            }
            else if (b >= size && r > size) {
                setPositionClass('detailPopperBottomRight');
                setPosition('bottomRight');
            }
            // if(window.innerHeight)
        }
    }, [props.showDetails]);

    const ticker = (product.companies || []).length > 0 && product.companies[0].ticker;

    const isThumbnailLandscape = thumbnail && ((thumbnail.large.width / thumbnail.large.height) > 2);


    return (
        <div id={product.airtableId} className={clsx(compact ? classes.root : classes.rootAlt, active ? 'highlight' : 'unHighlight')} onClick={handleClick}>
            <div ref={pill} className={'product-pill'} >
                {
                    !compact ? (
                        <div>
                            <div className={classes.img} >
                                {thumbnail ? <img src={thumbnail.large.url} height={isThumbnailLandscape ? 'auto' : '98%'} width={isThumbnailLandscape ? '98%' : Device.isTablet ? '80%' : 'auto'} alt={product.name} /> : null}
                            </div>
                            <div className={classes.container}>
                                <div className="info">
                                    <Comp isActive={!product.partnered} color={COLOR_CONFIG[product.sector.airtableId]} />
                                    <div className={'nameContainer'} >
                                        <p className={classes.title}>{product.companies ? (product.companies[0].shortName || product.companies[0].name) : ''}</p>
                                        <p className={classes.subtitle}>{product.name}</p>
                                        {ticker ? <div className={classes.ticker} ><div>{ticker}</div></div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <>
                                <Comp isActive={!product.partnered} color={COLOR_CONFIG[product.sector.airtableId]} />
                                <p className={classes.label}>{product.companies ? (product.companies[0].shortName || product.companies[0].name) : ''}</p>
                                {ticker ? <div className={classes.ticker} ><div>{ticker}</div></div> : null}
                            </>
                        )
                }
            </div>
            {
                // showDetails && active && position
                showDetails && !!position
                    ?
                    <div className={clsx(classes[positionClass as keyof typeof STYLES], classes.detailPopper)} >
                        <ProductPopperCard onClose={props.onCloseDetails} productDetails={product} variant={position} />
                    </div> : null
            }
        </div >
    )
})




const STYLES = (theme: any) => ({
    root: {
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'top',
        transition: '100ms linear',
        margin: Device.isTablet ? 4 : 10,
        padding: Device.isTablet ? '8px 8px 8px 0px' : 8,
        boxSizing: 'border-box',
        // zIndex: 0,
        cursor: 'pointer',
        border: '1px solid transparent',
        '& .product-pill': {
            zIndex: 0,
            // margin: '10px 15px'
        }
    },
    rootAlt: {
        display: 'inline-block',
        verticalAlign: 'top',
        transition: '100ms linear',
        position: 'relative',
        // margin: 4,
        // padding: '10px 15px',
        cursor: 'pointer',
        border: '1px solid transparent',
        '& .pill': {
            // transform: 'scale(0.8)',
        },
        '& .product-pill': {
            margin: Device.isTablet ? '10px' : '10px 15px',
        },
        '& .info': {
            maxWidth: 70,
            display: 'flex',
            alignItems: 'flex-start',
            '& p': {
                lineHeight: '10px',
                marginLeft: 3,
                marginBottom: 3
            },
            '& .nameContainer': {
                flex: 1,
                maxWidth: 60
            }
        }
    },
    container: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    title: {
        fontSize: 8,
    },
    subtitle: {
        fontSize: 8,
        fontWeight: 500
    },
    label: {
        maxWidth: 80,
        fontSize: 10,
        lineHeight: 1,
        fontWeight: 'normal',
        margin: 0,
        marginTop: 4,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Avenir, Montserrat'
    },
    ticker: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: 2,
        '& > div': {
            padding: 3,
            fontSize: 5,
            backgroundColor: 'white',
            borderRadius: 6,
            height: 7,
            boxShadow: '0px 1px 3px rgba(0,0,0,0.16)',
        }
    },
    detailPopper: {
        position: 'absolute',
        zIndex: 2
    },
    detailPopperTopLeft: { bottom: '105%', right: '-10px' },
    detailPopperTopRight: { bottom: '105%', left: '-10px' },
    detailPopperBottomLeft: { top: '105%', right: '-10px' },
    detailPopperBottomRight: { top: '105%', left: '-10px' },
    img: {
        width: '100%',
        maxWidth: '100px',
        height: 50,
        overflow: 'hidden',
        marginBottom: 5,
        display: 'flex',
        alignItems: 'flex-end'
    }
})

export default injectSheet(STYLES)(ProductPill);