import qs from 'qs';
import { IQueryState, ILayoutViewType } from './@types';
import { HISTORY } from '../screens/Home/index';


export const Device = {
    isTablet: window.innerWidth <= 1024
}

export const getQueryString = () => window.location.search;

export const initialState: IQueryState = {
    layoutView: 'pill',
    sectorIds: ["cell_therapy", "gene_therapy_gene_editing", "rna", "microbiome", "digital_therapeutics"],
    sidebar: {
        open: Device.isTablet ? false : true,
    },
    subcategory: {
        id: '',
        open: false
    },
    filter: {
        id: '',
        type: '',
    },
    columnId: '',
    rowId: '',
    selectedProduct: '',
    selectedSectorBar: {
        colId: '',
        rowId: '',
        sectorId: '',
    },
    showReleaseNotes: '0',
    showOnlyFiltered: false,
    partnerView: {
        nonPartnered: true,
        partnered: true
    },
    settings: {
        notUnderDevelopment: true,
        underDevelopment: true,
        nonPartnered: true,
        partnered: true,
        covered: false,
        public: true,
        private: true,
        showOnlyFiltered: false,
        showCommercialColumns: true
    }
}

export const parseQs = (): IQueryState => {
    const queryString = getQueryString();
    if (!queryString) {
        updateUrl(initialState);
        return initialState;
        // throw 'Invalid URL';
    }
    const query: any = qs.parse(queryString, { ignoreQueryPrefix: true });
    const state: IQueryState = {
        layoutView: query.layoutView || 'pill',
        sectorIds: query.sectorIds,
        sidebar: {
            open: query.sidebar && query.sidebar.open === 'true'
        },
        subcategory: {
            id: query.subcategory.id,
            open: query.subcategory && query.subcategory.open === 'true'
        },
        filter: {
            id: (query.filter && query.filter.id) || '',
            type: (query.filter && query.filter.type) || ''
        },
        columnId: query.columnId,
        rowId: query.rowId,
        selectedProduct: query.selectedProduct,
        selectedSectorBar: {
            rowId: query.selectedSectorBar && query.selectedSectorBar.rowId || '',
            colId: query.selectedSectorBar && query.selectedSectorBar.colId || '',
            sectorId: query.selectedSectorBar && query.selectedSectorBar.sectorId || '',
        },
        showReleaseNotes: query.showReleaseNotes,
        showOnlyFiltered: query.showOnlyFiltered === 'true',
        partnerView: {
            nonPartnered: query.partnerView && query.partnerView.nonPartnered === 'true',
            partnered: query.partnerView && query.partnerView.partnered === 'true',
        },
        settings: {
            underDevelopment: query.settings && query.settings.underDevelopment === 'true',
            notUnderDevelopment: query.settings && query.settings.notUnderDevelopment === 'true',
            covered: query.settings && query.settings.covered === 'true',
            nonPartnered: query.settings && query.settings.nonPartnered === 'true',
            partnered: query.settings && query.settings.partnered === 'true',
            public: query.settings && query.settings.public === 'true',
            private: query.settings && query.settings.private === 'true',
            showOnlyFiltered: query.settings && query.settings.showOnlyFiltered === 'true',
            showCommercialColumns: query.settings && query.settings.showCommercialColumns === 'true',
        }
    }
    return state;
}




export const updateUrl = (newQueryState: any) => {
    const newQueryString = 'view?' + qs.stringify(newQueryState);
    if (HISTORY)
        HISTORY.push(newQueryString);
}


export const isSame = (ary1: string[], ary2: string[]): boolean => {
    const union = new Set([...ary1, ...ary2])
    return ary1.length === ary2.length ? union.size === ary1.length : false
}

export const isSameObject = (obj1: { [key: string]: string | number | boolean }, obj2: { [key: string]: string | number | boolean }): boolean => {
    let isEqual = true;
    Object.keys(obj1 || {}).forEach(key => {
        if (obj2[key] !== obj1[key]) {
            isEqual = false;
            return;
        }
    });
    return isEqual;
}

export interface GroupByFilterConfig<T> {
    data: T[]
    keyName?: string
}

type GroupItem<T> = { [x: string]: { group: string, items: T[] } }

export const groupByFirstLetter = <T extends Record<string, any>>({ data, keyName = 'name' }: GroupByFilterConfig<T>): { group: string, items: T[] }[] => {
    let reducedData = data.reduce<GroupItem<T>>((a, c) => {
        let group = /[a-z]/i.test((c[keyName] as string)[0]) ? (c[keyName] as string)[0].toUpperCase() : '#'
        if (a[group]) a[group].items.push(c);
        else a[group] = { group, items: [c] }
        return a
    }, { '': { group: '', items: [] } });
    return Object.values(reducedData);
}


export type AggregatedObject<T> = { _id: T }
export const parseAggregatedArray = <T>(arr: AggregatedObject<T>[]): T[] => (arr || []).map(item => item._id);

export const validateEmail = (email: string) => {
    // eslint-disable-next-line
    let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email)
        return false;

    if (email.length > 254)
        return false;

    var valid = tester.test(email);
    if (!valid)
        return false;

    // Further checking of some things regex can't handle
    var parts = email.split("@");
    if (parts[0].length > 64)
        return false;

    var domainParts = parts[1].split(".");
    if (domainParts.some(function (part) { return part.length > 63; }))
        return false;

    return true
}

