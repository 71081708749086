import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import ToggleButton from '../ToggleButton';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState, IProductBarAggregatingType } from '../../reducer/@types';
import { toggleAggregation } from '../../reducer/redux-actions';
import { LayoutContext } from '../../LayoutContext';

interface IProps extends WithSheet<typeof STYLES> { }

const ToggleAggregation: React.FC<IProps> = (props) => {

    const { classes } = props;
    const aggregationType = useSelector<IAppState, IProductBarAggregatingType>(state => state.aggregationType);
    const { viewType } = React.useContext(LayoutContext);
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(toggleAggregation(aggregationType === 'current' ? 'total' : 'current'));
    }

    // if (viewType === 'pill')
    return <div />


    return (
        <div className={classes.root}>
            <ToggleButton
                active={aggregationType === 'current'}
                label={''}
                onToggle={handleToggle}
            />
        </div>
    )
}

const STYLES = (theme: any) => ({
    root: {
        margin: '0px 8px'
    }
})

export default injectSheet(STYLES)(ToggleAggregation)