import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import Dialog from '../../Components/Modal/Dialog';
import Form from './Form';
import { useSelector } from 'react-redux';
import { IAppState } from '../../reducer/@types';

interface IProps extends WithSheet<typeof STYLES> {
    open: boolean
    onClose: () => void
    disableActions?: boolean
    strict?: boolean


}

const Policy: React.FC<IProps> = (props) => {
    const { classes } = props
    // React.useEffect(() => {
    //     if (policy_accepted) {
    //         setOpen(true);
    //     }
    // }, [policy_accepted])

    // React.useEffect(() => {
    //     if (props.open !== open)
    //         setOpen(props.open || open)
    // }, [props.open])

    const closeDialog = () => {
        if (props.onClose) props.onClose();
        // setOpen(false);
    }

    return (
        <Dialog
            classes={{ content: classes.formContainerAlt }}
            open={props.open || false}
            onClose={closeDialog}
        >
            <Form
                disableActions={props.disableActions}
                closeDialog={closeDialog}
                strict={props.strict}
            />
        </Dialog>
    )
}

const STYLES = (theme: any) => ({
    // formContainer: {
    //     // width: 500,
    //     width: 320,
    //     padding: 30,
    //     paddingTop: 14,
    //     cursor: 'initial !important',
    //     boxSizing: 'borderBox',
    //     borderRadius: 8,
    //     boxShadow: `0px 3px 6px rgba(0,0,0,0.2)`,
    //     position: 'fixed',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     backgroundColor: '#fff'
    // },
    formContainerAlt: {
        width: 300,
        padding: '30px 60px',
        paddingTop: 14,
        cursor: 'initial !important',
        boxSizing: 'borderBox',
        borderRadius: 8,
        boxShadow: `0px 3px 6px rgba(0,0,0,0.2)`,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff'
    },
})

export default injectSheet(STYLES)(Policy)