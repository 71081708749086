import React, { useState } from 'react';
import AppTheme, { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import ChipToggle from '../../ChipToggle';
import Button from '../../Buttons/Button';

// eslint-disable-next-line no-use-before-define
export interface ChipsListProps extends WithSheet<typeof STYLES> {
    arrayKeyForChipLabel?: string
    items: Array<any>
    title?: string
    onClick?: (item: any) => void
    collapsedChipCount?: number
}

function ChipsList(props: ChipsListProps) {

    const [fullChipView, setFullChpView] = useState(false);

    const { title = '', items, arrayKeyForChipLabel = 'name', classes, onClick, collapsedChipCount } = props;

    const handleClick = (item: any) => typeof onClick === 'function' && onClick(item);

    return (
        <div className={classes.root} >
            <p className={classes.title} >{title}</p>
            {
                (collapsedChipCount ? (fullChipView ? items : items.slice(0, collapsedChipCount)) : items).map((item, i) => {
                    return (
                        <div key={i} className={classes.chipContainer} >
                            <ChipToggle
                                title={item[arrayKeyForChipLabel]}
                                id={`${i}`}
                                active={false}
                                inactiveColor={'#ecf1fd'}
                                inactiveTextColor={AppTheme.color.primaryLight}
                                onToggle={() => handleClick(item)}
                            />
                        </div>
                    )
                })
            }
            {
                collapsedChipCount ?
                    (
                        items.length > collapsedChipCount ?
                            <Button onClick={() => { setFullChpView(fullDescriptionView => !fullDescriptionView) }} >{fullChipView ? `- Show Less` : `+ Show More`}</Button>
                            : null
                    ) : null
            }
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        marginTop: 14
    },
    title: {
        fontSize: 10,
        lineHeight: '16px',
        textTransform: 'uppercase',
        marginBottom: 8
    },
    chipContainer: {
        margin: '0px 5px 5px 0px'
    }
})

export default injectSheet(STYLES)(ChipsList);