import React, { FC, useEffect } from "react";
import ToggleButton from "../../ToggleButton";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../../../reducer/@types";
import { parseQs, updateUrl } from "../../../resources/utils";
import { SETTING_UPDATED } from '../../../reducer/actions';
import { Product } from "../../../models/product";
import { updateSettings } from "../../../reducer/redux-actions";
import { getProductsFromFilteredProducts } from '.';

const PartneredFilter: FC = (props) => {
    const { allProducts = [] } = useSelector<IAppState, Pick<IAppState, 'allProducts'>>(({ allProducts }) => ({ allProducts }));
    const { settings, products = [], originalFilterProducts } = useSelector<IAppState, Pick<IAppState, 'settings' | 'products' | 'originalFilterProducts'>>(({ settings, products, originalFilterProducts }) => ({ settings, products, originalFilterProducts }));
    const queryState = parseQs();
    const dispatch = useDispatch();
    const { nonPartnered, partnered } = settings;

    useEffect(() => {
        const { nonPartnered: QueryNonPartnered, partnered: QueryPartnered } = queryState.settings;
        if (QueryNonPartnered !== nonPartnered || QueryPartnered !== partnered) {
            dispatch(updateSettings({
                settings: queryState.settings,
                products: Product.settingFilterProduct(queryState.settings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
            }))
        }
    }, [queryState.settings, settings]);

    const handleTogglePartnered = () => {
        let nonPartneredFlag = partnered ? true : nonPartnered;
        const updatedSettings = {
            ...settings,
            nonPartnered: nonPartneredFlag,
            partnered: !partnered
        }

        dispatch(updateSettings({
            settings: updatedSettings,
            products: Product.settingFilterProduct(updatedSettings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
        }, { ...updatedSettings, eventName: 'TOGGLE PARTNERED' }))
        updateUrl({ ...queryState, settings: updatedSettings });
    }

    const handleToggleNonPartnered = () => {
        let partneredFlag = nonPartnered ? true : partnered;
        const updatedSettings = {
            ...settings,
            nonPartnered: !nonPartnered,
            partnered: partneredFlag
        }
        dispatch(updateSettings({
            settings: updatedSettings,
            products: Product.settingFilterProduct(updatedSettings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
        }, { ...updatedSettings, eventName: 'TOGGLE NON_PARTNERED' }))
        updateUrl({ ...queryState, settings: updatedSettings });
    }

    return (
        <>
            <ToggleButton active={partnered} onToggle={handleTogglePartnered} label={'PARTNERED'} />
            <ToggleButton active={nonPartnered} onToggle={handleToggleNonPartnered} label={'NON-PARTNERED'} />
        </>
    )
}

export default PartneredFilter;