import * as React from 'react'
import injectSheet, { WithSheet, WithTheme, } from 'react-jss';
import AppTheme from '../../resources/theme';
import App from '../../App';
import Contact from '../../screens/Contact';
import NO_PRODUCTS_IMAGE from '../../resources/images/no_products_icon.svg';

export interface ProductsNotFoundProps extends WithSheet<typeof STYLES> { }  {

}

const ProductsNotFound: React.FC<ProductsNotFoundProps> = (props) => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.row}>
                    <div style={{ width: 64 }}>
                        <img src={NO_PRODUCTS_IMAGE} width="100%" style={{ float: 'right' }} alt="no products found" />
                    </div>
                    <p className={classes.title}>No Products Found</p>
                </div>
                <hr className={classes.hr} />
                <div>
                    <p className={classes.subtitle}>There are no products that match your selected filters</p>
                    <p className={classes.description}>
                        Please update your filter selections to include more products in your results.
                        If you have any questions please don't hesitate to <Contact className={classes.contactButton}>contact us</Contact>.
                    </p>
                </div>
            </div>
        </div>
    )
}



const STYLES = (theme: any) => ({
    root: {
        position: 'fixed',
        zIndex: 1,
        top: '50%',
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        transform: 'translate(-60%, -50%)',
        padding: 40,
        display: 'flex',
        width: 340,
        flexDirection: 'column',
        borderRadius: 8,
        backgroundColor: 'white',
        boxShadow: `0px 3px 6px rgba(0,0,0,0.2)`,
    },
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    hr: {
        width: '100%',
        margin: '16px 0px',
        border: `0.5px solid rgba(0,0,0,0.04)`
    },
    title: {
        fontFamily: AppTheme.font.family[0],
        fontSize: AppTheme.font.size.title,
        marginLeft: 10
    },
    subtitle: {
        fontSize: AppTheme.font.size.subtitle,
        marginBottom: 10
    },
    description: {
        fontWeight: 400,
        lineHeight: 1.5
    },
    contactButton: {
        border: 'none',
        textDecoration: 'underline',
        background: 'none',
        fontSize: 'inherit',
        padding: 0,
        cursor: 'pointer'

    }
})
export default injectSheet(STYLES)(ProductsNotFound);