import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import AppTheme, { IAppTheme } from '../../resources/theme';
import { parseQs, updateUrl } from '../../resources/utils';
import { IQueryState } from '../../resources/@types';
import TabButton from '../Buttons/TabButton';
import Legend from './Legend';
import LegendDetails from './Legend/details';
import Companies from './Companies';
import CompanyDetails from './Companies/details';
import { createSelector } from 'reselect';
import { IAppState } from '../../reducer/@types';
import { useSelector, useDispatch } from 'react-redux';
import { applyFilter } from '../../reducer';
import TherapeuticSectorsDetails from './TherapeuticSector/details';
import Partners from './Partners';
import Investors from './Investors';
import Person from './Person/details';
import { TFilterType } from '../../models/product';
import IndicationDetails from './Indication/details';
import { LegendChipType } from '../SidebarChipItem';
import { TOGGLE_DRAWER } from '../../reducer/actions';


// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES>, RouteComponentProps {
    isOpen: boolean
}

const selectFilter = createSelector<IAppState, IAppState, Pick<IAppState, 'filter'>>(
    state => state,
    ({ filter }) => ({ filter })
)

const Sidebar: React.FC<IProps> = (props) => {

    const { classes, isOpen } = props;

    const [currentTab, setCurrentTab] = React.useState(1);

    const { filter } = useSelector(selectFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const state = React.useMemo(() => parseQs(), [window.location.search]);
    const sidebarOpen = isOpen;

    // Check if the tab will show or the details bar will show
    let detailBarStatus = (['company', 'therapeuticSector', 'indication', 'managementKeyPeople', 'analyst', 'legendCategory'] as Array<TFilterType>).findIndex(item => item === filter.type) !== -1;

    const newRoute = React.useMemo(() => {
        return (
            () => {
                const newState: IQueryState = {
                    ...state,
                    sidebar: { open: !sidebarOpen }
                }
                const route = '/view?' + qs.stringify(newState);
                return route;
            }
        )
    }, [state, sidebarOpen]);

    React.useEffect(() => {
        switch (filter.type) {
            case 'partner': {
                setCurrentTab(3);
                return;
            }
            case 'investor': {
                setCurrentTab(4);
                return;
            }
        }
        detailBarStatus ? setCurrentTab(0) : (currentTab === 0 && setCurrentTab(1));
        // setCurrentTab(detailBarStatus ? 0 : 1);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, state, detailBarStatus]);

    const handleTabClick = (i: number) => {
        setCurrentTab(i + 1);
        // detailBarStatus && applyFilter(filter.type || '', filter.id || '', '');
        detailBarStatus && applyFilter('', '', '');
        // const queryState = parseQs();
        // const newQueryState: IQueryState = { ...queryState, rowId: '' };
        // const newQueryString = 'view?' + qs.stringify(newQueryState);
        // state.rowId && props.history.push(newQueryString)
        // dispatch({type: ROW})
    }

    return (
        <div className={classes.root}>
            {
                sidebarOpen
                    ?
                    <div>
                        <div className={classes.header} >
                            {
                                ['Legend', 'Companies', 'Partners', 'Investors'].map((item, i) => (
                                    <TabButton onClick={() => handleTabClick(i)} key={item} isSelected={i + 1 === currentTab} >{item}</TabButton>
                                ))
                            }
                        </div>
                        <div className={classes.contentContainer} >
                            {
                                detailBarStatus
                                    ?
                                    <>
                                        {filter.type === 'company' && filter.id ? <CompanyDetails id={filter.id as string} /> : null}
                                        {(filter.type === 'therapeuticSector') && filter.id ? <TherapeuticSectorsDetails id={filter.id as string} /> : null}
                                        {(filter.type === 'analyst' || filter.type === 'managementKeyPeople') && filter.id ? <Person id={filter.id as string} type={filter.type} /> : null}
                                        {filter.type === 'indication' && filter.id ? <IndicationDetails id={filter.id as string} /> : null}
                                        {(filter.type === 'legendCategory' && filter.id) ? <LegendDetails id={filter.id as string} /> : null}
                                    </>
                                    :
                                    <>
                                        {currentTab === 1 ? <Legend selectedLegendCategory={state.filter.id as string} /> : null}
                                        {currentTab === 2 ? <Companies selectedCompany={state.filter.id as string} /> : null}
                                        {currentTab === 3 ? <Partners /> : null}
                                        {currentTab === 4 ? <Investors /> : null}
                                    </>
                            }
                        </div>
                        <div className={classes.sidebarFooter}>
                            <div>
                                <LegendChipType type="P" />
                                <LegendChipType type="C" />
                            </div>
                            <ReleaseInformation {...props} />
                        </div>
                    </div>
                    : null
            }
            <Link className={classes.button} to={newRoute()}>
                <i className="material-icons" style={{
                    transform: sidebarOpen ? `rotateZ(180deg)` : 'none'
                }}>chevron_left</i>
            </Link>
        </div>
    )
}


function ReleaseInformation(props: IProps) {
    const dispatch = useDispatch();
    const { classes } = props;

    const handleRleaseClick = () => {
        dispatch({ type: TOGGLE_DRAWER, data: true });
        let queryState = parseQs();
        updateUrl({ ...queryState, showReleaseNotes: '1' });
    }
    return (
        <div>
            <button onClick={handleRleaseClick} className={classes.releaseButton}>
                <span>proprietary and confidential</span>
                <span>Release Information</span>
            </button>
        </div>
    )
}



const HEADER_HEIGHT = 60;
const FOOTER_HEIGHT = 140;
const STYLES = (theme: IAppTheme) => ({
    root: {
        height: '100%',
        // background: 'grey',
        boxShadow: '0px 1px 3px rgba(0,0,0,0.16)',
        position: 'relative',
        marginLeft: 17
    },
    button: {
        width: 34,
        height: 34,
        borderRadius: 17,
        background: 'white',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: -17,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '1px solid rgba(0,0,0,0.16)',
        textDecoration: 'none',
        '& .material-icons': {
            fontSize: 28,
            transition: '200ms ease-in 100ms',
            color: theme.color.primaryLight
        }
    },
    header: {
        padding: '0px 10px',
        height: 27,
        borderBottom: '1px Solid #ebebeb',
        display: 'flex',
        justifyContent: 'space-around'
    },
    contentContainer: {
        padding: 20,
        flex: 1,
        paddingBottom: 0,
        height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
        overflow: 'scroll',
        'scrollbar-width': 'none', /* Firefox */
        '-ms-overflow-style': 'none',  /* IE 10+ */
        '&::-webkit-scrollbar': { /* WebKit */
            width: 0,
            height: 0
        }
    },
    sidebarFooter: {
        height: FOOTER_HEIGHT,
        background: AppTheme.color.backgroundMedium,
        '& > div:first-child': {
            background: AppTheme.color.backgroundDark,
            display: 'flex',
            alignItems: 'center',
            padding: 8,
            '& > div': {
                margin: '0px 4px'
            }
        },
        '& > div:nth-child(2)': {
            padding: 16
        }
    },
    releaseButton: {
        display: 'flex',
        flexDirection: 'column',
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        '&:focus': {
            outline: 'none'
        },
        '& > span:first-child': {
            fontSize: 8,
            fontWeight: 500
        },
        '& > span:nth-child(2)': {
            fontSize: 11,
            textTransform: 'uppercase'
        },
    }
})

export default injectSheet(STYLES)(withRouter(Sidebar))