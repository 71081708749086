import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { IProduct } from '../../models/product';
import { useSpring, animated } from 'react-spring'
import ProductsBarPopperCard, { ProductsBarPopperCardProps } from '../Cards/ProductsBarPopperCard';
import clsx from 'clsx';
import { ILandscape } from '../../models/landscape';
import { useClickEventListener } from '../../EventHandler/hook';
import { parseQs, updateUrl } from '../../resources/utils';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../reducer/@types';
import { selectSectors } from '../../reducer/redux-actions';
import { HISTORY } from '../../screens/Home';


interface IProps extends WithSheet<typeof STYLES> {
    onClick?: () => void;
    showDetail?: boolean
    percentage: number
    color: string
    products: IProduct[]
    sector?: ILandscape
}

const ProductBar: React.FC<IProps> = (props) => {

    const { classes, percentage = 0, color, showDetail = false, products, sector } = props;
    const [positionClass, setPositionClass] = React.useState('');
    const [position, setPosition] = React.useState<ProductsBarPopperCardProps['variant']>();
    const queryState = parseQs();
    const barRef = React.useRef<HTMLDivElement>(null);
    const { landscapes } = useSelector<IAppState, Pick<IAppState, 'landscapes'>>(({ landscapes }) => ({ landscapes }));
    const dispatch = useDispatch();

    // if (sector && sector._id.airtableId === 'cell_therapy')
    //     console.log("he->", products, percentage);


    const buttonRef = React.useRef<HTMLButtonElement>(null);
    useClickEventListener(buttonRef, {
        eventName: `SELECT SECTOR BAR - ${sector ? sector._id.name : ''}`,
        data: sector ? sector._id : {}
    })

    React.useEffect(() => {
        if ((queryState.sectorIds || []).length === 0) {
            queryState.sectorIds = (landscapes || []).map(sector => sector._id.airtableId);
            updateUrl(queryState);
        }
    }, [queryState])

    React.useEffect(() => {
        if (showDetail && buttonRef.current && barRef.current) {
            let p: ClientRect & { x: number, y: number } = buttonRef.current.getBoundingClientRect() as ClientRect & { x: number, y: number };
            // Position from right
            let el = window.document.getElementById('table-container')
            const r = el ? el.offsetWidth - p.x : 0
            console.log("bar ref", barRef.current.clientWidth)
            // Position from bottom
            const b = el ? el.offsetHeight - p.y : 0;

            let size = 150

            if (b < size && r < size) {
                setPositionClass('detailPopperTopLeft');
                setPosition('topLeft');
            }
            else if (b < size && r >= size) {
                setPositionClass('detailPopperTopRight');
                setPosition('topRight');
            }
            else if (b >= size && r < size) {
                setPositionClass('detailPopperBottomLeft');
                setPosition('bottomLeft');
            }
            else if (b >= size && r > size) {
                setPositionClass('detailPopperBottomRight');
                setPosition('bottomRight');
            }
            // if(window.innerHeight)
        }
    }, [showDetail]);



    const animationProps = useSpring({
        width: `${percentage}%`,
        backgroundColor: color,
        boxShadow: showDetail ? `0px 3px 6px rgba(0,0,0,0.2)` : `0px 0px 0px rgba(0,0,0,0.2)`
    })

    const popperProps = useSpring({
        config: {

        },
        display: showDetail && position ? 'block' : 'none',
        transform: showDetail && position ? 'translateY(0px)' : 'translateY(20px)',
        opacity: showDetail && position ? 1 : 0,
    })


    const handlePopperAction = (sectorId: string, rowId: string) => {
        queryState.sectorIds = [sectorId];
        const colId = products[0] && products[0].regulatoryStages[0] && products[0].regulatoryStages[0].airtableId
        if (rowId)
            queryState.rowId = rowId;
        if (colId)
            queryState.columnId = colId;

        const route = '/view?' + qs.stringify(queryState);
        if (HISTORY) HISTORY.push(route);

    }

    if (!percentage) return <div />

    const barDivStyle: React.CSSProperties = {
        left: barRef.current ? (position === 'topLeft' || position === 'bottomLeft' ? barRef.current.clientWidth / 2 - 136 : barRef.current.clientWidth / 2.25 - 42) : -42
    }

    return (
        <>
            <button ref={buttonRef} className={classes.root} onClick={props.onClick}>
                <animated.div ref={barRef} style={{ ...animationProps }} />
            </button>
            <animated.div style={{ ...popperProps, ...barDivStyle }} className={clsx(classes[positionClass as keyof typeof STYLES], classes.detailPopper)} >
                <ProductsBarPopperCard onClick={handlePopperAction} products={products} sector={sector} onClose={props.onClick} variant={position} />
            </animated.div>
        </>
    )
}

const STYLES = (theme: any) => ({
    root: {
        background: 'transparent',
        border: 'none',
        '&:focus': {
            outline: 'none'
        },
        width: '100%',
        flex: 1,
        marginBottom: 8,
        '& > div': {
            borderRadius: 4,
            height: 14,
        }
    },
    active: {
        boxShadow: `0px 2px 4px rgba(0,0,0,0.2)`
    },
    detailPopper: {
        position: 'absolute',
        zIndex: 2
    },
    detailPopperTopLeft: { bottom: '120%', right: '38px' },
    // detailPopperTopLeft: { bottom: '105%', right: '-10px' },
    detailPopperTopRight: { bottom: '105%', left: '-40px' },
    detailPopperBottomLeft: { top: '105%', right: '38px' },
    detailPopperBottomRight: { top: '105%', left: '-40px' },
    // detailPopperBottomRight: { top: '105%', left: '-10px' },
})

export default injectSheet(STYLES)(ProductBar)