import * as React from 'react';
import { parseQs, updateUrl } from '../resources/utils';
import { ILayoutViewType, IQueryState } from '../resources/@types';
import { useDispatch } from 'react-redux';
import { getAnalyticsMetadata } from '../middlewares/analytics';
import { initialState } from '../reducer';


export const LayoutContext = React.createContext<ProviderValue>({
    viewType: 'pill',
    changeLayoutView: (type: ILayoutViewType) => { }
})


const LayoutContextProvider: React.FC = (props) => {
    const dispatch = useDispatch();
    const [viewType, setViewType] = React.useState<ILayoutViewType>('pill')
    const queryState = window.location.pathname === '/view' ? parseQs() : undefined;


    const layoutView: ILayoutViewType = queryState ? queryState.layoutView : 'pill';

    React.useEffect(() => {
        if (window.location.pathname === '/') {
            setViewType('pill')
        }
    }, [window.location.pathname])

    React.useEffect(() => {
        if (!queryState) return;

        if (viewType !== layoutView) {
            setViewType(layoutView)
            console.log(`changing layout from ${viewType} to ${layoutView}`)
        }
    }, [layoutView]);


    const changeLayoutView = (type: ILayoutViewType) => {
        if (!queryState) return;
        queryState.layoutView = type;
        dispatch({
            type: '',
            ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                eventName: `CHANGE LAYOUT VIEW - ${type}`,
                layout: type
            })
        })
        updateUrl(queryState);
    }

    const providerValue: ProviderValue = {
        viewType,
        changeLayoutView
    }

    return (
        <LayoutContext.Provider value={providerValue}>
            {props.children}
        </LayoutContext.Provider>
    )


}

interface ProviderValue {
    viewType: ILayoutViewType
    changeLayoutView: (type: ILayoutViewType) => void
}

export default LayoutContextProvider