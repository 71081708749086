import { Dispatch } from "redux";
import { SELECT_SECTOR_BAR, TOGGLE_PRODUCT_BAR_AGGREGATION, ANALYTICS_TRACKING_ENABLED, USER_DETAIL_FETCHED, USER_POLICY_ACCEPTED, SETTING_UPDATED, SET_SELECTED_SECTORS, APPLY_FILTER } from "../actions";
import { IProductBarAggregatingType, IUser } from "../@types";
import Axios from "axios";
import { ANALYTICS_USER_EVENT } from "../../Analytics/analyticActions";
import { getAnalyticsMetadata, AnalyticPayload } from "../../middlewares/analytics";

export const selectSectorBar = (sectorId: string, colId: string, rowId: string) => (dispatch: Dispatch) => {
    dispatch({
        type: SELECT_SECTOR_BAR,
        data: {
            sectorId, colId, rowId
        }
    })
}




export const toggleAggregation = (type: IProductBarAggregatingType) => (dispatch: Dispatch) =>
    dispatch({
        type: TOGGLE_PRODUCT_BAR_AGGREGATION,
        data: type
    })



export const setUserDetail = (user: IUser) => async (dispatch: Dispatch) => {
    dispatch({
        type: USER_DETAIL_FETCHED,
        data: user,
        ...getAnalyticsMetadata('ANALYTICS_IDENTIFY_USER', {
            eventName: 'USER DETAIL FETCHED',
            userId: user.user_id || '',
            traits: { email: user.email, name: user.name }
        })
    })

}

export const userPolicyAccepted = (accept: boolean = true) => (dispatch: Dispatch) =>
    dispatch({
        type: USER_POLICY_ACCEPTED,
        data: accept,
        ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
            eventName: `POLICY ${accept ? 'ACCEPTED' : 'DECLINED'}`
        })
    })


export const enableAnalyticsTracking = (enable: boolean = true) => (dispatch: Dispatch) =>
    dispatch({
        type: ANALYTICS_TRACKING_ENABLED,
        data: enable
    })



export const updateSettings = (data: Record<string, any>, analyticsData?: AnalyticPayload) => (dispatch: Dispatch) =>
    dispatch({
        type: SETTING_UPDATED,
        data,
        analytics: analyticsData ? getAnalyticsMetadata('ANALYTICS_USER_EVENT', analyticsData).analytics : undefined

    })


export const applyProductsFilter = (data: Record<string, any>, analyticsData?: AnalyticPayload) => (dispatch: Dispatch) =>
    dispatch({
        type: APPLY_FILTER,
        data,
        analytics: analyticsData ? getAnalyticsMetadata('ANALYTICS_USER_EVENT', analyticsData).analytics : undefined
    })



export const selectSectors = (data: Record<string, any>, analyticsData?: AnalyticPayload) => (dispatch: Dispatch) =>
    dispatch({
        type: SET_SELECTED_SECTORS,
        data,
        analytics: analyticsData ? getAnalyticsMetadata('ANALYTICS_USER_EVENT', analyticsData).analytics : undefined
    })