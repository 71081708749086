import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import Contact from '../../screens/Contact';

interface IProps extends WithSheet<typeof STYLES> {
    variant?: 'full' | 'icon'
}

const ContactButton: React.FC<IProps> = (props) => {
    const { variant = 'full' } = props;

    const { classes } = props
    return variant === 'full' ? (
        <Contact>
            <div className={classes.contactSection}>
                <i className="material-icons">mail_outline</i>
                <p>Contact Chardan</p>
            </div>
        </Contact>
    ) : (
            <Contact>
                <i className="material-icons">mail_outline</i>
            </Contact>
        )

}

const STYLES = (theme: any) => ({
    contactSection: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
        color: theme.color.primary,
        '& > p': {
            textDecoration: 'underline',
            marginLeft: 8,
        }
    },
})

export default injectSheet(STYLES)(ContactButton)