import React, { FC, useRef } from 'react';
import { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';
// eslint-disable-next-line no-use-before-define
export interface IconButtonProps extends WithSheet<typeof STYLES> {
    size?: 'small' | 'medium' | 'large'
    onClick?: () => void
}



const IconButton: FC<IconButtonProps> = ({ size = 'medium', classes, children, onClick }) => {
    const buttonSizeKey = `size${size.toUpperCase()}`;
    const ref = useRef<HTMLButtonElement>(null);

    return (
        <button ref={ref} onClick={onClick} className={clsx(classes.root, classes.ripple, classes[buttonSizeKey as keyof typeof STYLES])} >
            {children}
        </button>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderRadius: '100%',
        border: '0px',
        transition: '100ms',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }
    },
    ripple: {
        backgroundPosition: 'center',
        transition: 'background 0.8s',
        '&:active': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            backgroundSize: '100%',
            transition: 'background 0s',
        },
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.2) radial-gradient(circle, transparent 1 %, rgba(0, 0, 0, 0.2) 1 %) center/15000%'
        }
    },
    sizeSMALL: {
        minHeight: 32,
        minWidth: 32
    },
    sizeMEDIUM: {
        minHeight: 45,
        minWidth: 45,
    },
    sizeLARGE: {
        minHeight: 56,
        minWidth: 56,
    }
});

export default injectSheet(STYLES)(IconButton);