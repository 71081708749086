import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { STYLES } from './style';
import clsx from 'clsx';
import AppTheme from '../../resources/theme';
import { validator } from './utils';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import RegistrationSuccess from './Components/RegistrationSuccess';
import qs from 'qs';
import { Auth, TUser } from '../../models/auth';
import Policy from '../../screens/Policy';

interface IProps extends WithSheet<typeof STYLES> {
    user?: TUser
    completed?: boolean
    loading?: boolean
    onSubmit: (password: string) => void
    onPolicyClick: () => void
}

export type TPasswordCheck = 'minLength' | 'number' | 'caps' | 'specialChar' | 'match'
const CHECKS: { key: TPasswordCheck, label: string }[] = [
    { key: 'minLength', label: 'Must be at least 10 characters' },
    { key: 'number', label: 'Must contain at least 1 number (0-9)' },
    { key: 'caps', label: 'Must contain at least 1 capital letter (A-Z)' },
    { key: 'specialChar', label: 'Must contain at least 1 special character (!@#$%&*)' },
    { key: 'match', label: 'Passwords must match' },
]

const PASSWORD_KEY = 'password'
const REPEAT_PASSWORD_KEY = 'repeatPassword'

const RegisterForm: React.FC<IProps> = (props) => {
    const { classes, user, completed = false, loading = false, onPolicyClick } = props;
    const [checks, setChecks] = React.useState<TPasswordCheck[]>([])
    const [form, setForm] = React.useState<Record<string, string>>({})
    const [showErrors, setShowErrors] = React.useState(false);
    const [error, setError] = React.useState<Record<string, string>>({})







    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowErrors(false);
        const { name, value } = e.target;
        const _form = { ...form, [name]: value };
        setForm(_form);
        validate(_form);
    }

    const validate = (_form: typeof form) => {
        const _checks: TPasswordCheck[] = [];
        const pass = _form[PASSWORD_KEY];
        const repeatPass = _form[REPEAT_PASSWORD_KEY];
        if (validator.containsCaps(pass)) _checks.push('caps');
        if (validator.containsNumber(pass)) _checks.push('number');
        if (validator.containsSpecialChar(pass)) _checks.push('specialChar');
        if (validator.minLength(pass)) _checks.push('minLength');
        if (pass && pass === repeatPass) _checks.push('match');
        setChecks(_checks);
    }



    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setShowErrors(true);
        const _error: Record<string, string> = {}
        const _checks = [...checks];
        if (!_checks.includes('match')) _error[REPEAT_PASSWORD_KEY] = 'error';
        if (_checks.filter(c => c !== 'match').length !== CHECKS.length - 1) _error[PASSWORD_KEY] = 'error';
        setError(_error);
        if (Object.keys(_error).length === 0 && user)
            props.onSubmit(form[PASSWORD_KEY])
    }

    const hasError = Object.keys(error).length > 0;

    return (
        <div className={classes.formCard} style={{ maxWidth: 800 }}>
            {completed ? (
                <RegistrationSuccess />
            ) : (
                    <>
                        <h4>Complete Registration</h4>
                        <br />
                        {/* <p style={{ color: AppTheme.color.brandGold }}><b>Welcome {user ? (user!.nickname || user!.name || user!.email) : ''},</b></p> */}
                        <p>Please enter a password to create your account:</p>
                        <br />
                        <div className={classes.container}>
                            <form onSubmit={handleSubmit}>
                                {hasError && showErrors ? (
                                    <p className={classes.registerError}><b>An error occured. Please try again.</b></p>
                                ) : null}

                                <input onChange={handleChange} value={form[PASSWORD_KEY] || ''}
                                    className={clsx(classes.input, { [classes.errorField]: error[PASSWORD_KEY] })}
                                    type="password" name={PASSWORD_KEY}
                                    placeholder="Create a password"
                                />

                                <input onChange={handleChange} value={form[REPEAT_PASSWORD_KEY] || ''}
                                    className={clsx(classes.input, { [classes.errorField]: error[REPEAT_PASSWORD_KEY] })}
                                    type="password" name={REPEAT_PASSWORD_KEY}
                                    placeholder="Re-enter password"
                                />


                                <div className={classes.passwordSubmitButtonContainer}>
                                    <button type="submit">{loading ? 'Loading...' : 'CREATE ACCOUNT'}</button>
                                </div>
                                <p className={classes.small + ' my-1'}>
                                    By creating an account you agree to our <span><a href="#" onClick={onPolicyClick}>Privacy Policy</a></span>
                                </p>
                            </form>
                            <div style={{ width: '100%', marginLeft: 40 }}>
                                <p className="mx-1"><b>Password hints</b></p>
                                <br />
                                {CHECKS.map(c => (
                                    <div className="flex-row " key={c.key}>
                                        <span className="mx-1"><i className={clsx("material-icons", checks.includes(c.key) ? classes.check : classes.checkGrey)}>check</i></span>
                                        <p className={checks.includes(c.key) || !showErrors ? '' : classes.checkError}>{c.label}</p>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <p><b>Already have an account?</b>&nbsp;Please <Link to="/">log in here</Link></p>
                    </>
                )}


        </div>

    )
}





export default injectSheet(STYLES)(RegisterForm)