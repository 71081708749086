import React, { FC, useCallback, useEffect } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import { createSelector } from 'reselect';
import { IAppState } from '../../../reducer/@types';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../../../models/product';
import { CLEAR_SELECTED_INDICATION, INDICATION_DETAILS_RECEIVED } from '../../../reducer/actions';
import Divider from '../DetailComponents/Divider';
import ChipsList from '../DetailComponents/ChipsList';
import { IField } from '../../../models/landscape';
import { applyFilter } from '../../../reducer';
import { parseAggregatedArray } from '../../../resources/utils';
import CoverageBadge from '../DetailComponents/CoverageBadge';
import Contact from '../../../screens/Contact';
import ContactButton from '../../ContactButton';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    id: string
}

const selectSelectedIndication = createSelector<IAppState, IAppState, Pick<IAppState, 'selectedIndication'>>(
    state => state,
    ({ selectedIndication }) => ({ selectedIndication })
)

const TherapeuticSectorsDetails: FC<IProps> = ({ id, classes }) => {

    const dispatch = useDispatch();

    const { selectedIndication } = useSelector(selectSelectedIndication);

    const getData = useCallback(async () => {
        dispatch({ type: CLEAR_SELECTED_INDICATION });
        const data = await Product.fetchIndicationDetails(id).catch(error => { throw error });
        dispatch({ type: INDICATION_DETAILS_RECEIVED, data });
    }, [dispatch, id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if ((selectedIndication && selectedIndication.airtableId !== id) || !selectedIndication) getData(); }, [id, getData]);

    const companies = (selectedIndication && parseAggregatedArray<IField>(selectedIndication.companies || [])) || [];
    const partners = (selectedIndication && parseAggregatedArray<IField>(selectedIndication.partners || [])) || [];
    const investors = (selectedIndication && parseAggregatedArray<IField>(selectedIndication.investors || [])) || [];
    const landscapes = (selectedIndication && parseAggregatedArray<IField>(selectedIndication.sector || [])) || [];
    const analysts = (selectedIndication && parseAggregatedArray<IField>(selectedIndication.analysts || [])) || [];

    const handleComapnyClick = (company: IField) => applyFilter('company', company.airtableId);
    const handlePartnerClick = (partner: IField) => applyFilter('partner', [partner.airtableId]);
    const handleInvestorClick = (investor: IField) => applyFilter('investor', [investor.airtableId]);
    const handleLandscapeClick = (landscape: IField) => applyFilter('landscape', landscape.airtableId);

    return (
        <div>
            {
                selectedIndication ?
                    (
                        <>
                            <p className={classes.title} >{selectedIndication && selectedIndication.name}</p>
                            <Divider ySpacing={10} />
                            {landscapes.length > 0 ? <ChipsList onClick={handleLandscapeClick} items={landscapes} collapsedChipCount={5} title={'Landscapes'} /> : null}
                            {(selectedIndication.companies || []).length > 0 ? <ChipsList items={companies} onClick={handleComapnyClick} title={'Companies'} collapsedChipCount={5} /> : null}
                            {(selectedIndication.partners || []).length > 0 ? <ChipsList items={partners} onClick={handlePartnerClick} title={'Partners'} collapsedChipCount={5} /> : null}
                            {(selectedIndication.investors || []).length > 0 ? <ChipsList items={investors} onClick={handleInvestorClick} title={'investors'} collapsedChipCount={5} /> : null}
                            {analysts.length > 0 && <CoverageBadge customTitle={'Chardan Researchers'} analysts={analysts} />}
                            <ContactButton />
                        </>
                    )
                    : <p>Loading</p>
            }
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    title: {
        fontSize: 14,
        lineHeight: '20px'
    }
});

export default injectSheet(STYLES)(TherapeuticSectorsDetails);