import React, { FC } from 'react';
import AppTheme, { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';
import { IProduct, COLOR_CONFIG } from '../../models/product';
import ChipToggle from '../ChipToggle';
import Divider from '../Sidebar/DetailComponents/Divider';
import { findShapeByLegendCategoryId } from '../../service/mappings';
import { applyFilter } from '../../reducer';
import Contact from '../../screens/Contact';
import ContactButton from '../ContactButton';

// eslint-disable-next-line no-use-before-define
export interface ProductPopperCardProps extends WithSheet<typeof STYLES> {
    productDetails: IProduct
    variant?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight'
    onClose?: () => void
}

const ProductPopperCard: FC<ProductPopperCardProps> = (props) => {

    const { classes, variant = 'bottomLeft', productDetails, onClose } = props;

    const positionStyleClass = classes[variant as keyof typeof STYLES];

    const logo = (productDetails.companies || []).length > 0 && (productDetails.companies[0].logo || []).length > 0 && productDetails.companies[0].logo[0].thumbnails.large.url
    const company = (productDetails.companies || []).length > 0 && productDetails.companies[0];

    const onPartnerClick = (id: string) => { applyFilter('partner', [id], ''); }

    const onCompanyClick = (id: string) => { applyFilter('company', id, ''); }

    const onLegendClick = (id: string) => { applyFilter('legendCategory', id, ''); }

    const onIndicationClick = (id: string) => { applyFilter('indication', id, ''); }

    let Comp = React.useMemo(() => {
        return findShapeByLegendCategoryId((Array.isArray(productDetails.legendCategories) && productDetails.legendCategories.length > 0) ? productDetails.legendCategories[0].airtableId : 'default');
    }, [productDetails.legendCategories]);



    return (
        <div className={classes.root} onClick={e => e.stopPropagation()} >
            <div className={clsx(classes.pointer, positionStyleClass)} />
            <div className={classes.cancelButton} onClick={() => onClose && onClose()} >
                <i className={'material-icons'} >close</i>
            </div>
            <div className={classes.header} >
                {logo ? <img className={classes.img} src={logo} alt={productDetails.name} /> : null}
                {company
                    ? <ChipToggle
                        active={false}
                        inactiveColor={'#ecf1fd'}
                        inactiveTextColor={AppTheme.color.primaryLight}
                        title={company.shortName || company.name || ''}
                        onToggle={() => onCompanyClick(company.airtableId)}
                    />
                    : null
                }
            </div>
            <Divider size='full' ySpacing={8} />
            <div className={classes.body} >
                <div className={classes.product} >
                    <Comp isActive={!productDetails.partnered} color={COLOR_CONFIG[productDetails.sector.airtableId]} />
                    <p>{productDetails.name}</p>
                </div>
                {productDetails.productComment ? <p className={classes.comment} >{productDetails.productComment}</p> : null}
                {
                    (productDetails.indications || []).length > 0
                        ? (
                            <div>
                                <p className={classes.chipHeading} >Indication</p>
                                <ChipToggle
                                    active={false}
                                    inactiveColor={'#ecf1fd'}
                                    inactiveTextColor={AppTheme.color.primaryLight}
                                    title={productDetails.indications[0].name}
                                    onToggle={() => onIndicationClick(productDetails.indications[0].airtableId)}
                                />
                            </div>
                        )
                        : null
                }
                {
                    (productDetails.legendCategories || []).length > 0
                        ? (
                            <div>
                                <p className={classes.chipHeading} >Product Category</p>
                                <ChipToggle
                                    active={false}
                                    inactiveColor={'#ecf1fd'}
                                    inactiveTextColor={AppTheme.color.primaryLight}
                                    title={productDetails.legendCategories[0].name}
                                    onToggle={() => onLegendClick(productDetails.legendCategories[0].airtableId)}
                                />
                            </div>
                        )
                        : null
                }
                {
                    (productDetails.partners || []).length > 0
                        ? <div>
                            <Divider size={'full'} ySpacing={14} />
                            <div className={classes.partnerContainer} >
                                <p>Partner: </p>
                                <ChipToggle
                                    active={false}
                                    inactiveColor={'#ecf1fd'}
                                    inactiveTextColor={AppTheme.color.primaryLight}
                                    title={productDetails.partners[0].name}
                                    onToggle={() => onPartnerClick(productDetails.partners[0].airtableId)}
                                />
                            </div>
                        </div>
                        : null
                }
                <ContactButton variant="full" />
                {/* <Contact>
                    <div className={classes.contactSection}>
                        <i className="material-icons">email</i>
                        <p>Contact Chardan</p>
                    </div>
                </Contact> */}
            </div>
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        backgroundColor: 'white',
        zIndex: 1100,
        borderRadius: 7,
        position: 'relative',
        boxShadow: '0px 1px 3px rgba(0,0,0,0.16)',
        padding: '13px 0px'
    },
    cancelButton: {
        height: 24, width: 24,
        position: 'absolute',
        borderRadius: '100%',
        backgroundColor: '#104177',
        right: '-12px', top: '-12px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        padding: 2
    },
    pointer: {
        height: 20,
        width: 20,
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
    },
    bottomLeft: { right: 40, top: '-10px', },
    bottomRight: { left: 40, top: '-10px', },
    topLeft: { right: 40, bottom: '-10px', },
    topRight: { left: 40, bottom: '-10px', },
    header: {
        padding: '0px 13px',
        display: 'flex',
        alignItems: 'center'
    },
    img: { width: 70, marginRight: 12 },
    body: {
        padding: '0px 13px',
        '& > button': { marginBottom: 7 }
    },
    product: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '& > p': {
            fontSize: 12,
            fontWieght: 'normal',
            fontFamily: 'Avenir, Montserrat',
            marginLeft: 3
        },
    },
    contactSection: {
        marginTop: 10,
        display: 'flex',
        color: theme.color.primary,
        alignItems: 'center',
        '& > p': {
            textDecoration: 'underline',
            marginLeft: 8,
        }
    },
    partnerContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            fontSize: 12,
            fontWeight: 'normal',
            marginRight: 5
        }
    },
    chipHeading: {
        margin: '12px 0px'
    },
    comment: {
        fontWeight: 'normal'
    }
});

export default injectSheet(STYLES)(ProductPopperCard);