import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { DARK_GREY, GREY } from '.';
import AppTheme from '../../resources/theme';

interface IProps extends WithSheet<typeof STYLES> {
    close?: () => void
}

const FormSubmitted: React.FC<IProps> = (props) => {

    const { classes } = props
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <div>
                    <i className="material-icons">check_circle_outline</i>
                    <p>Message sent!</p>
                </div>
                <button type="button" onClick={props.close}>
                    <i className="material-icons">close</i>
                </button>
            </div>
            <div className={classes.content}>
                <p><b>Thank you for your interest.</b></p>
                <p>{THANKYOU_MESSAGE}</p>

                <p className={classes.footer}><b>The Chardan team</b></p>
                <p>{CONTACT_NUMBER}</p>
                <p>{EMAIL}</p>
            </div>
            <div>
                <button className={classes.button} onClick={props.close}>Close</button>
            </div>
        </div>
    )
}



const THANKYOU_MESSAGE = `
We have received you message and would like to thank you for writing to us. 
If your inquiry is urgent, please use the telephone number listed below to contact us directly. 
Otherwise, we will reply by email as soon as possible.
`
const CONTACT_NUMBER = '+1 646-465-9001';
const EMAIL = 'info@chardan.com'


const STYLES = (theme: any) => ({
    root: {

    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        padding: '14px 0px',
        borderBottom: `1px solid rgba(0,0,0,0.1)`,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            '&  p': {
                fontSize: 18,
                fontFamily: AppTheme.font.family[0]
            },
            '&  i': {
                color: AppTheme.color.success,
                fontSize: 48,
                marginRight: 10,
            }
        },
        '& > button': {
            padding: 2,
            cursor: 'pointer',
            border: 'none',
            position: 'absolute',
            right: -42,
            top: -26,
            lineHeight: 0.9,
            borderRadius: '100%',
            background: AppTheme.color.primaryLight,
            '& > i': {
                color: 'white',
                fontSize: 20
            }
        },

    },
    content: {
        '& p': {
            fontWeight: 500,
            lineHeight: 1.7
        },
        '& p:first-child': {
            marginBottom: 8,
            marginTop: 20
        },
    },
    footer: {
        marginTop: 20

    },
    button: {
        marginTop: 20,
        cursor: 'pointer',
        fontWeight: 500,
        backgroundColor: AppTheme.color.primaryLight,
        color: 'white',
        fontFamily: AppTheme.font.family[0],
        textTransform: 'uppercase',
        padding: '6px 30px',
        height: 40,
        width: 124,
        '&:focus': {
            outline: 'none'
        },
    }
})

export default injectSheet(STYLES)(FormSubmitted)