import React, { FC, useEffect } from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import ToggleButton from '../../ToggleButton';
import { parseQs, updateUrl } from '../../../resources/utils';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../reducer/@types';
import { SETTING_UPDATED } from '../../../reducer/actions';
import { updateSettings } from "../../../reducer/redux-actions";


export const isCommercialStage = (stageId: string) => (stageId === "recVNZ60DSxXvBIUC" || stageId === "recaMTBugooGFzNHr");

const CommericalFilter: FC<WithSheet<typeof STYLES>> = (props) => {
    const { settings, filteredProducts } = useSelector<IAppState, Pick<IAppState, 'settings' | 'filteredProducts'>>(({ settings, filteredProducts }) => ({ settings, filteredProducts }));
    const queryState = parseQs();
    const dispatch = useDispatch();

    useEffect(() => {
        if (queryState.settings.showCommercialColumns !== settings.showCommercialColumns) {
            dispatch(updateSettings({
                settings: { ...settings, showCommercialColumns: queryState.settings.showCommercialColumns }
            }))
            // dispatch({
            //     type: SETTING_UPDATED,
            //     data: {
            //         settings: { ...settings, showCommercialColumns: queryState.settings.showCommercialColumns },
            //     }
            // });
        }
    }, [queryState.settings.showCommercialColumns, settings.showCommercialColumns]);

    const handleToggle = () => {
        const _settings = { ...settings, showCommercialColumns: !settings.showCommercialColumns }
        dispatch(updateSettings({
            settings: _settings,
            products: filteredProducts
        }, { ..._settings, eventName: 'TOGGLE COMMERCIAL_PRE_COMMERCIAL' }))
        // dispatch({
        //     type: SETTING_UPDATED,
        //     data: { settings: { ...settings, showCommercialColumns: !settings.showCommercialColumns } }
        // });
        updateUrl({ ...queryState, settings: { ...settings, showCommercialColumns: !settings.showCommercialColumns } });
    }

    return (
        <ToggleButton active={settings.showCommercialColumns} onToggle={handleToggle} label={'COMMERCIAL AND PRE-COMMERCIAL PHASES'} />
    )
}

const STYLES = ({

})

export default injectSheet(STYLES)(CommericalFilter);