import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux';
import { reducer } from './reducer';
import { Store, Dispatch } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import logger from 'redux-logger';
import { BrowserRouter } from 'react-router-dom';
import segmentMiddleware from './Analytics'
import thunk from './middlewares/thunk';
import analytics from './middlewares/analytics';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const rxStore = createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk, analytics, segmentMiddleware, logger))
);

ReactDOM.render(
    <Provider store={rxStore} >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

