import React, { FC } from 'react';
import { Legend } from './@types';
// import { Theme } from 'theming';
import injectSheet, { WithSheet } from 'react-jss';
// import { Circle, Square, Triangle, SquareRotated, Plus, DefaultShape } from '../../ProductPill';
import { findShapeByLegendCategoryId, SectorPillColor, LandscapesLegendsOrder } from '../../../service/mappings';
import { applyFilter } from '../../../reducer';
import clsx from 'clsx';

// const ShapeByIndex = [Circle, Square, Triangle, SquareRotated, Plus]

// eslint-disable-next-line no-use-before-define
export interface LegendBlockProps extends WithSheet<typeof STYLES> {
    legend: Legend
    selectedCategory?: string
}

const LegendBlock: FC<LegendBlockProps> = React.memo((props) => {

    const { legend, classes, selectedCategory } = props;

    const handleClick = (id: string) => {
        console.log('Hello World');
        applyFilter('legendCategory', id);
    }

    console.log('legend.legendCategories', legend.legendCategories);

    legend.legendCategories.sort((a, b) => {
        return (((LandscapesLegendsOrder || {})[legend._id.airtableId] || {})[a.legendCategory.airtableId] || 0) - (((LandscapesLegendsOrder || {})[legend._id.airtableId] || {})[b.legendCategory.airtableId] || 0)
    });

    console.log('Legend', legend);

    return (
        <div className={classes.root} >
            <p className={classes.title} >{legend._id.name}</p>
            {
                legend.legendCategories.map((item, i) => {
                    const Comp = findShapeByLegendCategoryId(item.legendCategory.airtableId);
                    return (
                        <div key={item.legendCategory.airtableId} className={clsx(classes.legendCategoryContainer, (selectedCategory === item.legendCategory.airtableId ? classes.selected : {}))} onClick={() => handleClick(item.legendCategory.airtableId)} >
                            <div>
                                <div>
                                    <Comp isActive color={SectorPillColor[legend._id.airtableId as keyof typeof SectorPillColor]} />
                                </div>
                                <div>
                                    <Comp color={SectorPillColor[legend._id.airtableId as keyof typeof SectorPillColor]} />
                                </div>
                            </div>
                            <p className={classes.listItem} >{item.legendCategory.name}</p>
                        </div>
                    )
                })
            }
        </div>
    )
})

const STYLES = (theme: any) => ({
    root: {
        marginBottom: 10
    },
    title: {
        fontWeight: 'bold',
        fontSize: 10,
        marginBottom: 8
    },
    listItem: {
        fontWeight: 'normal',
        fontSize: 10,
    },
    legendCategoryContainer: {
        display: 'flex',
        cursor: 'pointer',
        padding: 5,
        borderRadius: 10,
        alignItems: 'center',
        transition: '250ms ease-in-out',
        '& > div': {
            display: 'flex',
            marginRight: 5,
            '& > div': {
                marginRight: 12
            }
        }
    },
    selected: {
        backgroundColor: '#fff4d5',
    }
});

export default injectSheet(STYLES)(LegendBlock);