import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { buttonEvent, locationChangeEvent } from './events';


export type ButtonEventPayload = {
    eventName: string
    data?: Record<string, any>
}

export function useClickEventListener<T extends HTMLElement>(ref: React.RefObject<T>, payload?: ButtonEventPayload) {
    const dispatch = useDispatch();

    useEffect(() => {
        attachListeners();
    }, [ref])


    const attachListeners = () => {
        const { current } = ref;
        if (!current) return;

        current.addEventListener('click', (ev: MouseEvent) => {
            dispatch(buttonEvent(payload))
        })
    }

}

export function useLocationChangeListener() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(locationChangeEvent())
    }, [window.location.href])
}