import React, { FC } from 'react';
import injectSheet, { WithSheet } from 'react-jss';
import AppTheme from '../../resources/theme';

// eslint-disable-next-line no-use-before-define
export interface ButtonProps extends WithSheet<typeof STYLES> {
    onClick?: () => void
    style?: React.CSSProperties
    fontSize?: number
    ref?: React.Ref<HTMLButtonElement>
}

const Button: FC<ButtonProps> = ({ children, classes, onClick, style = {}, fontSize, ref }) => {

    return (
        <button ref={ref} className={classes.root} onClick={onClick} style={{ ...style, fontSize }} >
            {children}
        </button>
    )
}

const STYLES = (theme: typeof AppTheme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        color: theme.color.primary,
        height: 40,
        outline: 'none',
        border: 0,
        cursor: 'pointer',
        backgroundColor: 'transparent'
    }
});

export default injectSheet(STYLES)(Button);