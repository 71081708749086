import { IField } from './landscape';
import { IProduct } from './product';
import { ITherapeuticSector } from './therapeuticSector';


export interface IIndicationDetail extends IField {
    analysts: Array<{ _id: IField }>
    companies: Array<{ _id: IField }>
    investors: Array<{ _id: IField }>
    partners: Array<{ _id: IField }>
    indications: Array<{ _id: IField }>
    sector: Array<{ _id: IField }>
}

export interface IIndication extends IField {
    metadata: Array<any>
}


export type TIndication = Array<{
    stages: IProduct[][]
    indication: IIndication
}>

export class Indication {


    static setProducts(selectedSector: ITherapeuticSector | undefined): TIndication {
        if (!selectedSector)
            return []
        let indications: TIndication = [];
        selectedSector.indications.forEach(indication => {

            const stages = selectedSector.stages ?
                selectedSector.stages.map(stage => stage ? stage.filter(p => p.indications && p.indications.findIndex(i => i.airtableId === indication.indication.airtableId) > -1) : [])
                : [];
            indications.push({ stages, indication: indication.indication });
        })
        return indications;

    }
}