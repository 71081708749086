import defaultConfig from '../config.json';
let envConfig: any = {};
const defConfig: any = defaultConfig;

if (process.env.REACT_APP_NODE_ENV) {
    console.log("s", process.env.REACT_APP_NODE_ENV)
    const env = process.env.REACT_APP_NODE_ENV.trim();
    envConfig = require(`../config.${env}.json`);
}

const config = {
    get: (key: TConfigKey): any => {
        return envConfig[key] || defConfig[key];
    }
}


export type TConfigKey = 'BASE_URL' | 'API_URL' | 'AUTH_URL'


export default config;