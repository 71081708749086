import React, { FC, memo } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';

// eslint-disable-next-line no-use-before-define
export interface DividerProps extends WithSheet<typeof STYLES> {
    size?: 'small' | 'full',
    ySpacing?: number
}

const Divider: FC<DividerProps> = memo(({ classes, size = 'small', ySpacing = 0 }) => {

    const sizeKey = classes[`${size}Size` as keyof typeof STYLES];

    return (
        <div className={clsx(sizeKey, classes.root)} style={{ margin: `${ySpacing}px 0px` }} />
    )
})

const STYLES = (theme: IAppTheme) => ({
    root: {
        height: 1,
        backgroundColor: '#E4E4E4'
    },
    smallSize: {
        width: 20
    },
    fullSize: {
        width: '100%'
    }
});

export default injectSheet(STYLES)(Divider);