import * as React from 'react';
import injectSheet, { WithSheet } from 'react-jss';
import { Route, Switch } from "react-router-dom";
import { IAppTheme } from './resources/theme';
import ForgotPassword from './screens/ForgotPassword';
import Home from './screens/Home';
import LandingScreen from './screens/Landing';
import Register from './screens/Register';


// eslint-disable-next-line no-use-before-define
interface Props extends WithSheet<typeof STYLES> {

}

const RootNavigation: React.FC<Props> = (props) => {

    return (
        <>
            <Switch>
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <Route path="/view" exact component={Home} />
                <Route path="/complete-registration" exact component={Register} />
                <Route path="/" component={LandingScreen} />
            </Switch>
        </>
    )
}




const STYLES = (theme: IAppTheme) => ({

})

export default injectSheet(STYLES)(RootNavigation);