import clsx from 'clsx';
import * as React from 'react';
import injectSheet, { WithSheet } from 'react-jss';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { IProduct } from '../../../models/product';
import { IAppState } from '../../../reducer/@types';
import { IQueryState } from '../../../resources/@types';
import { Device, parseQs, updateUrl } from '../../../resources/utils';
import { isCommercialStage } from '../../AppHeader/Filters/CommercialFilter';
import ProductPill from '../../ProductPill';
// import { SELECT_PRODUCT } from '../../reducer/actions';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    products: IProduct[] | undefined,
    colIndex: number
}

type IStateProps = Pick<IAppState, 'stages' | 'filteredProducts' | 'selectedProduct' | 'settings' | 'allProducts'>;

const selectStates = createSelector<IAppState, IAppState, IStateProps>(
    state => state,
    state => ({ stages: state.stages, filteredProducts: state.filteredProducts, selectedProduct: state.selectedProduct, settings: state.settings, allProducts: state.allProducts })
)

const Pills: React.FC<IProps> = (props) => {
    const { classes, products = [], colIndex } = props;
    const [compact, setPillType] = React.useState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryState = parseQs();
    const { columnId, rowId, selectedProduct } = queryState;


    const state = useSelector<IAppState, IStateProps>(selectStates);
    const stageId = state.stages ? state.stages[colIndex].airtableId : ''

    React.useEffect(() => {
        if (rowId !== '' || columnId !== '')
            setPillType(false);
        else
            setPillType(true)
    }, [rowId, columnId]);

    const highlightItem = (product: IProduct) => {
        if (!product.companies || !product.companies.length)
            return;
        if (product.airtableId === queryState.selectedProduct && product.airtableId === (state.selectedProduct || {} as IProduct).airtableId) {
            onCloseDetails();
            return;
        }
        let newQuery = { ...queryState, selectedProduct: product.airtableId };
        updateUrl(newQuery);
    }

    const onCloseDetails = () => {
        const newQueryState: IQueryState = {
            ...queryState, selectedProduct: ''
        }
        updateUrl(newQueryState);
    }


    return (
        <div className={
            clsx(
                classes.root,
                'lscp-column',
                stageId === columnId ? 'expanded' : (columnId !== '' ? 'hidden' : ''),
                isCommercialStage(stageId) ? (state.settings.showCommercialColumns ? '' : 'hidden') : ''
            )
        }>
            <div className={compact ? classes.productPillContainer : classes.compactProductPillContainer} >
                {
                    (products) ? products.map((product, i) => {
                        let isActive = state.filteredProducts[product.airtableId] || (state.selectedProduct && state.selectedProduct.airtableId) === product.airtableId;
                        return (
                            <div
                                style={{ display: 'inline-block', }}
                                key={`${product.airtableId}-${i}`}
                            >
                                <ProductPill
                                    showDetails={state.selectedProduct && state.selectedProduct.airtableId === selectedProduct && product.airtableId === state.selectedProduct.airtableId}
                                    active={isActive && (Object.keys(state.filteredProducts || {}).length !== (state.allProducts || []).length)}
                                    onClick={highlightItem}
                                    compact={compact}
                                    product={product}
                                    onCloseDetails={onCloseDetails}
                                />
                            </div>
                        )
                    }) : <div></div>
                }
            </div>
        </div>
    )
}

const STYLES = (theme: any) => ({
    root: {
        background: theme.color.background,
        borderBottom: `2px solid rgba(0,0,0,0.05)`,
        boxSizing: 'border-box',
        margin: '0px 5px',
        '& .sample-class': {
            display: 'inline-block',
            margin: '4px 10px',
            boxSizing: 'border-box',
        },
        '& > span > span': {
            margin: 8,
            boxSizing: 'border-box',
        },
        '& .highlight': {
            // transform: 'scale(1.1)',
            borderRadius: 8,
            backgroundColor: '#e3ebf4',
            border: '1px solid #bddcff',
            position: 'relative',
            boxSizing: 'border-box',
            '& .product-pill': {
                opacity: 1,
            }
        },
    },
    productContainer: {
        position: 'relative'
    },
    productPillContainer: {
        padding: Device.isTablet ? undefined : '8px 12px',
        boxSizing: 'border-box',
    },
    compactProductPillContainer: {
        padding: '20px 0px',
        // margin: '0px 8px',
        boxSizing: 'border-box',
    }
})

export default injectSheet(STYLES)(Pills)