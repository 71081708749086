import axios from 'axios';
import { IProduct } from './product';
import { IStage } from './landscape';
import { IIndication } from './indication';
import { LandscapesLegendsOrder } from '../service/mappings';

export interface IField {
    airtableId: string
    name: string
}

export interface ITherapeuticSectors extends IField {
    metadata: Array<any>
}

export interface ITherapeuticSectorDetail extends IField {
    companies: Array<{ _id: IField }>
    investors: Array<{ _id: IField }>
    partners: Array<{ _id: IField }>
    indications: Array<{ _id: IField }>
    analysts: Array<{ _id: IField }>
    sector: Array<{ _id: IField }>
}

export interface ITherapeuticSector {
    indications: Array<{ indication: IIndication, total: number }>
    total: number
    _id: IField,
    regulatoryStages: IRegulatoryStage[]
    stages?: IProduct[][]
}

export interface IRegulatoryStage {
    _id: IField
    indications: { _id: IIndication, total: number }[]
    total: number
}

export class TherapeuticSector {

    static async fetchTherapeuticSectors(): Promise<ITherapeuticSector[] | null> {
        try {
            const res = await axios.request({ url: '/products/therapeuticsectors' });
            return res.data;
        } catch (error) {
            return []
        }

    }

    static productsByLegendsOrder = (products: Array<IProduct>, landscapeId: string): Array<IProduct> => {
        products.sort((a, b) => LandscapesLegendsOrder[landscapeId][a.legendCategories[0].airtableId] - LandscapesLegendsOrder[landscapeId][b.legendCategories[0].airtableId]);
        return products
    }

    static productsByLegends = (products: Array<IProduct>, landscapeId: string): Array<IProduct> => {
        let arr: Array<IProduct> = [];

        let legendObj: Record<string, Array<IProduct>> = {};

        (products || []).forEach((product) => {
            product.legendCategories.forEach(lc => {
                let legendProducts = legendObj[lc.airtableId] || [];
                legendProducts.push(product);
                legendObj[lc.airtableId] = legendProducts;
            })
        });

        let sortableArr: Array<Array<string | Array<IProduct>>> = [];
        for (let key in legendObj) {
            sortableArr.push([key, legendObj[key]]);
        }

        sortableArr.sort((a, b) => {
            return LandscapesLegendsOrder[landscapeId][a[0] as string] - LandscapesLegendsOrder[landscapeId][b[0] as string]
        });

        let sortedLegendObj: Record<string, Array<IProduct>> = {};

        sortableArr.forEach((item) => {
            sortedLegendObj[item[0] as string] = item[1] as IProduct[];
        });

        // console.log(Object.keys(so))

        Object.keys(sortedLegendObj).forEach(lc => {

            arr.push(...(
                (sortedLegendObj[lc] || []).sort(
                    (a, b) => (
                        ((a.companies || [])[0] || {}).name || ' ').localeCompare((((b.companies || [])[0] || {}).name || ' ')))));
        });



        return arr;
    }

    static productsByLandscape = (products: Array<IProduct>): Array<IProduct> => {

        let arr: Array<IProduct> = [];
        // where key is the airtableId of the landscae
        let landscapeObj: Record<string, Array<IProduct>> = {};

        const landscapes = ["cell_therapy", "gene_therapy_gene_editing", "rna", "microbiome", "digital_therapeutics"];

        (products || []).forEach((product) => {
            // sector in product is the landscape.
            let landscapeProducts = (landscapeObj[product.sector.airtableId] || []);
            landscapeProducts.push(product);
            landscapeObj[product.sector.airtableId] = landscapeProducts;
        });

        landscapes.forEach(ls => { arr.push(...(TherapeuticSector.productsByLegends(landscapeObj[ls], ls))) });

        return arr;
    }

    static productsByStages = (stages: Array<IStage>, products: Array<IProduct>): IProduct[][] => {
        let stageObj: {
            [key: string]: Array<IProduct>
        } = {};
        products.forEach(product => {
            (product.regulatoryStages || []).forEach(stage => {
                let stageProducts = stageObj[stage.airtableId] || [];
                stageProducts.push(product);
                stageObj[stage.airtableId] = stageProducts;
            });
        });
        let arr: IProduct[][] = [];
        stages.forEach(stage => { arr.push(TherapeuticSector.productsByLandscape(stageObj[stage.airtableId])) });
        return arr;
    }
    static setProducts(thSectors: Array<ITherapeuticSector> | undefined, products: Array<IProduct> | undefined, stages: Array<IStage> | undefined): Array<ITherapeuticSector> {
        if (!thSectors || !thSectors.length)
            return [];
        if (!products || !products.length)
            return thSectors;
        return thSectors.map((thSector) => {
            const thProducts = products.filter((product) => (product.therapeuticSectors.findIndex(item => item.airtableId === thSector._id.airtableId) > -1));
            thSector.stages = TherapeuticSector.productsByStages(stages || [], thProducts);
            return thSector;
        });
    }

    static cleaupTherapeuticSectors(thSectors: ITherapeuticSector[]) {
        let temp: string[] = []
        thSectors.forEach((ts, i) => {
            let indications: {
                indication: IIndication;
                total: number;
            }[] = [];
            ts.regulatoryStages.forEach(s => {
                indications = [...indications, ...s.indications.map(ind => ({ indication: ind._id, total: ind.total })).filter(ind => temp.indexOf(ind.indication.airtableId) === -1)];
                temp = [...indications.map(ind => ind.indication.airtableId)];
            })
            thSectors[i].indications = indications
        })
        return thSectors;
    }
}