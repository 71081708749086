import React, { FC, useEffect, useContext } from 'react'
import ToggleButton from '../../ToggleButton';
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../../../reducer/@types";
import { getFilteredProducts } from '../../../reducer';
import { parseQs, updateUrl } from "../../../resources/utils";
import { SETTING_UPDATED } from '../../../reducer/actions';
import { Product } from "../../../models/product";
import { updateSettings } from "../../../reducer/redux-actions";
import { getProductsFromFilteredProducts } from '.';

const PublicPrivateFilter: FC = () => {
    const { allProducts = [] } = useSelector<IAppState, Pick<IAppState, 'allProducts'>>(({ allProducts }) => ({ allProducts }));
    const { settings, products = [], originalFilterProducts } = useSelector<IAppState, Pick<IAppState, 'settings' | 'products' | 'originalFilterProducts'>>(({ settings, products, originalFilterProducts }) => ({ settings, products, originalFilterProducts }));
    const queryState = parseQs();
    const dispatch = useDispatch();

    useEffect(() => {
        const { public: QueryPublic, private: QueryPrivate } = queryState.settings;
        if (QueryPublic !== settings.public || QueryPrivate !== settings.private) {
            dispatch(updateSettings({
                settings: queryState.settings,
                products: Product.settingFilterProduct(queryState.settings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
            }))
        }
    }, [queryState.settings, settings]);

    const handleTogglePublic = () => {
        let privateFlag = settings.public ? true : settings.private;
        const updatedSettings = {
            ...settings,
            private: privateFlag,
            public: !settings.public
        }
        dispatch(updateSettings({
            settings: updatedSettings,
            // products: Product.settingFilterProduct(updatedSettings, products.filter((p => originalFilterProducts[p.airtableId])))
            products: Product.settingFilterProduct(updatedSettings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
        }, { ...updatedSettings, eventName: 'TOGGLE PUBLIC' }))

        updateUrl({ ...queryState, settings: updatedSettings });
    }

    const handleTogglePrivate = () => {
        let publicFlag = settings.private ? true : settings.public;
        const updatedSettings = {
            ...settings,
            private: !settings.private,
            public: publicFlag
        }

        dispatch(updateSettings({
            settings: updatedSettings,
            // products: Product.settingFilterProduct(updatedSettings, products.filter((p => originalFilterProducts[p.airtableId])))
            products: Product.settingFilterProduct(updatedSettings, getProductsFromFilteredProducts(allProducts, originalFilterProducts))
        }, { ...updatedSettings, eventName: 'TOGGLE PRIVATE' }))

        updateUrl({ ...queryState, settings: updatedSettings });
    }

    return (
        <>
            <ToggleButton active={settings.private} onToggle={handleTogglePrivate} label={'PRIVATE'} />
            <ToggleButton active={settings.public} onToggle={handleTogglePublic} label={'PUBLIC'} />
        </>
    )
}

export default PublicPrivateFilter;