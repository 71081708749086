import React, { FC, memo } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';

// eslint-disable-next-line no-use-before-define
interface ReleaseInfoProps extends WithSheet<typeof STYLES> { }

const ReleaseInfo: FC<ReleaseInfoProps> = memo(({ classes }) => {
    return (
        <div className={classes.root} >
            <p className={classes.h1} >Release Notes</p>

            <div className={classes.infoBlock} >
                <p className={classes.title} >Digital Therapeutics (DTx) and Prescription Digital Therapeutics (PDTx) Companies</p>
                <p className={classes.subtitle}>Release 2.0</p>
                <p className={classes.description} >
                    Data from publicly available sources as of June 19, 2019
                    {/* <br />
                    Prepared by Steven Wardell,
                    <br />
                    Senior Research Analyst
                    <br />
                    <a href={'mailto:swardell@chardan.com'} >swardell@chardan.com</a>
                    <br />
                    Kip Bitok, PhD, Senior Research Associate
                    <br />
                    <a href={'mailto:kbitok@chardan.com'} >kbitok@chardan.com</a> */}
                </p>
            </div>

            <div className={classes.infoBlock} >
                <p className={classes.title} >Gene Therapy and Gene Editing Companies*</p>
                <p className={classes.subtitle}>Release 4.0</p>
                <p className={classes.description}>
                    Data from publicly available sources as of August 12, 2019   *Excluding ex vivo engineered immune cells
                    {/* Prepared by Chardan Healthcare Banking and Chardan Research */}
                </p>
            </div>

            <div className={classes.infoBlock} >
                <p className={classes.title} >Microbiome Medicines Companies</p>
                <p className={classes.subtitle}>Release 3.0</p>
                <p className={classes.description}>
                    Data from publicly available sources as of August 12, 2019
                    {/* Prepared by Taylor Feehley, PhD, Senior Research Analyst <br />
                    <a href={'mailto:tfeehley@chardan.com'} >tfeehley@chardan.com</a> */}
                </p>
            </div>

            <div className={classes.infoBlock} >
                <p className={classes.title} >RNA Medicines Companies</p>
                <p className={classes.subtitle}>Release 3.0</p>
                <p className={classes.description}>
                    Data from publicly available sources as of July 8, 2019
                    </p>
            </div>
            <div className={classes.infoBlock} >
                <p className={classes.title} >Cell-based Immunotherapy Companies</p>
                <p className={classes.subtitle}>Release 1.0</p>
                <p className={classes.description}>
                    Data from publicly available sources as of December 20, 2019
                <br />
                    <br />
                    Contact <a>info@chardan.com</a>
                    {/* Prepared by Chardan Healthcare Banking and Chardan Research */}
                </p>
            </div>
            <small className={classes.small}>Updated as of Dec 31st, 2019</small>
        </div>
    )
});

const STYLES = (theme: IAppTheme) => ({
    root: {

    },
    h1: {
        fontSize: 12,
        marginBottom: 12,
        fontWeight: 'bold'
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    subtitle: {
        textTransform: 'uppercase',
        fontWeight: 'normal'
    },
    description: {
        fontWeight: 'normal',
        '& a': {
            color: '#104177'
        }
    },
    infoBlock: {
        marginBottom: 20
    },
    small: {
        fontSize: 10,
        fontWeight: 400
    }
});

export default injectSheet(STYLES)(ReleaseInfo);