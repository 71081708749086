import { Middleware, MiddlewareAPI, Dispatch } from "redux";
import { AnalyticsEvent } from "../Analytics";
import { IAppState } from "../reducer/@types";

/*
    Middleware that handles analytics

    whenever an action needs to be sent to our analytics controller,
    just add  'analytics' object with the name of the controller in it to your payload;
    example: the dispatched object should look something like this
     {
        type: 'ACTION_TYPE',
        data: ....,
        analytics: {
            name: 'CONTROLLER NAME',
            data: ...
        }
    }
*/
const analytics: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
    next(action);

    const { tracking_enabled = true } = store.getState() as IAppState;

    if (tracking_enabled && action && action.analytics && action.analytics.name) {
        const { name, data } = action.analytics;
        next({
            type: name,
            data
        })
    }

    return;
}



// utility funtion to generate analytics data in dispatch payload for our middleware.
export interface AnalyticPayload extends Record<'eventName', string> {
    [key: string]: any
}
export const getAnalyticsMetadata = (analyticEvent: AnalyticsEvent, analyticPayload?: AnalyticPayload) => ({
    analytics: {
        name: analyticEvent,
        data: analyticPayload
    }
})


export default analytics