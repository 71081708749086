import React, { FC } from 'react';
import { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';

// eslint-disable-next-line no-use-before-define
interface DrawerProps extends WithSheet<typeof STYLES> {
    open: boolean
    position: 'left' | 'right'
}

const Drawer: FC<DrawerProps> = ({ open, classes, position = 'right', children }) => {
    const positionKey = `position${position.toUpperCase()}`;
    return (
        <div className={clsx(classes.root, classes[positionKey as keyof typeof STYLES], { [classes.closed]: !open })} >
            {children}
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        position: 'fixed',
        top: '60px', bottom: 0,
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px rgba(0,0,0,0.16)',
        transition: '500ms linear',
        zIndex: 1100,
    },
    positionLEFT: { left: 0 },
    positionRIGHT: { right: 0 },
    closed: {
        right: '-250px'
    }
});

export default injectSheet(STYLES)(Drawer);