import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { useSpring, animated } from 'react-spring'
import AppTheme from '../../resources/theme';

interface IProps extends WithSheet<typeof STYLES> {
    active: boolean
    onToggle: () => void
    label: string
}

const ToggleButton: React.FC<IProps> = (props) => {
    const { classes, active, label = '' } = props;
    const track_spring = useSpring({
        background: active ? AppTheme.color.accent : AppTheme.color.backgroundDark,
    })
    const thumb_spring = useSpring({
        left: active ? 19 : 1
    });


    const handleToggle = () => {
        props.onToggle();
    }


    return (
        <div className={classes.toggleContainer} onClick={handleToggle} >
            <animated.div className={classes.toggle} style={track_spring}>
                <animated.button className={classes.thumb} style={thumb_spring}></animated.button>
            </animated.div>
            <p className={active ? classes.active : classes.inactive}>{label.toUpperCase()}</p>
        </div>
    )
}

const STYLES = (theme: any) => ({
    toggleContainer: {
        // background: theme.color.primaryDark,
        // borderRadius: 9,
        padding: '8px 10px',
        lineHeight: 0,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    toggle: {
        height: 15,
        width: 34,
        position: 'relative',
        borderRadius: 15,
        marginRight: 8,
        padding: 1
    },
    thumb: {
        position: 'absolute',
        background: 'white',
        borderRadius: '50%',
        width: 15, height: 15,
        border: 'none',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none'
        }
    },
    inactive: {
        fontSize: 8,
        // color: AppTheme.color.primaryLight,
    },
    active: {
        fontSize: 8,
        // color: AppTheme.color.accent
    },
})

export default injectSheet(STYLES)(ToggleButton)