import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import AppTheme from '../../resources/theme';


type TChipLabelType = 'P' | 'C';

interface IProps extends WithSheet<typeof STYLES> {
    total?: number | string
    name: string
    type?: TChipLabelType
}

const SidebarChipItem: React.FC<IProps> = (props) => {

    const { classes, name, total, type } = props
    return (
        <div className={classes.chipTextContainer}>
            <p title={name} className={classes.name}>{name}</p>
            {total ? <span className={classes.total}>{total + ''}</span> : null}
            {type ? <span className={classes.type}>{type}</span> : null}
        </div>
    )
}


interface LegendChipTypeProps extends WithSheet<typeof STYLES> {
    type: TChipLabelType
}
const _LegendChipType: React.FC<LegendChipTypeProps> = (props) => {
    const { classes, type } = props;
    const TYPES = {
        P: 'Products',
        C: 'Companies'
    }

    return (
        <div className={classes.chipTextContainer}>
            <span className={classes.type}>{type}</span>
            <span className={classes.typeLabel}>{TYPES[type]}</span>
        </div>
    )
}

const STYLES = (theme: any) => ({
    chipTextContainer: {
        display: 'flex',
        alignItems: 'center',

    },
    total: {
        marginLeft: 6,
        fontWeight: 400
    },
    name: {
        maxWidth: 90,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    type: {
        fontSize: 6,
        color: AppTheme.color.darkGrey,
        lineHeight: '10px',
        width: 10,
        height: 10,
        textAlign: 'center',
        marginLeft: 2,
        border: `1px solid ${AppTheme.color.darkGrey}`,
        borderRadius: '100%',
    },
    typeLabel: {
        marginLeft: 3,
        fontWeight: 500,
        fontSize: 8
    }
})

export const LegendChipType = injectSheet(STYLES)(_LegendChipType)
export default injectSheet(STYLES)(SidebarChipItem)