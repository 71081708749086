import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';
import { IProduct } from '../../models/product';
import ProductPill from '../ProductPill';
import { useSelector } from 'react-redux';
import { IAppState } from '../../reducer/@types';
import { parseQs, updateUrl } from '../../resources/utils';
import { createSelector } from 'reselect'
import { IQueryState, ILayoutViewType } from '../../resources/@types';
import { settings } from 'cluster';
import { isCommercialStage } from '../AppHeader/Filters/CommercialFilter';
import Pills from './CellTypes/Pills';
import Bars from './CellTypes/Bars';
import { LayoutContext } from '../../LayoutContext';
// import { SELECT_PRODUCT } from '../../reducer/actions';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    products: IProduct[] | undefined,
    colIndex: number
}



const TableCell: React.FC<IProps> = (props) => {
    const { classes, products = [], colIndex } = props;
    const { viewType } = React.useContext(LayoutContext);


    const TableCellComponent = viewType === 'bar' ? Bars : Pills


    return <TableCellComponent products={products} colIndex={colIndex} />


}

const STYLES = (theme: any) => ({

})

export default injectSheet(STYLES)(TableCell)