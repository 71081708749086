import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { Link, RouteComponentProps } from 'react-router-dom';
import LionImage from '../../resources/images/Lion.svg';
import AppTheme from '../../resources/theme';
import RegisterForm from '../../Components/AuthForm/RegisterForm';
import qs from 'qs';
import { Auth, TUser } from '../../models/auth';
import Policy from '../Policy';

interface IProps extends WithSheet<typeof STYLES>, RouteComponentProps { }

const Register: React.FC<IProps> = (props) => {

    const { classes } = props;
    const [user, setUser] = React.useState<TUser | undefined>()
    const [loading, setLoading] = React.useState(false);
    const [mounted, setMounted] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const query: any = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    const [invalidTicket, setInvalidTicket] = React.useState(false);
    const [policyOpen, setPolicyOpen] = React.useState(false)

    React.useEffect(() => {
        init();
    }, [])

    const init = async () => {

        if (!query.ticket) {
            setInvalidTicket(true);
            return;
        }
        try {
            const res = await Auth.getTicketInfo(query.ticket);
            const info = res.data;
            setUser(info);
            setInvalidTicket(false);
        } catch (error) {
            setInvalidTicket(true)
        }
        setMounted(true)
    }

    const handleSubmit = async (password: string) => {
        setSuccess(false);
        if (!query.ticket) return;
        setLoading(true);
        try {
            await Auth.resetPassword(query.ticket, password);
            setSuccess(true);
        } catch (error) {

        }
        setLoading(false)

    }


    return (
        <>
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.hero}>
                        <img src={LionImage} alt={'Chardan Logo'} />
                        <p className={classes.title} >Chardan BioScope<sup className={classes.superScript} >TM</sup></p>
                        <p className={classes.subTitle} >Interactive Landscape of <br /> Selected Healthcare Companies</p>
                    </div>
                    <div className={classes.formContainer}>
                        {mounted ? (
                            invalidTicket ? <h3 className={classes.errorText}>Invalid ticket</h3> : (
                                <RegisterForm onPolicyClick={() => setPolicyOpen(true)} user={user} onSubmit={handleSubmit} loading={loading} completed={success} />
                            )
                        ) : (
                                <h3 className={classes.errorText} style={{ color: AppTheme.color.brandGold }}>Loading...</h3>
                            )}
                        {/* {invalidTicket ? <h3 className={classes.errorText}>Invalid ticket</h3> : (
                            <RegisterForm onPolicyClick={() => setPolicyOpen(true)} user={user} onSubmit={handleSubmit} loading={loading} completed={success} />
                        )} */}
                    </div>
                </div>
            </div>
            <Policy strict disableActions open={policyOpen} onClose={() => setPolicyOpen(false)} />
        </>
    )
}

const STYLES = (theme: any) => ({
    root: {

    },
    content: {
        width: '100vw',
        display: 'flex',
        margin: '0 auto',
        justifyContent: 'space-evenly',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
    },
    hero: {

    },
    errorText: {
        color: AppTheme.color.error,
        width: 300
    },
    formContainer: {
        // width: 500,
        // boxShadow: AppTheme.shadow[0]
    },
    title: {
        fontFamily: AppTheme.font.family[0],
        fontSize: '2vw',
        fontWeight: 400,
        margin: '20px 0px',
        position: 'relative'
    },
    superScript: {
        fontFamily: AppTheme.font.family[0],
        fontSize: '1vw',
        fontWeight: 400,
        color: theme.color.black,
        textTransform: 'uppercase'
    },
    subTitle: {
        fontFamily: AppTheme.font.family[0],
        fontSize: '1.5vw',
        fontWeight: 400
    }
})

export default injectSheet(STYLES)(Register)