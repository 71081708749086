import clsx from 'clsx';
import * as React from 'react';
import injectSheet, { WithSheet } from 'react-jss';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ILandscape } from '../../../models/landscape';
import { IProduct, Product } from '../../../models/product';
import { ITherapeuticSector } from '../../../models/therapeuticSector';
import { IAppState } from '../../../reducer/@types';
import { IQueryState } from '../../../resources/@types';
import { parseQs, updateUrl } from '../../../resources/utils';
import { isCommercialStage } from '../../AppHeader/Filters/CommercialFilter';
import ProductBar from '../../ProductBar';
import Pills from './Pills';
// import { SELECT_PRODUCT } from '../../reducer/actions';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    products: IProduct[] | undefined,
    colIndex: number
}

type IStateProps = Pick<IAppState,
    'stages'
    | 'currentHighestRegulatoryTotal'
    | 'filteredProducts'
    | 'selectedProduct'
    | 'selectedSectorBar'
    | 'settings'
    | 'highestRegulatoryTotal'
    | 'aggregationType'
    | 'selectedSectors'
>;

const selectStates = createSelector<IAppState, IAppState, IStateProps>(
    state => state,
    state => ({ selectedSectors: state.selectedSectors, aggregationType: state.aggregationType, currentHighestRegulatoryTotal: state.currentHighestRegulatoryTotal, highestRegulatoryTotal: state.highestRegulatoryTotal, stages: state.stages, filteredProducts: state.filteredProducts, selectedProduct: state.selectedProduct, selectedSectorBar: state.selectedSectorBar, settings: state.settings })
)

const Bars: React.FC<IProps> = (props) => {
    const { classes, products = [], colIndex } = props;
    const [compact, setPillType] = React.useState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryState = parseQs();
    const { columnId, rowId, selectedProduct } = queryState;



    const state = useSelector<IAppState, IStateProps>(selectStates);
    const therapeuticSectors = useSelector<IAppState, ITherapeuticSector[]>(state => state.therapeuticSectors || []);
    const landscapes = useSelector<IAppState, ILandscape[]>(state => state.landscapes || []);
    const stageId = state.stages ? state.stages[colIndex].airtableId : ''
    const groupedProductsBySector = Product.groupProductsBySectors(products);
    const sectors = Object.keys(groupedProductsBySector);
    const totalProducts = state.aggregationType === 'current' ? state.currentHighestRegulatoryTotal : state.highestRegulatoryTotal;




    React.useEffect(() => {
        if (rowId !== '' || columnId !== '')
            setPillType(false);
        else
            setPillType(true)
    }, [rowId, columnId]);



    // const highlightItem = (product: IProduct) => {
    //     if (!product.companies || !product.companies.length)
    //         return;
    //     if (product.airtableId === queryState.selectedProduct && product.airtableId === (state.selectedProduct || {} as IProduct).airtableId) {
    //         onCloseDetails();
    //         return;
    //     }
    //     let newQuery = { ...queryState, selectedProduct: product.airtableId };
    //     updateUrl(newQuery);
    // }

    const getSectorFromId = (sectorId: string) =>
        landscapes.find(s => s._id.airtableId === sectorId);


    const highlightItem = (products: IProduct[], sectorId: any) => {
        const _sector = getSectorFromId(sectorId);
        const _rowId = products[0].therapeuticSectors[0] ? products[0].therapeuticSectors[0].airtableId : '';
        const _colId = products[0].regulatoryStages[0] ? products[0].regulatoryStages[0].airtableId : ''
        console.log("products sector", products, _sector)
        if (state.selectedSectorBar && sectorId === state.selectedSectorBar.sectorId && _rowId === state.selectedSectorBar.rowId && _colId === state.selectedSectorBar.colId) {
            onCloseDetails();
            return;
        }
        if (products.length === 0) return;

        if (!_rowId || !_colId) return;
        let newQuery: IQueryState = { ...queryState, selectedSectorBar: { sectorId, rowId: _rowId, colId: _colId } };
        updateUrl(newQuery);
    }

    const shouldShowDetail = (products: IProduct[], sectorId: any) => {
        const _rowId = products[0].therapeuticSectors[0] ? products[0].therapeuticSectors[0].airtableId : '';
        const _colId = products[0].regulatoryStages[0] ? products[0].regulatoryStages[0].airtableId : '';
        return state.selectedSectorBar && sectorId === state.selectedSectorBar.sectorId && _rowId === state.selectedSectorBar.rowId && _colId === state.selectedSectorBar.colId
    }

    const onCloseDetails = () => {
        const newQueryState: IQueryState = {
            ...queryState, selectedSectorBar: {
                rowId: '',
                colId: '',
                sectorId: ''
            }
        }
        updateUrl(newQueryState);
    }

    if (!compact)
        return <Pills products={products} colIndex={colIndex} />


    return (
        <div className={
            clsx(
                classes.root,
                'lscp-column',
                stageId === columnId ? 'expanded' : (columnId !== '' ? 'hidden' : ''),
                isCommercialStage(stageId) ? (state.settings.showCommercialColumns ? '' : 'hidden') : ''
            )
        }>
            <div className={compact ? classes.productPillContainer : classes.compactProductPillContainer} >
                {

                    (sectors.length > 0) ? sectors.map((sectorId, i) => {
                        const _products: IProduct[] = groupedProductsBySector[sectorId];
                        const _filteredProducts = Object.keys(state.filteredProducts).length > 0 ? _products.filter(p => !!state.filteredProducts[p.airtableId]) : _products;
                        const _p = _products[0];
                        if (!_p) return <div />

                        // if (sectorId === 'cell_therapy')
                        //     console.log("he->", groupedProductsBySector, _products, state.filteredProducts)

                        const regulatoryStage = _p.regulatoryStages[0];
                        const config = Product.getProductBarConfig(
                            totalProducts,
                            _products,
                            products,
                            sectorId,
                            therapeuticSectors,
                            regulatoryStage,
                            state.filteredProducts,
                        )
                        return (
                            <div key={`${sectorId}-${i}`} className={classes.productContainer}>
                                <ProductBar
                                    products={_filteredProducts}
                                    sector={getSectorFromId(sectorId)}
                                    showDetail={shouldShowDetail(_products, sectorId)}
                                    onClick={() => highlightItem(_products, sectorId)}
                                    {...config}
                                />
                            </div>
                        )
                    }) : <div></div>
                }
            </div>
        </div>
    )
}

const STYLES = (theme: any) => ({
    root: {
        background: theme.color.background,
        borderBottom: `2px solid rgba(0,0,0,0.05)`,
        margin: '0px 5px',
        '& .sample-class': {
            display: 'inline-block',
            margin: '4px 10px'
        },
        '& > span > span': {
            margin: 8,
        },
        '& .highlight': {
            // transform: 'scale(1.1)',
            borderRadius: 8,
            backgroundColor: '#e3ebf4',
            border: '1px solid #bddcff',
            position: 'relative',
            '& .product-pill': {
                opacity: 1,
            }
        },
    },
    productContainer: {
        position: 'relative'
    },
    productPillContainer: {
        padding: '8px 12px',
    },
    compactProductPillContainer: {
        padding: 20
    }
})

export default injectSheet(STYLES)(Bars)