import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { STYLES } from './style';

interface IProps extends WithSheet<typeof STYLES> {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
    loading?: boolean
    errorMsg?: string
    successMsg?: string
    userName: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleLogin?: () => void
}

const ResetPasswordForm: React.FC<IProps> = (props) => {

    const { classes, userName = '', loading = false, errorMsg = '', successMsg = '' } = props
    return (
        <form className={classes.formCard} onSubmit={props.onSubmit}>
            <h4>Reset password</h4>
            <input className={classes.passwordInput} type="text" name="userName" value={userName} placeholder="Enter email" onChange={props.onChange} />
            <div className={classes.passwordSubmitButtonContainer}>
                <button type="submit">{loading ? 'Loading...' : 'SEND RESET LINK'}</button>
            </div>
            <p style={{ opacity: (errorMsg ? 1 : 0) }} className={classes.loginError}>{errorMsg}</p>
            <p style={{ opacity: (successMsg ? 1 : 0) }} className={classes.successMessage}>{successMsg}</p>
            <br />
            <p>
                Already a user?&nbsp;<span><a onClick={props.handleLogin} href="#">Log in</a></span>
            </p>
            <br />
            <hr />
            <h5>Request access</h5>
            <p>
                "Don’t have an account?
            <br />
            Please email <a href="mailto:info@chardan.com">info@chardan.com</a> to request additional information or to schedule a demo.
                </p>
        </form>
    )
}



export default injectSheet(STYLES)(ResetPasswordForm)