import React, { FC, useEffect, useCallback, } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import { Product } from '../../../models/product';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { IAppState } from '../../../reducer/@types';
import { COMPANY_RECEIVED, CLEAR_SELECTED_COMPANY } from '../../../reducer/actions';
import Description from '../DetailComponents/Description';
import Divider from '../DetailComponents/Divider';
import ChipsList from '../DetailComponents/ChipsList';
import { IField } from '../../../models/landscape';
import { withRouter, RouteComponentProps } from 'react-router';
import { applyFilter } from '../../../reducer';
import CoverageBadge from '../DetailComponents/CoverageBadge';
import { parseAggregatedArray } from '../../../resources/utils';
import { IPerson } from '../Person/@types';
import Contact from '../../../screens/Contact';
import ContactButton from '../../ContactButton';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES>, RouteComponentProps {
    id: string
}

const selectSelectedCompany = createSelector<IAppState, IAppState, Pick<IAppState, 'selectedCompany'>>(
    state => state,
    ({ selectedCompany }) => ({ selectedCompany })
)

const CompanyDetails: FC<IProps> = ({ id, classes }) => {

    const dispatch = useDispatch();

    const { selectedCompany } = useSelector(selectSelectedCompany);

    const getData = useCallback(async () => {
        dispatch({ type: CLEAR_SELECTED_COMPANY });
        const data = await Product.fetchCompanyDetails(id).catch(error => { throw error });
        dispatch({ type: COMPANY_RECEIVED, data });
    }, [dispatch, id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if ((selectedCompany && selectedCompany.airtableId !== id) || !selectedCompany) getData(); }, [id, getData]);

    const thumbnail = selectedCompany && (selectedCompany.logo || []).length > 0 && selectedCompany.logo[0].thumbnails.large.url;

    let therapaticSectors: Array<{ airtableId: string, name: string }> = [];

    let partners: IField[] = (selectedCompany && parseAggregatedArray<IField>(selectedCompany.partners || [])) || [];

    selectedCompany && (selectedCompany.sectors || []).map(item => {
        return item.therapeuticSectors.map(ts => {
            return therapaticSectors.push(ts.therapeuticSector);
        })
    });

    const handleTSClick = (ts: IField) => applyFilter('therapeuticSector', ts.airtableId);
    const handlePartnerClick = (partner: IField) => applyFilter('partner', [partner.airtableId]);
    const handleMKPClick = (mkp: IPerson) => applyFilter('managementKeyPeople', mkp.airtableId);
    const handleInvestorClick = (investor: IField) => applyFilter('investor', [investor.airtableId]);

    return (
        <div>
            {
                selectedCompany
                    ?
                    (
                        <div>
                            {thumbnail && <img src={thumbnail} alt={selectedCompany.shortName} className={classes.img} />}
                            <p className={classes.title} >{selectedCompany.shortName || selectedCompany.name || ''}</p>
                            <Divider ySpacing={9} />
                            <Description description={(selectedCompany.description || '')} />
                            <ChipsList title={'Therapeutic Sectors'} onClick={handleTSClick} items={therapaticSectors || []} collapsedChipCount={5} />
                            {partners.length > 0 ? <ChipsList title={'Partners'} onClick={handlePartnerClick} items={partners} collapsedChipCount={5} /> : null}
                            {(selectedCompany.managementKeyPeople || []).length > 0 ? <ChipsList onClick={handleMKPClick} title={'Management & Key People'} items={selectedCompany.managementKeyPeople || []} collapsedChipCount={5} /> : null}
                            {(selectedCompany.investors || []).length > 0 ? <ChipsList title={'Investors'} items={selectedCompany.investors || []} collapsedChipCount={5} onClick={handleInvestorClick} /> : null}
                            {(selectedCompany.analysts || []).length > 0 ? <CoverageBadge analysts={selectedCompany.analysts} /> : null}
                            <ContactButton />
                        </div>
                    )
                    :
                    <p>Loading</p>
            }
        </div>
    )
}



const STYLES = (theme: IAppTheme) => ({
    img: {
        width: 70
    },
    title: {
        fontSize: 14,
        lineHeight: '20px'
    },

})

export default withRouter(injectSheet(STYLES)(CompanyDetails))