import axios from 'axios';
import { IProduct } from './product';

export interface IField {
    airtableId: string
    name: string
    total?: number
}


export interface ILandscape {
    therapeuticSectors: Array<{
        therapeuticSector: {
            metadata: Array<any>
            airtableId: string
            name: string
        }
    }>
    total: number
    _id: IField
}

export interface IStage extends IField {
    metadata: Array<any>
    order: number
}

export class Landscape {

    static async fetchLandscapes(): Promise<ILandscape[] | null> {
        try {
            const res = await axios.request({ url: '/products/landscape' });
            return res.data;
        } catch (error) {
            return []
        }

    }

    static async fetchStages(): Promise<IStage[] | null> {
        try {
            const res = await axios.request({ url: '/products/stages' });
            return res.data;
        } catch (error) {
            return []
        }
    }

    static filterProductsById(landscapeIds: Array<string>, products: IProduct[]): IProduct[] {
        if (landscapeIds && landscapeIds.length !== 0)
            return products.filter(product => landscapeIds.indexOf(product.sector.airtableId) > -1)
        else
            return products

    }
}