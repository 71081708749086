export const validator = {
    minLength(value: string, len: number = 10) {
        return value.length >= len;
    },
    containsNumber(value: string) {
        return /\d/.test(value)
    },
    containsCaps(value: string) {
        return /[A-Z]/.test(value)
    },
    containsSpecialChar(value: string) {
        return /[!@#$%&*]/.test(value)
    }
}


