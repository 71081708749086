import { IUser } from "../reducer/@types";
import Axios from 'axios';
import config from "../resources/Config";

const USER_BASE_URL = config.get('AUTH_URL');

export class UserService {
    static acceptPolicy = async (user: IUser) => {
        UserService.setUserBaseUrl();
        const { identities } = user;
        if (identities.length === 0) return;
        const url = identities[0].provider + '|' + identities[0].user_id
        try {
            await Axios.request({
                url,
                method: 'PATCH',
                data: {
                    user_metadata: {
                        policy_accepted: true
                    }
                }
            })

            UserService.resetBaseUser();
            return;
        } catch (error) {
            UserService.resetBaseUser();
            throw error;
        }
    }


    static setUserBaseUrl = () => Axios.defaults.baseURL = USER_BASE_URL;
    static resetBaseUser = () => Axios.defaults.baseURL = config.get('API_URL');
}