import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import { useSpring, animated } from 'react-spring';
import AppTheme from '../../resources/theme';
import clsx from 'clsx';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> {
    title: string | JSX.Element
    id?: string
    onToggle?: (id: string) => void
    active?: boolean
    invertedColor?: boolean
    inactiveColor?: string
    inactiveTextColor?: string
    activeColor?: string
    activeTextColor?: string
    maxWidth?: number
    multiSelect?: boolean,
    rootStyle?: string
}

const ChipToggle: React.FC<IProps> = (props) => {
    const { classes, rootStyle = {}, invertedColor = false, title, active = false, activeColor = 'white', inactiveColor = AppTheme.color.primaryLight, activeTextColor = '#104177', inactiveTextColor = 'white', maxWidth } = props
    const spring = useSpring({
        background: active ? (!invertedColor ? activeColor : inactiveColor) : (invertedColor ? activeColor : inactiveColor),
        color: active ? (!invertedColor ? activeTextColor : inactiveTextColor) : (invertedColor ? activeTextColor : inactiveTextColor),
        position: 'relative',

    })

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        typeof props.onToggle === 'function' && props.onToggle(props.id || '');
    }

    return (
        <animated.button onClick={handleClick} className={clsx(classes.root, rootStyle)} style={{ ...spring, ...{ maxWidth: maxWidth || '100%' } }}>
            <animated.p style={{ color: spring.color }} className={classes.title} >{title}</animated.p>
        </animated.button>
    )
}

const STYLES = (theme: any) => ({
    root: {
        padding: '4px 12px',
        boxSizing: 'border-box',
        borderRadius: 30,
        height: 28,
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:focus': {
            outline: 'none',
        },
    },
    title: {
        overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
    }
})

export default injectSheet(STYLES)(ChipToggle)