import React, { FC } from 'react';
import AppTheme, { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';
import { IProduct, COLOR_CONFIG } from '../../models/product';
import ChipToggle from '../ChipToggle';
import Divider from '../Sidebar/DetailComponents/Divider';
import { findShapeByLegendCategoryId } from '../../service/mappings';
import { applyFilter } from '../../reducer';
import Contact from '../../screens/Contact';
import { ILandscape } from '../../models/landscape';

// eslint-disable-next-line no-use-before-define
export interface ProductsBarPopperCardProps extends WithSheet<typeof STYLES> {
    products: IProduct[]
    sector?: ILandscape
    variant?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight'
    onClose?: () => void
    onClick?: (sectorId: string, rowId: string) => void
}

const ProductBarPopperCard: FC<ProductsBarPopperCardProps> = (props) => {

    const { classes, variant = 'bottomLeft', products, sector, onClose } = props;

    const positionStyleClass = classes[variant as keyof typeof STYLES];

    const handleClick = (sectorId: string) => () => {
        if (!props.onClick || products.length === 0) return;
        const rowId = products[0].therapeuticSectors[0] ? products[0].therapeuticSectors[0].airtableId : '';
        props.onClick(sectorId, rowId);
    }

    if (!sector || products.length === 0) return <div />


    return (
        <div className={classes.root} onClick={e => e.stopPropagation()} >
            <div className={clsx(classes.pointer, positionStyleClass)} />
            <div className={classes.cancelButton} onClick={() => onClose && onClose()} >
                <i className={'material-icons'} >close</i>
            </div>
            <div className={classes.header} >
                {sector._id.name}
            </div>
            <Divider size='full' ySpacing={8} />
            <div className={classes.body} >
                <a className={classes.productsLink} onClick={handleClick(sector._id.airtableId)}>
                    {products.length} Products
                </a>


            </div>
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        backgroundColor: 'white',
        zIndex: 1100,
        minWidth: 175,
        borderRadius: 7,
        position: 'relative',
        boxShadow: '0px 1px 3px rgba(0,0,0,0.16)',
        padding: '13px 0px'
    },
    cancelButton: {
        height: 24, width: 24,
        position: 'absolute',
        borderRadius: '100%',
        backgroundColor: '#104177',
        right: '-12px', top: '-12px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        padding: 2
    },
    pointer: {
        height: 20,
        width: 20,
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
    },
    bottomLeft: { right: 40, top: '-10px', },
    bottomRight: { left: 40, top: '-10px', },
    topLeft: { right: 40, bottom: '-10px', },
    topRight: { left: 40, bottom: '-10px', },
    header: {
        padding: '0px 13px',
        display: 'flex',
        alignItems: 'center'
    },
    img: { width: 70, marginRight: 12 },
    body: {
        padding: '0px 13px',
        '& > a': {
            marginBottom: 7,
            fontWeight: 500,
            fontSize: 12,
            color: 'grey'
        }
    },
    product: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '& > p': {
            fontSize: 12,
            fontWieght: 'normal',
            fontFamily: 'Avenir, Montserrat',
            marginLeft: 3
        },
    },
    productsLink: {
        cursor: 'pointer'
    },
    contactSection: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            textDecoration: 'underline',
            marginLeft: 8,
        }
    },
    partnerContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            fontSize: 12,
            fontWeight: 'normal',
            marginRight: 5
        }
    },
    chipHeading: {
        margin: '12px 0px'
    },
    comment: {
        fontWeight: 'normal'
    }
});

export default injectSheet(STYLES)(ProductBarPopperCard);