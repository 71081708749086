import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import ChipToggle from '../ChipToggle';
import { useTrail, animated } from 'react-spring';
import { parseQs, updateUrl, Device } from '../../resources/utils';
import qs from 'qs';
import { SET_LOADING } from '../../reducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../reducer/@types';
import { withRouter, RouteComponentProps } from 'react-router';
import { OutsideClick } from '../OutsideClick';
import { useWindowSize } from '../../Components/WindowSize';
import AppTheme from '../../resources/theme';
import { updateSettings } from '../../reducer/redux-actions';
import { Product } from '../../models/product';
import { LANDSCAPE_TITLE_MAPPING } from '../../ResponsiveMapping/LandscapeTitles';


// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES>, RouteComponentProps { }


const MIN_WINDOW_WIDTH = 1500;


const Sectors: React.FC<IProps> = (props) => {
    const LIMIT = Device.isTablet ? 4 : 2
    const state = useSelector<IAppState, Pick<IAppState, 'landscapes'>>(({ landscapes }) => ({ landscapes }));
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const { classes } = props
    const queryState = parseQs();
    const checkedArray = queryState.sectorIds || []
    const windowSize = useWindowSize();
    const overflowing = windowSize[0] <= MIN_WINDOW_WIDTH;




    const onToggleChip = (id: string) => {
        const queryState = parseQs();
        dispatch({
            type: SET_LOADING,
            data: true
        })
        const _checkedArray = [...(queryState.sectorIds || [])];
        const index = _checkedArray.indexOf(id);
        if (index > -1) {
            // Select all if only 1 sector remained.
            if (_checkedArray.length === 1) {
                queryState.sectorIds = (landscapes || []).map(sector => sector._id.airtableId);
            } else {
                _checkedArray.splice(index, 1)
                queryState.sectorIds = [..._checkedArray]
            }

        } else {
            queryState.sectorIds = [..._checkedArray, id];
        }
        const route = '/view?' + qs.stringify(queryState);
        window.history.pushState({}, '', route);
    }

    const trail = useTrail(state.landscapes ? state.landscapes.length : 0, {
        opacity: 1,
        left: 0,
        from: {
            opacity: 0,
            left: 50
        }
    });

    React.useEffect(() => {
        if ((queryState.sectorIds || []).length === 0) {
            queryState.sectorIds = (landscapes || []).map(sector => sector._id.airtableId);
            updateUrl(queryState);
        }
    }, [queryState])

    const landscapes = state.landscapes;


    const getLandscapeTitle = (id: string, fallback: string) => {
        if (Device.isTablet)
            return LANDSCAPE_TITLE_MAPPING[id].tablet || fallback
        return LANDSCAPE_TITLE_MAPPING[id].desktop || fallback
    }



    const handleToggelExpand = () => {
        setExpanded(!expanded);
    }


    return (
        <OutsideClick onClickOut={() => setExpanded(false)}>
            <div className={classes.root}>

                {
                    !overflowing && landscapes && trail.map((style, index) => (
                        <animated.div style={{ position: 'relative', ...style }} key={landscapes[index]._id.airtableId}>
                            <ChipToggle onToggle={onToggleChip} id={landscapes[index]._id.airtableId} title={getLandscapeTitle(landscapes[index]._id.airtableId, landscapes[index]._id.name)} active={checkedArray.indexOf(landscapes[index]._id.airtableId) > -1} />
                        </animated.div>
                    ))
                }



                { /* EXPAND toggle sectors list uses the below components */}
                {
                    overflowing && landscapes ?
                        trail.map((style, index) => index < LIMIT ? (
                            <animated.div style={{ position: 'relative', ...style }} key={landscapes[index]._id.airtableId}>
                                <ChipToggle onToggle={onToggleChip} id={landscapes[index]._id.airtableId} title={getLandscapeTitle(landscapes[index]._id.airtableId, landscapes[index]._id.name)} active={checkedArray.indexOf(landscapes[index]._id.airtableId) > -1} />
                            </animated.div>
                        ) : null) : null
                }
                {
                    overflowing ?
                        <div>
                            <ChipToggle rootStyle={classes.chipToggleButton} onToggle={handleToggelExpand} title={(
                                <i className="material-icons">{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
                            )} active={expanded} />
                        </div> : null
                }
                {
                    expanded && landscapes ?
                        <div className={classes.expandedContainer}>
                            {
                                trail.map((style, index) => index >= LIMIT ? (
                                    <animated.div style={{ position: 'relative', ...style }} key={landscapes[index]._id.airtableId}>
                                        <ChipToggle invertedColor={true} onToggle={onToggleChip} id={landscapes[index]._id.airtableId} title={getLandscapeTitle(landscapes[index]._id.airtableId, landscapes[index]._id.name)} active={checkedArray.indexOf(landscapes[index]._id.airtableId) > -1} />
                                    </animated.div>
                                ) : null)

                            }
                        </div>
                        : null
                }
            </div>
        </OutsideClick>
    )
}


const STYLES = (theme: any) => ({
    root: {
        position: 'relative',
        display: 'flex',
        padding: 20,
        '& > div': {
            margin: '0px 4px'
        }
    },
    chipToggleButton: {
        height: 27,
        padding: '3px 6px',
        width: 40,
        border: `1px solid ${AppTheme.color.lightBlue}`,
        '& .material-icons': {
            position: 'relative',
            top: -2
        }
    },
    expandedContainer: {
        zIndex: 1,
        right: Device.isTablet ? -5 : -45,
        background: 'white',
        boxSizing: 'border-box',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `2px 2px 9px 0px rgba(176,176,176, 0.5)`,
        top: 65,
        padding: 20,
        borderRadius: 8,
        '& > div': {
            margin: '4px 0px'
        },
        '&::before': {
            content: "' '",
            width: 27,
            height: Device.isTablet ? 20 : 27,

            zIndex: 1,
            position: 'absolute',
            top: -4,
            right: '45%',
            background: 'white',
            transform: 'rotate(45deg)'

        }
    }
})

export default injectSheet(STYLES)(withRouter(Sectors));