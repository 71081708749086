import * as React from 'react';
import injectSheet, { WithSheet } from 'react-jss';
import * as H from 'history';

import { IAppTheme } from '../../resources/theme';
import Sidebar from '../../Components/Sidebar';
import Table from '../../Components/Table';
import { parseQs, isSame, updateUrl, Device } from '../../resources/utils';
import { SET_ALL_PRODUCTS, SET_SELECTED_SECTORS, TOGGLE_DRAWER } from '../../reducer/actions';
import AppHeader from '../../Components/AppHeader';
import { ProductService } from '../../service/productsService';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../reducer/@types';
import { RouteComponentProps } from 'react-router';
import Drawer from '../../Components/Drawer/Drawer';
import IconButton from '../../Components/Buttons/IconButton';
import ReleaseInfo from '../../Components/Sidebar/ReleaseInfo';
import IdleTimer from 'react-idle-timer';
import ModusBrandCard from '../../Components/Cards/ModusBrandCard';
import clsx from 'clsx';
import Snackbar from '../../Components/Snackbar';
import { selectSectors } from '../../reducer/redux-actions';
import ContactDialog from '../Contact/ContactDialog';
import Axios from 'axios';




export let HISTORY: H.History;

// eslint-disable-next-line no-use-before-define
const HomeScreen: React.FC<WithSheet<typeof STYLES> & RouteComponentProps> = (props) => {

    const { classes, history } = props;
    HISTORY = history;

    const state = useSelector<IAppState, Pick<IAppState, | 'landscapes' | 'appInitialized' | 'selectedSectors' | 'showDrawer' | 'isLoggedIn'>>(({ landscapes, appInitialized, selectedSectors, showDrawer, isLoggedIn }) => ({ landscapes, appInitialized, selectedSectors, showDrawer, isLoggedIn }));
    const dispatch = useDispatch();

    let queryState = parseQs();



    const isSidebarOpen = queryState.sidebar.open;

    let DRAWER_WIDTH = 34;
    if (queryState.sidebar.open) {
        DRAWER_WIDTH = 234;
    }
    else
        DRAWER_WIDTH = 34;

    React.useEffect(() => {
        if (state.appInitialized && state.landscapes) {
            const landscapeIds = state.landscapes.map(landscape => landscape._id.airtableId);
            const sectorIds = queryState.sectorIds ? queryState.sectorIds : landscapeIds;

            if (!isSame(sectorIds, state.selectedSectors)) {
                dispatch(selectSectors(sectorIds, { sectorIds, eventName: 'TOGGLE LANDSCAPE SELECTION' }))
                ProductService.getProducts(sectorIds).then(
                    ({ filteredProducts, allProducts }) => {
                        dispatch({ type: SET_ALL_PRODUCTS, data: { filteredProducts, allProducts } })

                    }
                )

            }

        }


    }, [state, queryState.sectorIds, dispatch]);

    const onIdle = () => {
        props.history.push('/');
    }

    const hideDrawer = () => {
        dispatch({ type: TOGGLE_DRAWER, data: false });
        updateUrl({ ...queryState, showReleaseNotes: '0' });
    }

    React.useEffect(() => {
        if ((queryState.showReleaseNotes === '0' && state.showDrawer) || (queryState.showReleaseNotes === '1' && !state.showDrawer)) {
            dispatch({ type: TOGGLE_DRAWER, data: queryState.showReleaseNotes === '0' ? false : true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryState.showReleaseNotes, state.showDrawer]);

    return (
        <div className={classes.root} >
            <div className={classes.header}>
                <AppHeader />
            </div>

            <IdleTimer
                // timeout={6000}
                timeout={1000 * 60 * 2}
                onIdle={onIdle}
            />
            <div className={clsx(classes.container)} id={'table-container'} style={{
                width: Device.isTablet ? `calc(100vw - 30px)` : `calc(100vw - ${DRAWER_WIDTH}px)`
            }}>
                <Table />
            </div>
            <div className={classes.sidebar} style={{
                width: DRAWER_WIDTH
            }}>
                <Sidebar isOpen={isSidebarOpen} />
            </div>
            <Drawer open={state.showDrawer} position={'right'} >
                <div className={classes.drawer} >
                    <IconButton onClick={hideDrawer} ><i className={'material-icons'} >clear</i></IconButton>
                    <ReleaseInfo />
                </div>
            </Drawer>

            <div className={classes.modusLogoContainer} ><ModusBrandCard /></div>
            <ContactDialog />
        </div >
    )
}

const APP_HEADER_HEIGHT = 60;

const STYLES = (theme: IAppTheme) => ({
    root: {

    },
    modusLogoContainer: {
        position: 'fixed',
        left: '0px',
        bottom: '0px'
    },
    header: {
        height: APP_HEADER_HEIGHT,
        position: 'fixed',
        zIndex: 2,
        // zIndex: 100,
        top: 0,
        left: 0,
        right: 0,
    },
    container: {
        overflowY: 'auto',
        background: 'white',
        position: 'fixed',
        top: APP_HEADER_HEIGHT,
        bottom: 0,
        left: 0,
        transition: '300ms ease-out',
        margin: '0px 6px'
    },
    sidebar: {
        transition: '300ms ease-out',
        position: 'fixed',
        background: 'white',
        top: APP_HEADER_HEIGHT,
        bottom: 0,
        right: 0,
    },
    drawer: {
        width: 217,
        position: 'relative',
        padding: '85px 20px 0px',
        boxSizing: 'border-box',
        '& > button': {
            position: 'absolute',
            top: 0, right: 0
        }
    }
});


export default injectSheet(STYLES)(HomeScreen)