import React, { FC, useEffect, useCallback } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { IAppState } from '../../../reducer/@types';
import { CLEAR_SELECTED_PERSON_DETAILS, PERSON_DETAILS_RECEIVED } from '../../../reducer/actions';
import { Product } from '../../../models/product';
import Divider from '../DetailComponents/Divider';
import ChipsList from '../DetailComponents/ChipsList';
import Description from '../DetailComponents/Description';
import { parseAggregatedArray } from '../../../resources/utils';
import { IField } from '../../../models/therapeuticSector';
import { applyFilter } from '../../../reducer';
import Contact from '../../../screens/Contact';
import ContactButton from '../../ContactButton';


// const DEFAULT_IMAGE = 'https://www.drupal.org/files/issues/default-avatar.png'

// eslint-disable-next-line no-use-before-define
interface PersonProps extends WithSheet<typeof STYLES> {
    id: string
    type: 'analyst' | 'managementKeyPeople'
}

const selectSelectedPerson = createSelector<IAppState, IAppState, Pick<IAppState, 'selectedPerson'>>(
    state => state,
    ({ selectedPerson }) => ({ selectedPerson })
)

const Person: FC<PersonProps> = ({ classes, id, type }) => {

    const { selectedPerson } = useSelector(selectSelectedPerson);
    const dispatch = useDispatch();

    const getData = useCallback(async () => {
        dispatch({ type: CLEAR_SELECTED_PERSON_DETAILS });
        const data = type === 'analyst' ? await Product.fetchAnalystDetails(id) : await Product.fetchManagementKeyPeopleDetails(id);
        dispatch({ type: PERSON_DETAILS_RECEIVED, data });
    }, [dispatch, id, type]);

    useEffect(() => { if ((selectedPerson && selectedPerson.airtableId !== id) || !selectedPerson) getData(); }, [id, getData, selectedPerson]);

    const therapeuticSectors = useCallback(() => selectedPerson ? parseAggregatedArray<IField>(selectedPerson.therapeuticSectors) : [], [selectedPerson])();
    const companies = useCallback(() => selectedPerson ? parseAggregatedArray<IField>(selectedPerson.companies) : [], [selectedPerson])();
    const image: string = selectedPerson ? ((selectedPerson.picture && selectedPerson.picture.length > 0) ? (((selectedPerson.picture[0].thumbnails || {}).large || {}).url || '') : '') : ''
    const bio = useCallback(() => { return selectedPerson && selectedPerson.bio }, [selectedPerson])() || '';
    const title = useCallback(() => { return selectedPerson && selectedPerson.title }, [selectedPerson])() || '';

    const handleTSClick = (ts: IField) => applyFilter('therapeuticSector', ts.airtableId);
    const handleCompanyClick = (company: IField) => applyFilter('company', company.airtableId);

    return (
        <div>
            {
                selectedPerson
                    ?
                    (
                        <>
                            <div className={classes.userHead} >
                                {image && <img src={image} alt={selectedPerson.name} />}
                                <p>{selectedPerson.name || ''}</p>
                            </div>
                            <Divider size='full' />
                            {title && <p className={classes.title} >{title}</p>}
                            <Divider size='full' />
                            {therapeuticSectors ? <ChipsList collapsedChipCount={5} title={'Therapeutic Sectors '} onClick={handleTSClick} items={therapeuticSectors} /> : null}
                            {bio && <Description title={'Biography'} description={bio} />}
                            <Divider size={'full'} />
                            {companies ? <ChipsList collapsedChipCount={5} title={'Companies'} onClick={handleCompanyClick} items={companies} /> : null}
                            <ContactButton />
                        </>
                    )
                    :
                    <p>Loading</p>
            }
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    userHead: {
        display: 'flex',
        marginBottom: 14,
        '& > img': {
            height: 48,
            width: 48,
            borderRadius: '100%',
            marginRight: 10,
            // boxSizing: 'border-box'
        },
        '& > p': {
            fontSize: 14,
            flex: 1
        },
    },
    title: {
        fontSize: 10,
        fontWeight: 'normal',
        margin: '7px 0px'
    },

});

export default injectSheet(STYLES)(Person);