import { createMiddleware, EventsMap } from 'redux-beacon';
import Segment from '@redux-beacon/segment';
import { LOCATION_CHANGED } from '../EventHandler/events';
import {
    pageViewTracker,
    analyticsSetUser,
    analyticsRemoveUser,
    eventTracker
} from './trackers';
import {
    ANALYTICS_USER_EVENT,
    ANALYTICS_IDENTIFY_USER, ANALYTICS_RESET_USER
} from './analyticActions';


const segment = Segment();


// Match the event definition to a Redux action:
const eventsMap = {
    [LOCATION_CHANGED]: pageViewTracker,
    [ANALYTICS_USER_EVENT]: eventTracker,
    [ANALYTICS_IDENTIFY_USER]: analyticsSetUser,
    [ANALYTICS_RESET_USER]: analyticsRemoveUser
};

export type AnalyticsEvent = keyof typeof eventsMap;

// Create the middleware
export default createMiddleware(eventsMap, segment);