import * as React from 'react';
import { IAppTheme } from '../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import clsx from 'clsx';

// eslint-disable-next-line no-use-before-define
export interface TabButtonProps extends WithSheet<typeof STYLES> {
    isSelected?: boolean
    onClick?: () => void
}

const TabButton: React.FC<TabButtonProps> = React.memo((props) => {

    const { classes, isSelected = false, onClick } = props;

    return (
        <div className={clsx(classes.root, isSelected ? classes.activeText : {})} onClick={onClick} >
            {props.children}
        </div>
    )
})

const STYLES = (theme: IAppTheme) => ({
    root: {
        fontSize: 8,
        color: theme.color.primaryLight,
        display: 'flex',
        alignItems: 'center',
        opacity: 0.30,
        cursor: 'pointer'
    },
    activeText: {
        opacity: 1,
        borderBottom: `3px Solid ${theme.color.brandGold}`,
    }
})

export default injectSheet(STYLES)(TabButton);