import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import qs from 'qs';
import Axios from 'axios';
import AppTheme from '../../resources/theme';
import { useHistory } from 'react-router-dom'
import { Auth } from '../../models/auth';

interface IProps extends WithSheet<typeof STYLES> { }

const ForgotPassword: React.FC<IProps> = (props) => {

    const { classes } = props;
    const [password, setPassword] = React.useState('');
    const history = useHistory();
    const [repeatPass, setRepeatPass] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const query: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const ticket = query.ticket;

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!isFormValid()) return;
        setLoading(true)
        // RESET PASSWORD
        try {
            await Auth.resetPassword(ticket, password);
            history.push('/');
            resetFields();
            setLoading(false);
            return;

        } catch (error) {
            const errorMsg = error.response ? error.response.data ? error.response.data.error ? error.response.data.error.message : '' : '' : ''
            setError(errorMsg)
        }
        setLoading(false)

    }


    const isFormValid = () => {
        if (!password) {
            setError('Password cannot be empty')
            return false;
        }
        if (password && password !== repeatPass) return false;
        if (!ticket) return false;
        if (error) return false;
        return true
    }

    const resetFields = () => {
        setError('');
        setPassword('');
        setRepeatPass('');
    }

    const handleRepeatPassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRepeatPass(e.target.value);
    }
    const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!password)
            setError('Password cannot be empty')
        else
            setError('')
        setPassword(e.target.value);
    }


    const handlePassBlur = () => {
        if (!password)
            setError('Password cannot be empty')
        else
            setError('')
    }

    return (
        <div className={classes.root} >
            <form onSubmit={handleSubmit} className={classes.formCard}>
                <h4>Reset Password</h4>
                <div>
                    <input className={classes.passwordInput} onBlur={handlePassBlur} type="password" value={password} placeholder="Enter new password" onChange={handlePassChange} />
                    <p className={classes.error}>{error}</p>
                </div>
                <div>
                    <input className={classes.passwordInput} type="password" value={repeatPass} placeholder="Repeat Password" onChange={handleRepeatPassChange} />
                    <p className={classes.error}>{password !== repeatPass ? 'Password does not match' : ''}</p>
                </div>
                <div className={classes.submitBtn} >
                    <button type="submit">{loading ? 'Loading...' : 'RESET'}</button>
                </div>
            </form>
        </div>
    )
}

const STYLES = (theme: any) => ({
    root: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        // background: theme.color.background,
        background: 'white',
        justifyContent: 'center',
        '& > div': {
            marginRight: 45
        }
    },
    error: {
        color: 'red'
    },
    formCard: {
        padding: 40,
        background: 'white',
        boxSizing: 'border-box',
        maxWidth: 354,
        boxShadow: `0px 2px 18px rgba(0,0,0,0.2)`,
        '& h4': {
            fontFamily: AppTheme.font.family[0],
            fontSize: 22,
            margin: '10px 0px',
            fontWeight: 400
        },
        '& hr': {
            borderColor: 'rgba(0,0,0,0.1)'
        },
        '& p, & a': {
            fontWeight: 400,
            fontSize: 12,
        },
        '& h5': {
            margin: '20px 0px 4px 0px'
        }
    },
    passwordInput: {
        height: 40,
        minWidth: 250,
        fontWeight: 400,
        border: '1px solid #ccc',
        padding: '0px 10px',
        outline: 'none',
        fontSize: 14,
        margin: '5px 0px',
        '&:focus': {
            borderColor: '#192354'
        }
    },
    submitBtn: {
        marginTop: 30,
        width: '100%',
        textAlign: 'center',
        '& > button': {
            height: 50,
            fontSize: 18,
            fontWeight: 400,
            fontFamily: AppTheme.font.family[0],
            width: '100%',
            background: '#192354',
            color: '#fff',
            outline: 'none',
            cursor: 'pointer'
        }
    },
})

export default injectSheet(STYLES)(ForgotPassword)