// import { Circle, Square, SquareRotated, Triangle, DefaultShape, Plus } from '../Components/ProductPill';
import React from 'react';

interface IShapeProps {
    isActive?: boolean
    color?: string
}
export const Square: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div
            style={{ height: 10, width: 10, ...(!isActive ? { border: `3px Solid ${color}`, height: 4, width: 4 } : { backgroundColor: color }) }}
        />
    )
})

export const SquareRotated: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div
            style={{ height: 10, width: 10, transform: 'rotate(45deg)', ...(!isActive ? { border: `3px Solid ${color}`, height: 4, width: 4 } : { backgroundColor: color }) }}
        />
    )
})


export const Circle: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div
            style={{ height: 10, width: 10, borderRadius: 5, ...(!isActive ? { border: `3px Solid ${color}`, height: 4, width: 4 } : { backgroundColor: color }) }}
        />
    )
});

export const DefaultShape: React.FC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div style={{ width: 10 }} >
            <div
                style={{ height: 10, width: 3, ...(isActive ? { backgroundColor: color } : { border: `2px Solid ${color}`, height: 6 }) }}
            />
        </div>
    )
});

export const Plus: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" >
            {
                isActive
                    ?
                    <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" fill={color} />
                    :
                    <path d="M13 2v9h9v2h-9v9h-2v-9h-9v-2h9v-9h2zm2-2h-6v9h-9v6h9v9h6v-9h9v-6h-9v-9z" fill={color} />
            }
        </svg>
    )
})

export const Triangle: React.SFC<IShapeProps> = React.memo((props) => {
    const { isActive, color = '#cfc8a7' } = props;
    return (
        <div style={{
            borderBottom: `12px Solid ${color}`,
            borderLeft: `6px Solid transparent`,
            borderRight: `6px Solid transparent`,
            height: 0, width: 0,
            position: 'relative'
        }} >
            <div style={{
                borderBottom: `4px Solid ${!isActive ? 'white' : color}`,
                borderLeft: `2px Solid transparent`,
                borderRight: `2px Solid transparent`,
                height: 0, width: 0,
                position: 'absolute',
                top: '5px',
                left: '-2px'
            }} />
        </div>
    )
})

export const SectorPillColor = {
    "gene_therapy_gene_editing": "#8bc53f",
    "rna": "#9e1f63",
    "microbiome": "#f6921e",
    "digital_therapeutics": "#25a9e0",
    "cell_therapy": "#9c45db"
}

export const SelectShape = {

    // Gene Therapy & Gene Editing
    "gene_therapy_in_vivo": Circle,
    "gene_therapy_ex_vivo": Square,
    "gene_therapy_it": Square,
    "gene_editing_in_vivo": Triangle,
    "gene_editing_ex_vivo": SquareRotated,


    //rna
    "mi_rna": Circle,
    "si_rna": Square,
    "aso": Triangle,
    "sa_rna": SquareRotated,
    "m_rna": Plus,

    //Microbiome
    "live_biotherapeutic_product_lbp": Circle,
    "functional_modulator_fm": Square,
    "fm_lbp": Triangle,

    //Digital Therapeutics
    "d_tx": Circle,
    "pd_tx": Square,
    "other": Triangle,

    "tcr_t": Plus,
    "allogeneic_car_t": SquareRotated,
    "non_genetically_engineered": Square,
    "autologous_car_t": Circle
}

export const GTGE_Order: Record<string, number> = {
    "gene_therapy_in_vivo": 1,
    "gene_therapy_it": 5,
    "gene_editing_ex_vivo": 4,
    "gene_therapy_ex_vivo": 2,
    "gene_editing_in_vivo": 3,
};

export const RNA_Order: Record<string, number> = {
    "mi_rna": 1,
    "si_rna": 2,
    "aso": 3,
    "sa_rna": 4,
    "m_rna": 5,
};

export const MicroBiome_Order: Record<string, number> = {
    "live_biotherapeutic_product_lbp": 1,
    "functional_modulator_fm": 2,
    "fm_lbp": 3,
};

export const DT_Order: Record<string, number> = {
    "d_tx": 1,
    "pd_tx": 2,
    "other": 3,
}

export const CT_Order: Record<string, number> = {
    "tcr_t": 5,
    "allogeneic_car_t": 4,
    "other": 3,
    "non_genetically_engineered": 2,
    "autologous_car_t": 1
}

export const LandscapesLegendsOrder: Record<string, Record<string, number>> = {
    'gene_therapy_gene_editing': GTGE_Order,
    'rna': RNA_Order,
    'microbiome': MicroBiome_Order,
    'digital_therapeutics': DT_Order,
    "cell_therapy": CT_Order
}

export const SVG_ID_to_airtabelId = {
    'Digital-Therapeutics': ['sectorIds', ["digital_therapeutics"]],
    'Gene-Therapy-and-Gen': ['sectorIds', ["gene_therapy_gene_editing"]],
    'Microbiome': ['sectorIds', ["microbiome"]],
    'RNA': ['sectorIds', ["rna"]],
    'Cell-Therapy': ['sectorIds', ['cell_therapy']],

    // 'Digital-Therapeutics': ['sectorIds', ["digital_therapeutics"]],
    // 'Gene-Therapy-and-Gene-Editing': ['sectorIds', ["gene_therapy_gene_editing"]],
    // 'Micro-biome': ['sectorIds', ["microbiome"]],
    // 'R-N-A': ['sectorIds', ["rna"]],

    'Gastrointestinal': ['rowId', "recpg3R2e0E5YnS9B"], //"Gastrointestinal"
    'Respiratory_disease': ['rowId', "rec4AwHKafvjP3EZr"], //"Respiratory disease"
    'CNS': ['rowId', "recwX5vIbx6LPhcKq"], //"CNS"
    'Oncology': ['rowId', "recr1bJSktKDEJ9u5"], //"Oncology"
    'Cardiovascular_and_metabolic_disease': ['rowId', "rechrFIZAT5VWZndV"], //"Cardiovascular and metabolic disease"
    'Infectious_Diseases': ['rowId', "recmBvHd5RXNVBYH7"], //"Infectious disease"
    'Other_Diseases': ['rowId', "recY9X6h6s1UWxexi"], //"Other diseases"
    'Hematology': ['rowId', "recC8PzQ9v2wTmR5f"], //"Hematology"
    'Autoimmune_allergic_disease': ['rowId', "recNiYN02gj98xGpn"], //"Autoimmune/allergic disease"
    'Ophthalmology': ['rowId', "recuSrbOzEge8w43S"], //"Ophthalmology"
    'Dermatology': ['rowId', "rec6XKtd9wflORoOp"], //"Dermatology"
    'Muscle': ['rowId', "rectxPXI0rQ0FZXsz"], //"Muscle"

    'Oval-Copy-29': ['rowId', "recJmQRX4H6N620el"], //"Gastrointestinal"
    'Oval-Copy-30': ['rowId', "rec4AwHKafvjP3EZr"], //"Respiratory disease"
    'Oval-Copy-33': ['rowId', "receCfPPtTgbw5fdr"], //"receCfPPtTgbw5fdr"
    'Oval-Copy-32': ['rowId', "recr1bJSktKDEJ9u5"], //"Oncology"
    'Oval-Copy-31': ['rowId', "rechrFIZAT5VWZndV"], //"Cardiovascular and metabolic disease"
    'Oval-Copy-42': ['rowId', "recmBvHd5RXNVBYH7"], //"Infectious disease"
    'Oval-Copy-41': ['rowId', "recY9X6h6s1UWxexi"], //"Other diseases"
    'Oval-Copy-25': ['rowId', "recG2z8ZPcEA20Ceh"], //"Hematology"
    'Oval-Copy-22': ['rowId', "recNiYN02gj98xGpn"], //"Autoimmune/allergic disease"
    'Oval-Copy-23': ['rowId', "recuSrbOzEge8w43S"], //"Ophthalmology"
    'Oval-Copy-24': ['rowId', "rec6XKtd9wflORoOp"], //"Dermatology"
    'Oval-Copy-26': ['rowId', "rectxPXI0rQ0FZXsz"], //"Muscle"
}



export const findShapeByLegendCategoryId = (id: string) => id in SelectShape ? SelectShape[id as keyof typeof SelectShape] : DefaultShape;