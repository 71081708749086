import React, { FC } from 'react';
import { Circle, Square, SquareRotated, Triangle, Plus } from '../../ProductPill';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES> { }

const Signs: FC<IProps> = ({ classes }) => {
    return (
        <div>
            <div className={classes.partneredContainer} >
                <Circle isActive />
                <Square isActive />
                <Triangle isActive />
                <SquareRotated isActive />
                <Plus isActive />
                <p className={classes.heading} >Not Partnered</p>
            </div>
            <div className={classes.partneredContainer} >
                <Circle />
                <Square />
                <Triangle />
                <SquareRotated />
                <Plus />
                <p className={classes.heading} >Partnered</p>
            </div>
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    partneredContainer: {
        display: 'flex',
        marginBottom: 10,
        '& > *': {
            marginRight: 10
        }
    },
    heading: {
        fontWeight: 'normal',
        lineHeight: '12px',
        fontSize: 10
    }
})

export default injectSheet(STYLES)(Signs);