import React, { FC, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../reducer/@types';
import { createSelector } from 'reselect';
import { Product } from '../../../models/product';
import { COMPANIES_RECEIVED } from '../../../reducer/actions';
import ChipToggle from '../../ChipToggle';
import AppTheme, { IAppTheme } from '../../../resources/theme';
import { applyFilter } from '../../../reducer';
import injectSheet, { WithSheet } from 'react-jss';
import { groupByFirstLetter } from '../../../resources/utils';
import clsx from 'clsx';
import AlphabetsButtonBar from '../../Buttons/AlphabetsButtonBar';
import SidebarChipItem from '../../SidebarChipItem';
import Contact from '../../../screens/Contact';
import ContactButton from '../../ContactButton';
import Delay from '../../Delay';

type IStateProps = Pick<IAppState, 'companies' | 'selectedSectors'>

// eslint-disable-next-line no-use-before-define
interface CompaniesProps extends WithSheet<typeof STYLES> {
    selectedCompany?: string
}

const selectComapnies = createSelector<IAppState, IAppState, IStateProps>(
    state => state,
    state => ({ companies: state.companies, selectedSectors: state.selectedSectors })
)

const Companies: FC<CompaniesProps> = (props) => {

    const { companies, selectedSectors = [] } = useSelector(selectComapnies);

    const { selectedCompany, classes } = props;

    const dispatch = useDispatch();

    const getData = useCallback(async (filter?: any) => {
        const data = await Product.fetchCompanies(filter).catch(error => { throw error });
        dispatch({ type: COMPANIES_RECEIVED, data });
    }, [dispatch]);

    useEffect(() => {
        let filter = selectedSectors.map(item => ({ 'sector.airtableId': item }));
        getData({ where: { "$or": filter } });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSectors]);

    const handleClick = (id: string) => applyFilter('company', id);

    let groupedData = groupByFirstLetter({ data: ((companies || [])) });

    const handleAlphabetClick = (alphabet: string) => {
        let el = window.document.getElementById(`company-alphabet-group-${alphabet}`);
        if (el) {
            el.scrollIntoView({ block: 'start' });
        }
    }

    const getChipText = (name: string, total: number) => {
        return <SidebarChipItem name={name} total={total} type="P" />
    }

    return (
        <div className={classes.root} >
            <div className={classes.chipsContainer} >
                {
                    (groupedData || []).map((grpItems, i) => {
                        const isLastItem = groupedData.length
                        return (
                            <div key={i} className={classes.groupItem} id={`company-alphabet-group-${grpItems.group.toUpperCase()}`} >
                                <p className={classes.stickyLabel}>{grpItems.group}</p>
                                {
                                    grpItems.items.map((company, i) => {
                                        const name = company.shortName || company.name
                                        const nameEl = getChipText(name, company.total);
                                        return (
                                            <div key={company.airtableId} className={clsx(classes.chipItem)} >
                                                <ChipToggle
                                                    title={nameEl || ''}
                                                    active={selectedCompany === company.airtableId}
                                                    onToggle={handleClick}
                                                    inactiveColor={'#ecf1fd'}
                                                    inactiveTextColor={AppTheme.color.primaryLight}
                                                    activeColor={'#fff4d5'}
                                                    activeTextColor={AppTheme.color.primaryLight}
                                                    maxWidth={150}
                                                    id={company.airtableId}
                                                />
                                            </div>
                                        )

                                    })
                                }
                            </div>
                        )
                    })
                }
                <Delay duration={600}>
                    <ContactButton />
                </Delay>
            </div>
            <AlphabetsButtonBar groups={groupedData.map(item => item.group)} handleAlphabetClick={handleAlphabetClick} />
        </div >
    )
}

const STYLES = (theme: IAppTheme) => ({
    root: {
        display: 'flex',
        overflowX: 'hidden',
    },
    chipsContainer: {
        flex: 1,
        height: 'calc(100vh - 150px)',
        overflowY: 'auto'
    },
    groupItem: {
        '& > p': {
            // marginLeft: 12
        },
        marginBottom: 8
    },
    stickyLabel: {
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 1,
    },

    chipItem: { marginBottom: 8 },
});

export default injectSheet(STYLES)(Companies);