import React, { FC, useState } from 'react';
import { IAppTheme } from '../../../resources/theme';
import injectSheet, { WithSheet } from 'react-jss';
import Button from '../../Buttons/Button';

// eslint-disable-next-line no-use-before-define
interface DescriptionProps extends WithSheet<typeof STYLES> {
    title?: string
    description: string
    initialChars?: number
}

const Description: FC<DescriptionProps> = ({ classes, title, description, initialChars = 230 }) => {

    const [fullDescriptionView, setFullDescriptionView] = useState(false);

    return (
        <div>
            <p className={classes.title} >{title || ''}</p>
            <p className={classes.description} >{description.slice(0, fullDescriptionView ? undefined : initialChars - 20) + (fullDescriptionView ? '' : '...')}</p>
            {
                (description || '').length > initialChars
                    ?
                    <Button onClick={() => { setFullDescriptionView(fullDescriptionView => !fullDescriptionView) }} >{fullDescriptionView ? `- Read Less` : `+ Read More`}</Button>
                    : null
            }
        </div>
    )
}

const STYLES = (theme: IAppTheme) => ({
    title: {
        fontSize: 10,
        lineHeight: '16px',
        marginBottom: 8
    },
    description: {
        fontSize: 10,
        lineHeight: '16px',
        fontWeight: 'normal',
        transition: '200ms'
    }
});

export default injectSheet(STYLES)(Description);