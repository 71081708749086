import clsx from 'clsx';
import qs from 'qs';
import * as React from 'react';
import ReactDOM from 'react-dom';
import injectSheet, { WithSheet } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getAnalyticsMetadata } from '../../middlewares/analytics';
import { TIndication } from '../../models/indication';
import { IStage } from '../../models/landscape';
import { IProduct, Product } from '../../models/product';
import { ITherapeuticSector } from '../../models/therapeuticSector';
import { expandColumn } from '../../reducer';
import { IAppState } from '../../reducer/@types';
import { EXPAND_COLUMN, EXPAND_TABLE_ROW, RESET_FILTERED_PRODUCTS, SELECT_PRODUCT } from '../../reducer/actions';
import { selectSectorBar, updateSettings } from '../../reducer/redux-actions';
import { IQueryState } from '../../resources/@types';
import { IAppTheme } from '../../resources/theme';
import { Device, isSameObject } from '../../resources/utils';
import { parseQs } from '.././../resources/utils';
import { isCommercialStage } from '../AppHeader/Filters/CommercialFilter';
import TableRow from './TableRow';
import ProductsNotFound from '../ProductsNotFound';

// eslint-disable-next-line no-use-before-define
interface IProps extends WithSheet<typeof STYLES>, RouteComponentProps {
    isSubcategory?: boolean,
    stages?: Array<IStage>

}

type IStoreState = Pick<IAppState, 'therapeuticSectors' | 'filter' | 'stages' | 'products' | 'selectedRow' | 'indications' | 'filteredProducts' | 'selectedProduct' | 'selectedSectorBar' | 'settings' | 'allProducts'>

const Table: React.FC<IProps> = (props) => {
    const { classes } = props;
    const [expanded, setExpanded] = React.useState(false);
    const state = useSelector<IAppState, IStoreState>(({ therapeuticSectors, stages, products, indications, selectedRow, filteredProducts, filter, selectedProduct, selectedSectorBar, settings, allProducts }) => ({ therapeuticSectors, stages, products, indications, selectedRow, filteredProducts, filter, selectedProduct, selectedSectorBar, settings, allProducts }));
    const { therapeuticSectors = [], stages = [] } = state;
    const queryState = parseQs();
    const dispatch = useDispatch();

    const isFiltered = Object.entries(state.filteredProducts).length !== 0 && Object.entries(state.filteredProducts).length !== (state.allProducts || []).length





    // HANDLE COLUMN EXPAND
    React.useEffect(() => {
        if (queryState.columnId !== '') {
            dispatch({
                type: EXPAND_COLUMN,
                data: queryState.columnId
            })
        }


    }, [queryState.columnId, state.products, dispatch])


    // HANDLE ROW EXPAND
    React.useEffect(() => {
        const _id = queryState.rowId
        if (_id !== '') {
            dispatch({
                type: EXPAND_TABLE_ROW,
                data: queryState.rowId,
                ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                    eventName: `LAYOUT ROW EXPANDED - ${_id}`,
                    sectorId: _id
                })
            });
            setExpanded(true);
        }
        else {
            dispatch({
                type: '',
                ...getAnalyticsMetadata('ANALYTICS_USER_EVENT', {
                    eventName: 'LAYOUT ROW RESET',
                })
            });
            setExpanded(false)
        }

    }, [queryState.rowId, state.products, dispatch]);

    React.useEffect(() => {
        if ((state.products || []).length < 1) return;
        if (queryState.selectedProduct !== (state.selectedProduct || {} as IProduct).airtableId) {
            dispatch({ type: SELECT_PRODUCT, data: (state.products || []).find(item => queryState.selectedProduct === item.airtableId) });
        }
    }, [state.selectedProduct, queryState.selectedProduct, state.products, dispatch]);


    React.useEffect(() => {
        if ((state.products || []).length < 1) return;
        if (!queryState.selectedSectorBar) return;
        const { sectorId, colId, rowId } = queryState.selectedSectorBar;

        if (!state.selectedSectorBar) {
            dispatch(selectSectorBar(sectorId, colId, rowId));
            return;
        }

        const { sectorId: _sectorId, colId: _colId, rowId: _rowId } = state.selectedSectorBar;
        if (sectorId !== _sectorId || colId !== _colId || rowId !== _rowId)
            dispatch(selectSectorBar(sectorId, colId, rowId));

    }, [state.selectedSectorBar, queryState.selectedSectorBar, state.products, dispatch]);



    // HANDLE PRODUCT FILTERING
    React.useEffect(() => {

        if (state.products && state.products.length && !isSameObject(queryState.settings, state.settings)) {
            dispatch(updateSettings({
                settings: queryState.settings,
                products: Product.settingFilterProduct(queryState.settings, state.products)
            }))
        }

        if (queryState.filter.type === state.filter.type) {

            if (state.filter.type === 'partner' || state.filter.type === 'investor') {
                //Comparing the array of string
                if ((queryState.filter.id as string[] || []).join('-') === (state.filter.id as string[] || []).join('-')) return;
            }
            // comparing the strings
            else if (queryState.filter.id === state.filter.id) return;

        }
        if (!queryState.filter.type || !queryState.filter.id) {
            Object.keys(state.filter || {}).length > 0 && dispatch({ type: RESET_FILTERED_PRODUCTS });
            return;
        }
        console.log('Applying from here');
        // applyFilter(queryState.filter.type, queryState.filter.id);
    }, [queryState.filter, queryState.settings, state.settings, state.products, state.filter, dispatch]);

    const isSearchEmpty = () => {
        if (!isFiltered) return false;
        let isEmpty = true;
        if (expanded) {
            state.indications?.forEach(ind => {
                ind.stages.forEach(stage => {
                    stage.forEach(p => {
                        if (state.filteredProducts[p.airtableId]) {
                            isEmpty = false;
                            return;
                        }
                    });
                });
            })
        } else {
            therapeuticSectors.forEach(sec => {
                sec.stages?.forEach(stage => {
                    stage.forEach(p => {
                        if (state.filteredProducts[p.airtableId]) {
                            isEmpty = false;
                            return;
                        }
                    });
                });
            })
        }
        return isEmpty;
    }





    return (
        <div className={clsx(classes.root, expanded ? classes.expanded : '', isFiltered ? (state.settings.showOnlyFiltered ? classes.hideInvisible : classes.hideOpacity) : '')} >
            <div className={classes.fixedTableHeading}>
                <TableHeading expanded={expanded} stages={stages} {...props} />
            </div>
            <div className={classes.rowContainer} id="row-container" >
                {
                    (expanded) ?
                        <AllIndications indications={state.indications} {...props} /> :
                        <AllTherapeuticSectors therapeuticSectors={therapeuticSectors} {...props} />
                }
            </div>
            {isSearchEmpty() ? (
                <ProductsNotFound />
            ) : null}
        </div>
    )
}







interface IAllIndications {
    indications: TIndication | undefined
}
const AllIndications: React.FC<IAllIndications> = (props) => {
    const { indications } = props;

    if (!indications)
        return <div />

    console.log("indications", indications)

    return (
        <>
            {
                indications.map((indication, i) => (
                    <TableRow subcategory={true} key={indication.indication.airtableId} stages={indication.stages} title={indication.indication.name}  {...props} id={`${i}`} />
                ))
            }
        </>
    )
}


interface IAllTherapeuticSectors {
    therapeuticSectors: ITherapeuticSector[]
}
const AllTherapeuticSectors: React.FC<IAllTherapeuticSectors & RouteComponentProps> = (props) => {
    const { therapeuticSectors } = props;
    const queryState = parseQs();



    const handleOnClick = (sector: ITherapeuticSector) => {
        if (sector && queryState.rowId === sector._id.airtableId)
            return;
        const newQueryState: IQueryState = { ...queryState, rowId: sector ? sector._id.airtableId : '' };
        const newQueryString = 'view?' + qs.stringify(newQueryState);
        props.history.push(newQueryString)
    }

    let pill: HTMLDivElement | null = null;

    React.useEffect(() => {
        let item = ReactDOM.findDOMNode(pill) as Element;
        setTimeout(() => {
            if (item) {
                let els = item.getElementsByClassName('highlight');
                if (els && (els.length || []) > 0) {
                    let first = els[0];
                    for (let i = 0; i < els.length; i++) {
                        let t = els[i].getBoundingClientRect().top;
                        if (t <= first.getBoundingClientRect().top) {
                            first = els[i]
                        }
                    }
                    first.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        });

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryState.filter.id]);

    // React.useEffect(() => {
    //     // Checking if the reset button is pressed
    //     if (JSON.stringify(queryState).trim() === JSON.stringify(initialState).trim()) {
    //         let item = ReactDOM.findDOMNode(pill) as Element;
    //         if (item) {
    //             let el = item.querySelector('.lscp-column');
    //             if (el) {
    //                 el.scrollIntoView({ block: 'start', behavior: 'smooth' });
    //             }
    //         }
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [queryState]);

    return (
        <div ref={ref => pill = ref} >
            {
                therapeuticSectors.map((thSector, i) => (
                    <TableRow onClick={() => handleOnClick(thSector)} key={thSector._id.airtableId} stages={thSector.stages} title={thSector._id.name} {...props} id={`${i}`} />
                ))
            }
        </div>
    )
}





const TableHeading: React.FC<IProps & { expanded: boolean }> = ({ classes, stages, history, expanded }) => {

    const handleExpandColumn = (colId: string) => {
        expandColumn(colId);
    }

    const state = useSelector<IAppState, Pick<IAppState, 'selectedRow' | 'settings'>>(({ selectedRow, settings }) => ({ selectedRow, settings }));

    const title = expanded ? state.selectedRow ? state.selectedRow._id.name : '' : 'Therapeutic Sectors'
    const queryState = parseQs();

    const { settings } = state;

    console.log({ expanded, title });

    const handleThSectorHeadingClick = () => {
        if (expanded && state.selectedRow) {
            const newQueryState: IQueryState = { ...queryState, rowId: '' };
            const newQueryString = 'view?' + qs.stringify(newQueryState);
            history.push(newQueryString);
        }
    }

    return (
        <div className={classes.th}>
            <div className={clsx(classes.tdata, 'lscp-column', 'lscp-row-heading')} onClick={handleThSectorHeadingClick} >
                <p>{title}</p>
            </div>
            {
                (stages || []).map((stage, i) =>
                    <div
                        onClick={e => handleExpandColumn(stage.airtableId)}
                        key={stage.airtableId}
                        className={clsx(
                            classes.tdata,
                            'lscp-col-heading',
                            'lscp-column',
                            stage.airtableId === queryState.columnId ? 'expanded' : queryState.columnId !== '' ? 'hidden' : '',
                            isCommercialStage(stage.airtableId) ? (settings.showCommercialColumns ? '' : 'hidden') : ''
                        )}
                    >
                        <p>{stage.name}</p>
                        {
                            (stage.name === "Approved/Marketed" || (i === (stages || []).length - 1)) ? null :
                                <div className={classes.expandButton} >
                                    <i className="material-icons" style={{
                                        transform: stage.airtableId === queryState.columnId ? `rotateZ(180deg)` : 'none',
                                        color: '#e1e1e1'
                                    }}>chevron_right</i>
                                </div>
                        }
                    </div>
                )
            }

        </div>
    )
}






const STYLES = (theme: IAppTheme) => ({
    root: {
        flex: 1,
        position: 'relative',
        display: 'flex',

        maxWidth: '99%',
        flexDirection: 'column',
        '& .lscp-row-heading': {
            display: 'flex',

            maxWidth: 150,
            minWidth: Device.isTablet ? 130 : 150,
            alignItems: 'flex-start',
            // alignItems: 'center',
            boxShadow: 'none',
            border: 'none',
            justifyContent: 'center',
        },

        '& .lscp-col-heading': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        },
        '& .lscp-column': {
            flex: 2,
            flexShrink: 0,
            transition: '400ms linear',
        },

        '& .lscp-column:nth-of-type(2)': {
            flex: 5,
        },
        '& .lscp-column:nth-of-type(3)': {
            flex: 3,
        },

        '& .lscp-column:nth-of-type(5)': {
            flex: Device.isTablet ? 3 : 2
        },
        '& .lscp-column.expanded': {
            display: 'flex',
            flex: 7,
        },
        '& .lscp-column.hidden': {
            flex: 0,
            display: 'none'
        },
        '& .lscp-row-heading-title': {
            position: 'relative',
            '& > p': {
                position: 'sticky',
                top: 0,
            },
            '&::after': {
                content: "''",
                height: 1,
                width: '20%',
                background: 'rgba(0,0,0,.06)',
                position: 'absolute',
                bottom: 0,
            }
        }
    },
    expanded: {
        '& .lscp-row-heading-title': {
            position: 'relative',
            '&::before': {
                content: "''",
                width: 2,
                height: '100%',
                background: 'rgba(0,0,0,.30)',
                position: 'absolute',
                left: 10
            },
            '& p.title': {
                padding: '0px 14px',
                fontWeight: 500,
            }
        }
    },
    fixedTableHeading: {
        position: 'absolute',
        top: 28,
        height: 60,
        left: 0,
        right: 0
    },

    hideOpacity: {
        '& .unHighlight': {
            opacity: 0.3,
        }
    },
    hideInvisible: {
        '& .unHighlight': {
            display: 'none'
        }
    },
    rowContainer: {
        marginTop: 88,
        height: 'calc(100vh - 150px)',
        overflow: 'scroll',
        'scrollbar-width': 'none', /* Firefox */
        '-ms-overflow-style': 'none',  /* IE 10+ */
        '&::-webkit-scrollbar': { /* WebKit */
            width: 0,
            height: 0
        }
    },
    th: {
        fontWeight: 500,
        display: 'flex',
        flex: 1,
        height: '100%',
        '& .lscp-row-heading': {
            alignItems: 'center',
        },
    },
    tr: {
        fontWeight: 500,
        display: 'flex',

        flex: 1,
        // minHeight: '80px'
        minHeight: 32
    },
    tdata: {
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '12px 12px 0px 0px',
        background: theme.color.background,
        boxShadow: `0px 1px 3px rgba(0,0,0,0.16)`,
        margin: '0px 5px',
        position: 'relative',

        textTransform: 'uppercase',
        '& .sample-class': {
            display: 'inline-block',
            margin: '4px 10px'
        },
        '& > span > span': {
            margin: 8
        },
        '& p': {
            padding: Device.isTablet ? '10px 6px' : 0,
            boxSizing: 'border-box'
        }
        // '& > div:first-child': {
        //     overflow: 'hidden',
        //     textOverflow: 'ellipsis',
        //     '& > p': {
        //         padding: 10,
        //         overflow: 'hidden',
        //         textOverflow: 'ellipsis',
        //     }
        // }
    },
    expandButton: {
        position: 'absolute',
        height: 34, width: 34,
        backgroundColor: 'white',
        top: 45, right: -18,
        borderRadius: '100%',
        zIndex: 1,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    subcategory: {

    }
})

export default injectSheet(STYLES)(withRouter(Table));