import * as React from 'react'
import injectSheet, { WithSheet } from 'react-jss';
import IconButton from '../Buttons/IconButton';
import { parseQs } from '../../resources/utils';
import { LayoutContext } from '../../LayoutContext';


interface IProps extends WithSheet<typeof STYLES> {

}

const ViewButtonGroup: React.FC<IProps> = (props) => {
    const { classes } = props
    const { viewType, changeLayoutView } = React.useContext(LayoutContext);

    const buttons = [
        { icon: 'insert_chart_outlined', active: viewType === 'bar', onClick: () => changeLayoutView('bar'), style: { transform: 'rotate(90deg)' } },
        { icon: 'apps', active: viewType === 'pill', onClick: () => changeLayoutView('pill') },
    ]

    return (
        <div className={classes.root}>
            {
                buttons.map((b, i) => (
                    <button onClick={b.onClick} style={b.style} className={b.active ? classes.active : classes.inactive} key={`${i}`}>
                        <i className="material-icons">{b.icon}</i>
                    </button>
                ))
            }
        </div>
    )
}

const STYLES = (theme: any) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            backgroundColor: 'transparent',
            marginLeft: 6,
            cursor: 'pointer',
            '&:focus': {
                outline: 'none'
            },
            '& i': {
                fontSize: 26,
                lineHeight: 1.4
            }
        }
    },
    active: {
        color: 'white',
        border: `2px solid white`,
        borderRadius: 2,
    },
    inactive: {
        color: 'grey',
        border: 'none',
    }
})

export default injectSheet(STYLES)(ViewButtonGroup)